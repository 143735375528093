export function buildReportAccountGroupsURL(accountGroups, cumulative) {
  const url = `/reports?accountGroups=${accountGroups.join(',')}`

  return cumulative ? `${url}&cumulative=true` : url;
}

export function buildUrl(path, params) {
  let url = path;

  if (!params || Object.keys(params).length === 0) {
    return url;
  }

  const values = Object.entries(params).some(([key, value]) => { return value });

  if (!values) {
    return url;
  }

  Object.entries(params).forEach(([key, value], index) => {
    if (index === 0 && value) {
      url += `?${key}=${value}`;
    } else if (value) {
      url += /\?/.test(url) ? `&${key}=${value}` : `?${key}=${value}`;
    }
  });

  return url;
}
