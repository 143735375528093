import React from 'react';
import { connect } from 'react-redux'
import { fetchDashboardDataIfNeeded } from '../actions/dashboard';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Loader from '../common/loader';
import DashboardSmallCards from './dashboard-small-cards';
import DashboardMediumCards from './dashboard-medium-cards';
import DashboardLargeCards from './dashboard-large-cards';

import './dashboard.scss';

class Dashboard extends React.Component {

  componentDidMount() {
    const { dispatch, selectedFinancialYear } = this.props;

    const id = selectedFinancialYear ? selectedFinancialYear.id : null;

    if (id) {
      dispatch(fetchDashboardDataIfNeeded(id));
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, didInvalidate, selectedFinancialYear } = this.props;

    if (didInvalidate && prevProps.didInvalidate !== didInvalidate) {
      const id = selectedFinancialYear ? selectedFinancialYear.id : null;
      dispatch(fetchDashboardDataIfNeeded(id));
    }

    if (selectedFinancialYear != prevProps.selectedFinancialYear) {
      const id = selectedFinancialYear ? selectedFinancialYear.id : null;
      dispatch(fetchDashboardDataIfNeeded(id, true));
    }
  }

  render() {
    const {
      isFetching,
      lastUpdated,
      data,
      dispatch,
      subscriptions,
      financialYears,
      selectedFinancialYear,
    } = this.props;

    if (isFetching) {
      return (
        <Loader />
      )
    }

    return (
      <div className='dashboard-container'>
        <Grid container justify="center" spacing={4}>
          <DashboardSmallCards data={data.headings}/>
          <DashboardMediumCards
            data={data.charts}
            selectedFinancialYear={selectedFinancialYear}
            financialYears={financialYears}
          />
          <DashboardLargeCards
            headingsData={data.headings}
            data={data.tables}
            balance={data.charts.balance}
            dispatch={dispatch}
            subscriptions={subscriptions}
          />
        </Grid>
      </div>
    )
  }
}

Dashboard.propTypes = {
  data: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  didInvalidate: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { dashboardData } = state;
  const { lastUpdated, didInvalidate } = dashboardData;
  const data = dashboardData.data || {};
  const isFetching = dashboardData.isFetching === undefined ? true : dashboardData.isFetching;

  return {
    isFetching,
    lastUpdated,
    didInvalidate,
    data
  }
}

export default connect(mapStateToProps)(Dashboard)
