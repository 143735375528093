import { combineReducers } from 'redux'
import dashboardReducer from './dashboard-reducer';
import reportReducer from './report-reducer';
import invoiceReducer from './invoice-reducer';
import supplierInvoiceReducer from './supplier-invoice-reducer';
import customerReducer from './customer-reducer';
import articleReducer from './article-reducer';
import orderReducer from './order-reducer';
import transactionReducer from './transaction-reducer';
import taxReductionReducer from './tax-reduction-reducer';
import documentReducer from './document-reducer';

const rootReducer = combineReducers({
  dashboardData: dashboardReducer,
  reportData: reportReducer,
  invoiceData: invoiceReducer,
  supplierInvoiceData: supplierInvoiceReducer,
  customerData: customerReducer,
  articleData: articleReducer,
  orderData: orderReducer,
  transactionData: transactionReducer,
  taxReductionData: taxReductionReducer,
  documentData: documentReducer
});

export default rootReducer;

