import request from './request';

export function fetchCompany() {
  return request(`fortnox_company`, {
    method: 'GET'
  });
}

export function fetchFinancialYears() {
  return request(`financial_years`, {
    method: 'GET'
  });
}
