import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  invalidateReportData,
  fetchReportDataIfNeeded,
} from '../actions/reports';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ReportCard from '../reports/report-card';
import BarChart from '../charts/bar-chart';
import LineChart from '../charts/line-chart';
import PieChart from '../charts/pie-chart';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { Button } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import QueueIcon from '@material-ui/icons/Queue';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CompareIcon from '@material-ui/icons/Compare';
import Loader from '../common/loader';
import i18n from '../i18n';
import ReportList from './report-list';
import { buildReportAccountGroupsURL } from '../utils/link-builder-utils';
import {
  presentFinancialYear,
  presentPreviousFinancialYear,
} from '../utils/financial-year-utils';

import './reports.scss';

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountGroups: [],
      cumulative: false,
    }
  }

  componentDidMount() {
    const { dispatch, location, selectedFinancialYear } = this.props;
    const params = new URLSearchParams(location.search);
    const accountGroups = params.get('accountGroups');
    const cumulative = params.get('cumulative');

    this.setState({
      accountGroups: accountGroups || [],
      cumulative: cumulative || false,
    });

    const id = selectedFinancialYear ? selectedFinancialYear.id : null;

    if (id) {
      dispatch(invalidateReportData());
      dispatch(fetchReportDataIfNeeded(accountGroups, cumulative, id));
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, didInvalidate, location, selectedFinancialYear } = this.props;

    const params = new URLSearchParams(location.search);
    const accountGroups = params.get('accountGroups');
    const cumulative = params.get('cumulative');

    if (didInvalidate && prevProps.didInvalidate !== didInvalidate || location.search !== prevProps.location.search) {
      this.setState({
        accountGroups: accountGroups || [],
        cumulative: cumulative || false,
      });

      const id = selectedFinancialYear ? selectedFinancialYear.id : null;

      dispatch(invalidateReportData());
      dispatch(fetchReportDataIfNeeded(accountGroups, cumulative, id));
    }

    if (selectedFinancialYear != prevProps.selectedFinancialYear) {
      const id = selectedFinancialYear ? selectedFinancialYear.id : null;
      dispatch(fetchReportDataIfNeeded(accountGroups, cumulative, id, true));
    }
  }

  render() {
    const t = i18n.getFixedT();
    const {
      isFetching,
      lastUpdated,
      data,
      financialYears,
      selectedFinancialYear,
    } = this.props;

    if (isFetching) {
      return (
        <Loader />
      )
    }

    if (this.state.accountGroups.length > 0 || (data.charts || []).length > 0) {
      return (
        <div className="reports-container">
          <ReportList
            reports={data.charts}
            financialYears={financialYears}
            selectedFinancialYear={selectedFinancialYear}
          />
        </div>
      )
    }

    return (
      <div className="reports-container">
        <Grid container justify="center" spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ReportCard
              medium={true}
              title={t('incomePerMonth')}
              titleWidth='25%'
              subHeader={
                <div>
                  { data.charts.income.previous_year.per_month.length > 0 &&
                    <React.Fragment>
                      <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#3c4fb3' }}/>
                      <p style={{margin: '2px', float: 'left'}}>
                        {presentFinancialYear(selectedFinancialYear)}
                      </p>
                      <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#ffa726' }}/>
                      <p style={{margin: '2px', float: 'left'}}>
                        {presentPreviousFinancialYear(financialYears, selectedFinancialYear)}
                      </p>
                  </React.Fragment>
                  }
                </div>
              }
              icon={<AssessmentIcon style={{ color: '#66bb6a', fontSize: '60px', margin: '-15px 0 0 -15px' }}/>}
            >
              <LineChart
                data={data.charts.income.current_year.per_month}
                secondaryData={data.charts.income.previous_year.per_month}
                barWidth={10}
                dataColor='#3c4fb3'
                secondaryDataColor='#ffa726'
                background='#FFFFFF'
                axisColor='#AAAAAA'
              />
              { data.charts.income.current_year.per_month.reduce((sum, row) => sum + row.amount, 0) > 0 &&
                data.charts.income.account_groups.length > 0 &&
                <Link
                  to={buildReportAccountGroupsURL(data.charts.income.account_groups, false)}
                  style={{float: 'right', padding: 10, color: '#000000', marginTop: 5}}
                >
                  {t('showMore')}
                </Link>
              }
            </ReportCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ReportCard
              medium={true}
              title={t('incomePerMonthCumulative')}
              titleWidth='25%'
              subHeader={
                <div>
                  { data.charts.income.previous_year.cumulative.length > 0 &&
                    <React.Fragment>
                      <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#3c4fb3' }}/>
                      <p style={{margin: '2px', float: 'left'}}>
                        {presentFinancialYear(selectedFinancialYear)}
                      </p>
                      <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#ffa726' }}/>
                      <p style={{margin: '2px', float: 'left'}}>
                        {presentPreviousFinancialYear(financialYears, selectedFinancialYear)}
                      </p>
                    </React.Fragment>
                  }
                </div>
              }
              icon={<AssessmentIcon style={{ color: '#66bb6a', fontSize: '60px', margin: '-15px 0 0 -15px' }}/>}
            >
              <LineChart
                data={data.charts.income.current_year.cumulative}
                secondaryData={data.charts.income.previous_year.cumulative}
                barWidth={10}
                dataColor='#3c4fb3'
                secondaryDataColor='#ffa726'
                background='#FFFFFF'
                axisColor='#AAAAAA'
              />
              { data.charts.income.current_year.cumulative.reduce((sum, row) => sum + row.amount, 0) > 0 &&
                data.charts.income.account_groups.length > 0 &&
                <Link
                  to={buildReportAccountGroupsURL(data.charts.income.account_groups, true)}
                  style={{float: 'right', padding: 10, color: '#000000', marginTop: 5}}
                >
                  {t('showMore')}
                </Link>
              }
            </ReportCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ReportCard
              medium={true}
              title={t('expenditurePerMonth')}
              titleWidth='25%'
              subHeader={
                <div>
                  { data.charts.expenditure.previous_year.per_month.length > 0 &&
                    <React.Fragment>
                      <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#3c4fb3' }}/>
                      <p style={{margin: '2px', float: 'left'}}>
                        {presentFinancialYear(selectedFinancialYear)}
                      </p>
                      <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#ffa726' }}/>
                      <p style={{margin: '2px', float: 'left'}}>
                        {presentPreviousFinancialYear(financialYears, selectedFinancialYear)}
                      </p>
                    </React.Fragment>
                  }
                </div>
              }
              icon={<CreditCardIcon style={{ color: '#ef5350', fontSize: '60px', margin: '-15px 0 0 -15px' }}/>}
            >
              <LineChart
                data={data.charts.expenditure.current_year.per_month}
                secondaryData={data.charts.expenditure.previous_year.per_month}
                dataColor='#3c4fb3'
                secondaryDataColor='#ffa726'
                background='#FFFFFF'
                axisColor='#AAAAAA'
              />
              { data.charts.expenditure.current_year.per_month.reduce((sum, row) => sum + row.amount, 0) > 0 &&
                data.charts.expenditure.account_groups.length > 0 &&
                <Link
                  to={buildReportAccountGroupsURL(data.charts.expenditure.account_groups, false)}
                  style={{float: 'right', padding: 10, color: '#000000', marginTop: 5}}
                >
                  {t('showMore')}
                </Link>
              }
            </ReportCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ReportCard
              medium={true}
              title={t('expenditurePerMonthCumulative')}
              titleWidth='25%'
              subHeader={
                <div>
                  { data.charts.expenditure.previous_year.cumulative.length > 0 &&
                    <React.Fragment>
                      <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#3c4fb3' }}/>
                      <p style={{margin: '2px', float: 'left'}}>
                        {presentFinancialYear(selectedFinancialYear)}
                      </p>
                      <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#ffa726' }}/>
                      <p style={{margin: '2px', float: 'left'}}>
                        {presentPreviousFinancialYear(financialYears, selectedFinancialYear)}
                      </p>
                    </React.Fragment>
                  }
                </div>
              }
              icon={<CreditCardIcon style={{ color: '#ef5350', fontSize: '60px', margin: '-15px 0 0 -15px' }}/>}
            >
              <LineChart
                data={data.charts.expenditure.current_year.cumulative}
                secondaryData={data.charts.expenditure.previous_year.cumulative}
                dataColor='#3c4fb3'
                secondaryDataColor='#ffa726'
                background='#FFFFFF'
                axisColor='#AAAAAA'
              />
              { data.charts.expenditure.current_year.cumulative.reduce((sum, row) => sum + row.amount, 0) > 0 &&
                data.charts.expenditure.account_groups.length > 0 &&
                <Link
                  to={buildReportAccountGroupsURL(data.charts.expenditure.account_groups, true)}
                  style={{float: 'right', padding: 10, color: '#000000', marginTop: 5}}
                >
                  {t('showMore')}
                </Link>
              }
            </ReportCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ReportCard
              medium={true}
              titleWidth='25%'
              subHeader={
                <div>
                  <p style={{margin: '2px', float: 'left'}}>
                    {t('incomeVsExpenditure')} {presentFinancialYear(selectedFinancialYear)}
                  </p>
                  <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#3c4fb3' }}/>
                  <p style={{margin: '2px', float: 'left'}}>{t('income')}</p>
                  <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#ffa726' }}/>
                  <p style={{margin: '2px', float: 'left'}}>{t('expenditure')}</p>
                </div>
              }
              icon={<CompareIcon style={{ color: '#ffa726', fontSize: '60px', margin: '-15px 0 0 -15px' }}/>}
            >
              <LineChart
                data={data.charts.income.current_year.per_month}
                secondaryData={data.charts.expenditure.current_year.per_month}
                dataColor='#3c4fb3'
                secondaryDataColor='#ffa726'
                background='#FFFFFF'
              />
            </ReportCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ReportCard
              medium={true}
              titleWidth='20%'
              subHeader={
                <div>
                  <p style={{margin: '2px', float: 'left'}}>
                    {t('incomeVsExpenditureCumulative')} {presentFinancialYear(selectedFinancialYear)}
                  </p>
                  <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#3c4fb3' }}/>
                  <p style={{margin: '2px', float: 'left'}}>{t('income')}</p>
                  <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#ffa726' }}/>
                  <p style={{margin: '2px', float: 'left'}}>{t('expenditure')}</p>
                </div>
              }
              icon={<CompareIcon style={{ color: '#ffa726', fontSize: '60px', margin: '-15px 0 0 -15px' }}/>}
            >
              <LineChart
                data={data.charts.income.current_year.cumulative}
                secondaryData={data.charts.expenditure.current_year.cumulative}
                dataColor='#3c4fb3'
                secondaryDataColor='#ffa726'
                background='#FFFFFF'
              />
            </ReportCard>
          </Grid>
        </Grid>
      </div>
    )
  }
}

Reports.propTypes = {
  data: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { reportData } = state;
  const { lastUpdated, didInvalidate } = reportData;
  const data = reportData.data || {};
  const isFetching = reportData.isFetching === undefined ? true : reportData.isFetching;

  return {
    isFetching,
    lastUpdated,
    data,
    didInvalidate,
  }
}

export default connect(mapStateToProps)(withRouter(Reports))
