import { initialState } from './initial-state';
import {
  RECEIVE_TAX_REDUCTION_DATA,
} from '../actions/tax-reduction-action-types';

export default function taxReductionReducer(state = initialState.taxReduction, action) {
  switch (action.type) {
    case RECEIVE_TAX_REDUCTION_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        data: action.taxReductionData,
        lastUpdated: action.receivedAt
      });
    default:
      return state
  }
}


