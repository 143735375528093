import React from 'react';
import { Link } from "react-router-dom";
import { Grid } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import BarChart from '../charts/bar-chart';
import LineChart from '../charts/line-chart';
import PieChart from '../charts/pie-chart';
import ReportCard from '../reports/report-card';
import { buildReportAccountGroupsURL } from '../utils/link-builder-utils';
import {
  presentFinancialYear,
  presentPreviousFinancialYear,
} from '../utils/financial-year-utils';
import i18n from '../i18n';

export default function DashboardMediumCards(props) {
  const { data, financialYears, selectedFinancialYear } = props;
  const t = i18n.getFixedT();

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <ReportCard
          medium={true}
          subHeader={
            <div>
              <p style={{margin: '2px', float: 'left'}}>{t('incomePerMonthCumulative')}</p>
              { data.income.previous_year.cumulative.length > 0 &&
                <React.Fragment>
                  <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#3c4fb3' }}/>
                  <p style={{margin: '2px', float: 'left'}}>
                    {presentFinancialYear(selectedFinancialYear)}
                  </p>
                  <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#ffa726' }}/>
                  <p style={{margin: '2px', float: 'left'}}>
                    {presentPreviousFinancialYear(financialYears, selectedFinancialYear)}
                  </p>
                </React.Fragment>
              }
            </div>
          }
          icon={<AssessmentIcon style={{ color: '#66bb6a' }}/>}
        >
          <LineChart
            data={data.income.current_year.cumulative}
            secondaryData={data.income.previous_year.cumulative}
            barWidth={10}
            dataColor='#3c4fb3'
            secondaryDataColor='#ffa726'
            background='#FFFFFF'
            axisColor='#AAAAAA'
          />

          { data.income.current_year.cumulative.reduce((sum, row) => sum + row.amount, 0) > 0 &&
            data.income.account_groups.length > 0 &&
            <Link
              to={buildReportAccountGroupsURL(data.income.account_groups, true)}
              style={{float: 'right', color: '#000000'}}
            >
              {t('showMore')}
            </Link>
          }
        </ReportCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <ReportCard
          medium={true}
          subHeader={
            <div>
              <p style={{margin: '2px', float: 'left'}}>{t('expenditurePerMonthCumulative')}</p>
              { data.expenditure.previous_year.cumulative.length > 0 &&
                <React.Fragment>
                  <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#3c4fb3' }}/>
                  <p style={{margin: '2px', float: 'left'}}>
                    {presentFinancialYear(selectedFinancialYear)}
                  </p>
                  <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#ffa726' }}/>
                  <p style={{margin: '2px', float: 'left'}}>
                    {presentPreviousFinancialYear(financialYears, selectedFinancialYear)}
                  </p>
                </React.Fragment>
              }
            </div>
          }
          icon={<CreditCardIcon style={{ color: '#ef5350' }}/>}
        >
          <LineChart
            data={data.expenditure.current_year.cumulative}
            secondaryData={data.expenditure.previous_year.cumulative}
            dataColor='#3c4fb3'
            secondaryDataColor='#ffa726'
            background='#FFFFFF'
            axisColor='#AAAAAA'
          />

          { data.expenditure.current_year.cumulative.reduce((sum, row) => sum + row.amount, 0) > 0 &&
            data.expenditure.account_groups.length > 0 &&
            <Link
              to={buildReportAccountGroupsURL(data.expenditure.account_groups, true)}
              style={{float: 'right', color: '#000000'}}
            >
              {t('showMore')}
            </Link>
          }
        </ReportCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <ReportCard
          medium={true}
          title='Kassa och bank'
          icon={<AccountBalanceWalletIcon style={{ color: '#ffa726' }}/>}
          background='#FFFFFF'
        >
          <LineChart
            data={data.balance.current_year}
            dataColor='#3c4fb3'
            background='#FFFFFF'
            axisColor='#AAAAAA'
          />

          {
            // TODO: Reimplement when balance by account is supported.
            // data.balance.current_year.reduce((sum, row) => sum + row.amount, 0) > 0 &&
            // data.balance.account_groups.length > 0 &&
            // <Link
            //   to={buildReportAccountGroupsURL(data.balance.account_groups, true)}
            //   style={{float: 'right', color: '#000000'}}
            // >
            //   {t('showMore')}
            // </Link>
          }
        </ReportCard>
      </Grid>
    </React.Fragment>
  )
}
