import React from 'react';
import { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import Icon from '@material-ui/core/Icon';
import Loader from '../common/loader';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import i18n from '../i18n';
import {
  fetchOrderPdf,
  fetchOrder,
  printOrder,
  createOrderInvoice,
  fetchFileAttachment,
  deleteFileAttachment,
  uploadFileAttachment,
} from '../services/orders';
import {
  printInvoice,
} from '../services/invoices';
import FileUploader from './file-uploader';
import OrderForm from './order-form';
import OrderEmailForm from './order-email-form';
import InvoiceEmailForm from '../invoices/invoice-email-form';
import { format } from 'date-fns'
import { formatMoney } from '../utils/money-utils';
import auth from '../authentication/authentication';
import { extractUuid } from '../utils/file-utils';

import './order-list.scss';

export default function OrderList(props) {
  const t = i18n.getFixedT();
  const {
    data,
    updateOrder,
    cancelOrder,
    emailOrder,
    emailInvoice,
    bookkeepInvoice,
    receiveCreateInvoice,
    receiveCreateOrderInvoice,
    receiveUploadFileAttachment,
    actionsEnabled,
    isFetching,
  } = props;

  const [invoiceForPayment, setInvoiceForPayment] = useState(null);
  const [sendOrderModalOpen, setSendOrderModalOpen] = useState(false);
  const [sendInvoiceModalOpen, setSendInvoiceModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [orderToSend, setOrderToSend] = useState(null);
  const [orderToEdit, setOrderToEdit] = useState(null);
  const [orderToUploadAttachment, setOrderToUploadAttachment] = useState(null);
  const [newInvoice, setNewInvoice] = useState(null);

  useEffect(() => {
    data.map((order) => {
      if (order && order.updated && invoiceForPayment && invoiceForPayment.document_number === order.document_number) {
        setTimeout(() => {
          setInvoiceForPayment(null);
        }, 1000);
      }

      if (order && order.updated && orderToSend && orderToSend.document_number === order.document_number) {
        setSendOrderModalOpen(false);
      }
    })
  });

  const toggleEditOrderForm = (order) => {
    if (orderToEdit) {
      setOrderToEdit(null);
    } else {
      fetchOrder(order.document_number)
        .then(response => response.json())
        .then(json => setOrderToEdit(json))
        .catch(error => console.log("error: ", error));
    }
  }

  const toggleSendOrderForm = (order, open) => {
    setOrderToSend(order);
    setSendOrderModalOpen(open);
  }

  const toggleSendInvoiceForm = (invoice, open) => {
    setNewInvoice(invoice);
    setSendInvoiceModalOpen(open);
  }

  const toggleFileUploadModalOpen = (order, open) => {
    setOrderToUploadAttachment(order);
    setFileUploadModalOpen(open);
  }

  const resolveStatus = (order) => {
    if (invoiceForPayment && invoiceForPayment.updating && order.document_number === invoiceForPayment.document_number) {
      return <Loader size={30} />
    }

    if (order.cancelled) {
      return t('cancelled');
    }

    return order.invoice_reference ? t('invoiceCreated') : t('invoiceNotCreated');
  }

  const resolveStatusColor = (order) => {
    if (order.cancelled) {
      return { color: '#aaaaaa' }
    }

    return order.invoice_reference ? { color: "#66bb6a" } : { color: "#e53935" }
  }

  const handleUpdateOrder = (order) => {
    updateOrder(order);

    setTimeout(() => {
      setOrderToEdit(null);
    }, 500);
  }

  const handleCancelOrder = (orderNumber) => {
    if (window.confirm(t('confirms.deleteOrder'))) {
      cancelOrder(orderNumber)
    }
  }

  const handleCreateInvoice = (orderNumber) => {
    createOrderInvoice(orderNumber)
      .then(response => response.json())
      .then((orderResp) => {
        setNewInvoice(orderResp.invoice);
        setSendInvoiceModalOpen(true);
        receiveCreateInvoice(orderResp.invoice);
        receiveCreateOrderInvoice(orderResp.order);
      });
  }

  const handleSendOrder = (order, deliveryAction) => {
    deliveryAction.func(order.document_number);

    setTimeout(() => {
      setSendOrderModalOpen(false);
    }, 500);
  }

  const handleFinalizeInvoice = (invoice, deliveryAction) => {
    bookkeepInvoice(invoice.document_number);

    deliveryAction.func(invoice.document_number);

    setTimeout(() => {
      setSendInvoiceModalOpen(false);
    }, 500);
  }

  const openAttachment = (orderNumber, fileAttachmentPath) => {
    fetchFileAttachment(orderNumber, extractUuid(fileAttachmentPath))
      .then((response) => response.json())
      .then((result) => {
        window.open(result.url);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const deleteAttachment = (orderNumber, orderFileAttachmentUuid) => {
    deleteFileAttachment(orderNumber, orderFileAttachmentUuid)
      .then((response) => response.json())
      .then((result) => {
        setOrderToUploadAttachment(result);
        receiveUploadFileAttachment(result);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const uploadAttachment = (order, attachment) => {
    setUploading(true);

    const formData = new FormData();
    formData.append('file', attachment);

    uploadFileAttachment(order.document_number, formData)
      .then((response) => response.json())
      .then((result) => {
        setOrderToUploadAttachment(result);
        receiveUploadFileAttachment(result);
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => setUploading(false));
  }

  return (
    <React.Fragment>
      {
        sendOrderModalOpen &&
        <OrderEmailForm
          open={sendOrderModalOpen}
          onSubmit={handleSendOrder}
          onClose={toggleSendOrderForm}
          onEmail={() => handleSendOrder(orderToSend, { func: emailOrder })}
          onPrint={() => handleSendOrder(orderToSend, { func: printOrder })}
          orderNumber={orderToSend.document_number}
        />
      }

      {
        sendInvoiceModalOpen &&
        <InvoiceEmailForm
          open={sendInvoiceModalOpen}
          onSubmit={handleFinalizeInvoice}
          onClose={toggleSendInvoiceForm}
          onEmail={() => handleFinalizeInvoice(newInvoice, { func: emailInvoice })}
          onPrint={() => handleFinalizeInvoice(newInvoice, { func: printInvoice })}
          invoiceNumber={newInvoice.document_number}
        />
      }

      {
        fileUploadModalOpen &&
          <FileUploader
            open={fileUploadModalOpen}
            loading={uploading}
            onUpload={uploadAttachment}
            onDelete={deleteAttachment}
            onClose={toggleFileUploadModalOpen}
            order={orderToUploadAttachment}
            orderFileAttachmentPath={orderToUploadAttachment.file_attachment_path}
          />
      }

      <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
        <Table aria-label="dashboard table">
          <TableHead style={{background: 'linear-gradient(60deg, #66bb6a, #3f9e43)'}}>
            <TableRow>
              <TableCell style={{ width: '15%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('customer')}</TableCell>
              <TableCell style={{ width: '8%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('orderNumber')}</TableCell>
              <TableCell style={{ width: '9%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('orderDate')}</TableCell>
              <TableCell style={{ width: '9%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('deliveryDate')}</TableCell>
              <TableCell style={{ width: '9%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('createdBy')}</TableCell>
              <TableCell style={{ width: '9%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('amount')}</TableCell>
              <TableCell style={{ width: '8%', fontWeight: 'bold', color: '#FFFFFF' }} align="right">{t('openPdf')}</TableCell>
              <TableCell style={{ width: '10%', fontWeight: 'bold', color: '#FFFFFF' }} align="right">{t('openAttachment')}</TableCell>
              <TableCell align="right" style={{ width: '23%' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching?
              <TableRow>
                <TableCell style={{height: '100vh'}}>
                  <Loader
                    style={{
                      position: 'absolute',
                      top: '35%',
                      left: '40%'
                    }}
                  />
                </TableCell>
              </TableRow>
            :
              data.map(order => (
                <React.Fragment key={order.document_number}>
                  <TableRow>
                    <TableCell
                      style={{
                        color: order.cancelled ? '#aaaaaa' : '#000000',
                        maxWidth: '190px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}
                      component="th"
                      scope="row"
                    >
                      {order.customer_name}
                    </TableCell>
                    <TableCell style={{color: order.cancelled ? '#aaaaaa' : '#000000'}} align="left">{order.document_number}</TableCell>
                    <TableCell style={{color: order.cancelled ? '#aaaaaa' : '#000000'}} align="left">{order.order_date}</TableCell>
                    <TableCell
                      align="left"
                      style={order.cancelled ? {color: '#aaaaaa'} : Date.parse(order.delivery_date) < Date.now() ? { color: "#e53935" }: { color: "#66bb6a" }}
                    >
                      {order.delivery_date}
                    </TableCell>
                    <TableCell
                      style={{
                        color: order.cancelled ? '#aaaaaa' : '#000000',
                        maxWidth: '170px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}
                      align="left"
                    >
                      {order.created_by}
                    </TableCell>
                    <TableCell style={{color: order.cancelled ? '#aaaaaa' : '#000000'}} align="left">{formatMoney(order.total, order.currency, true)}</TableCell>
                    <TableCell align="right">
                      {!order.cancelled &&
                        <Icon
                          style={{marginRight: 25, cursor: 'pointer'}}
                          onClick={() => fetchOrderPdf(order.document_number)}
                        >
                          description
                        </Icon>
                      }
                    </TableCell>
                    <TableCell align="right" style={{textAlign: 'center'}}>
                      {!order.cancelled && order.file_attachment_path &&
                        <Icon
                          style={{cursor: 'pointer'}}
                          onClick={() => openAttachment(order.document_number, order.file_attachment_path)}
                        >
                          attachment
                        </Icon>
                      }
                    </TableCell>
                    <TableCell align="right">
                      {actionsEnabled && !order.cancelled &&
                        <React.Fragment>
                          <Icon
                            style={{background: '#e53935', fontSize: '35px'}}
                            className="order-action-icon"
                            onClick={() => handleCancelOrder(order.document_number)}
                          >
                            delete
                          </Icon>
                          <Icon
                            style={{background: '#ffa726', fontSize: '35px'}}
                            className="order-action-icon"
                            onClick={() => toggleEditOrderForm(order)}
                          >
                            { orderToEdit && order.document_number === orderToEdit.document_number ? 'remove_square' : 'edit' }
                          </Icon>
                          <Button
                            style={{
                              color: '#FFFFFF',
                              fontSize: 14,
                              background: '#66bb6a',
                              float: 'right',
                              marginRight: '3px'
                            }}
                            onClick={() => handleCreateInvoice(order.document_number)}
                          >
                            {t('bill')}
                          </Button>
                          <Button
                            style={{
                              color: '#000000',
                              fontSize: 14,
                              background: '#FFFFFF',
                              float: 'right',
                              marginRight: '3px'
                            }}
                            variant="outlined"
                            onClick={() => toggleSendOrderForm(order, true)}
                          >
                            {t('send')}
                          </Button>
                        </React.Fragment>
                      }
                    </TableCell>
                  </TableRow>
                  {actionsEnabled && !order.cancelled && orderToEdit && orderToEdit.document_number === order.document_number &&
                    <TableRow>
                      <TableCell style={{width: '100%'}} colSpan="10">
                        <OrderForm
                          order={orderToEdit}
                          onSubmit={handleUpdateOrder}
                          receiveUploadFileAttachment={receiveUploadFileAttachment}
                        />
                      </TableCell>
                    </TableRow>
                  }
                </React.Fragment>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}
