import auth from '../authentication/authentication';

const BASE_URL = process.env.REACT_APP_AR_API_URL;

export default function upload(endpoint, body) {
  return new Promise((resolve, reject) => {
    fetch(`${BASE_URL}/${endpoint}`, {
      method: 'POST',
      body: body,
      headers: { Authorization: `Bearer ${auth.getToken()}` },
    })
      .then((response) => {
        if (response.status === 401) {
          if (!/tokens/.test(endpoint) && !/refresh_oauth2/.test(endpoint)) {
            auth.logout();
          }
          reject(response);
        }
        resolve(response);
      })
      .catch(error => reject(error));
  });
}
