import {
  REQUEST_DASHBOARD_DATA,
  RECEIVE_DASHBOARD_DATA,
  INVALIDATE_DASHBOARD_DATA,
} from './dashboard-action-types';

import { fetchDashboardData } from '../services/dashboard';

function receiveDashboardData(dashboardData) {
  return {
    type: RECEIVE_DASHBOARD_DATA,
    dashboardData: dashboardData,
    receivedAt: Date.now(),
  }
}

function requestDashboardData() {
  return {
    type: REQUEST_DASHBOARD_DATA
  }
}

export function invalidateDashboardData() {
  return {
    type: INVALIDATE_DASHBOARD_DATA
  }
}

function asyncFetchDashboardData(financialYear) {
  return function (dispatch, getState) {
    dispatch(requestDashboardData());

    return fetchDashboardData(financialYear)
      .then(response => response.json())
      .then(json => dispatch(receiveDashboardData(json)));
  }
}

function shouldfetchDashboardData(state) {
  const dashboardData = state.dashboardData;
  if (!dashboardData) {
    return true;
  }

  if (dashboardData.isFetching) {
    return false;
  }

  return dashboardData.didInvalidate;
}

export function fetchDashboardDataIfNeeded(financialYear) {
  return (dispatch, getState) => {
    if (shouldfetchDashboardData(getState())) {
      return dispatch(asyncFetchDashboardData(financialYear));
    }
  }
}

