import React from 'react';
import {
  VictoryChart,
  VictoryGroup,
  VictoryScatter,
  VictoryLine,
  VictoryAxis,
  VictoryLabel,
} from 'victory';
import { formatMoney } from '../utils/money-utils';

export default function LineChart(props) {
  const {
    axisColor,
    labelColor,
    background,
    data,
    secondaryData,
    includeBudget,
    dataColor,
    secondaryDataColor,
    budgetColor,
  } = props;

  const months = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'Maj',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Okt',
    11: 'Nov',
    12: 'Dec'
  }

  const toggleDisplayLabels = (target) => {
    return {
      target: target,
      eventHandlers: {
        onMouseOver: () => {
          return [{
            target: 'labels',
            mutation: (props) => {
              return { style: { ...props.style, opacity: 1 } };
            }
          }];
        },
        onMouseOut: () => {
          return [{
            target: 'labels',
            mutation: (props) => {
              return { style: { ...props.style, opacity: 0 } }
            }
          }];
        }
      }
    }
  }

  const formattedData = (data || []).map((item) => {
    return { x: months[item.date.split('-')[1]], y: item.amount };
  });

  const formattedData2 = (secondaryData || []).map((item) => {
    return { x: months[item.date.split('-')[1]], y: item.amount };
  });

  let budgetData = [];

  if (props.includeBudget) {
    budgetData = data.map((item) => {
      return { x: months[item.month], y: item.budget };
    });
  }

  return (
    <VictoryChart
      style={{
        parent: {
          background: background || 'linear-gradient(60deg, #ffa726, #fb8c00)',
          borderRadius: '5px'
        },
      }}
      animate={{
        duration: 1500,
      }}
      minDomain={{ y: 0}}
    >
      <VictoryAxis
        tickLabelComponent={
          <VictoryLabel
            style={{
              fill: labelColor || '#AAAAAA',
              fontSize: 10
            }}
          />
        }
        style={{
          axis: { stroke: axisColor || '#AAAAAA', opacity: '0.9'},
          grid: { stroke: axisColor || '#AAAAAA', opacity: '0.15' },
          ticks: { stroke: axisColor || '#AAAAAA' },
        }}
      />
      <VictoryAxis
        style={{
          axis: {stroke: axisColor || '#AAAAAA', opacity: '0.9'},
          grid: { stroke: axisColor || '#AAAAAA', opacity: '0.15' },
          ticks: {stroke: axisColor || '#AAAAAA' },
        }}
        dependentAxis
        tickLabelComponent={
          <VictoryLabel
            style={{
              fill: labelColor || '#AAAAAA',
              fontSize: 10
            }}
          />
        }
      />

      {formattedData.length > 0 &&
        <VictoryGroup data={formattedData}>
          <VictoryLine
            style={{
              data: {
                stroke: dataColor || '#66bb6a',
              },
              labels: {
                fill: dataColor || '#66bb6a'
              }
            }}
          />
          <VictoryScatter
            style={{
              data: {
                fill: dataColor || '#66bb6a',
                stroke: dataColor || '#66bb6a',
                cursor: 'pointer'
              },
              labels: {
                fill: labelColor || '#000000',
                opacity: 0,
              }
            }}
            labels={({ datum }) => formatMoney(datum.y, 'SEK', true)}
            events={[toggleDisplayLabels('data'), toggleDisplayLabels('labels')]}
          />
        </VictoryGroup>
      }

      {formattedData2.length > 0 &&
        <VictoryGroup data={formattedData2}>
          <VictoryLine
            style={{
              data: {
                stroke: secondaryDataColor || '#ef5350',
              },
              labels: {
                fill: secondaryDataColor || '#ef5350'
              }
            }}
          />
          <VictoryScatter
            style={{
              data: {
                fill: secondaryDataColor || '#ef5350',
                stroke: secondaryDataColor || '#ef5350',
                cursor: 'pointer'
              },
              labels: {
                fill: labelColor || '#000000',
                opacity: 0,
              }
            }}
            labels={({ datum }) => formatMoney(datum.y, 'SEK', true)}
            events={[toggleDisplayLabels('data'), toggleDisplayLabels('labels')]}
          />
        </VictoryGroup>
      }

      {includeBudget &&
        <VictoryGroup data={budgetData}>
          <VictoryLine
            style={{
              data: {
                stroke: budgetColor || '#000000',
              },
              labels: {
                fill: budgetColor || '#000000'
              }
            }}
          />
          <VictoryScatter
            style={{
              data: {
                fill: budgetColor || '#000000',
                stroke: budgetColor || '#000000',
                cursor: 'pointer'
              },
              labels: {
                fill: labelColor || '#000000',
                opacity: 0,
              }
            }}
            labels={({ datum }) => formatMoney(datum.y, 'SEK', true)}
            events={[toggleDisplayLabels('data'), toggleDisplayLabels('labels')]}
          />
        </VictoryGroup>
      }
    </VictoryChart>
  )
}
