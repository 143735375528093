import {
  REQUEST_ARTICLE_DATA,
  RECEIVE_ARTICLE_DATA,
  INVALIDATE_ARTICLE_DATA,
  RECEIVE_CREATE_ARTICLE,
  RECEIVE_UPDATE_ARTICLE,
  RECEIVE_DELETE_ARTICLE,
} from './article-action-types';
import {
  fetchArticleData,
  createArticle,
  updateArticle,
  deleteArticle,
} from '../services/articles';

function receiveArticleData(articleData) {
  return {
    type: RECEIVE_ARTICLE_DATA,
    articleData: articleData,
    receivedAt: Date.now(),
  }
}

function requestArticleData() {
  return {
    type: REQUEST_ARTICLE_DATA
  }
}

export function invalidateArticleData() {
  return {
    type: INVALIDATE_ARTICLE_DATA
  }
}

function asyncFetchArticleData() {
  return function (dispatch, getState) {
    dispatch(requestArticleData());

    return fetchArticleData()
      .then(response => response.json())
      .then(json => dispatch(receiveArticleData(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function shouldfetchArticleData(state) {
  const articleData = state.articleData;
  if (!articleData) {
    return true;
  }

  if (articleData.isFetching) {
    return false;
  }

  return articleData.didInvalidate;
}

export function fetchArticleDataIfNeeded() {
  return (dispatch, getState) => {
    if (shouldfetchArticleData(getState())) {
      return dispatch(asyncFetchArticleData());
    }
  }
}

function receiveCreateArticle(article) {
  return {
    type: RECEIVE_CREATE_ARTICLE,
    article: article,
  }
}

export function asyncCreateArticle(body) {
  return function (dispatch, getState) {
    createArticle(body)
      .then(response => response.json())
      .then(json => dispatch(receiveCreateArticle(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function receiveUpdateArticle(article) {
  return {
    type: RECEIVE_UPDATE_ARTICLE,
    article: article,
  }
}

export function asyncUpdateArticle(body, articleNumber) {
  return function (dispatch, getState) {
    updateArticle(body, articleNumber)
      .then(response => response.json())
      .then(json => dispatch(receiveUpdateArticle(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function receiveDeleteArticle(articleNumber) {
  return {
    type: RECEIVE_DELETE_ARTICLE,
    articleNumber: articleNumber,
  }
}

export function asyncDeleteArticle(articleNumber) {
  return function (dispatch, getState) {
    deleteArticle(articleNumber)
      .then(response => response.json())
      .then(json => dispatch(receiveDeleteArticle(articleNumber)))
      // .catch(error => console.log("error: ", error))
  }
}

