import { initialState } from './initial-state';
import {
  REQUEST_SUPPLIER_INVOICE_DATA,
  RECEIVE_SUPPLIER_INVOICE_DATA,
  INVALIDATE_SUPPLIER_INVOICE_DATA,
} from '../actions/supplier-invoice-action-types';

export default function supplierInvoiceReducer(state = initialState.supplierInvoice, action) {
  switch (action.type) {
    case INVALIDATE_SUPPLIER_INVOICE_DATA:
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case REQUEST_SUPPLIER_INVOICE_DATA:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVE_SUPPLIER_INVOICE_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        data: action.supplierInvoiceData,
        meta: action.meta,
        lastUpdated: action.receivedAt
      });
    default:
      return state
  }
}
