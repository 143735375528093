import {
  REQUEST_ORDER_DATA,
  RECEIVE_ORDER_DATA,
  INVALIDATE_ORDER_DATA,
  RECEIVE_CREATE_ORDER,
  RECEIVE_CREATE_ORDER_INVOICE,
  RECEIVE_UPDATE_ORDER,
} from './order-action-types';

import {
  fetchOrderData,
  createOrder,
  createOrderInvoice,
  updateOrder,
  fetchOrderPdf,
  cancelOrder,
  emailOrder,
  uploadFileAttachment,
} from '../services/orders';

function receiveOrderData(orderData) {
  return {
    type: RECEIVE_ORDER_DATA,
    orderData: orderData.data,
    meta: orderData.meta,
    receivedAt: Date.now(),
  }
}

function requestOrderData() {
  return {
    type: REQUEST_ORDER_DATA
  }
}

export function invalidateOrderData() {
  return {
    type: INVALIDATE_ORDER_DATA
  }
}

export function asyncFetchOrderData(filter, page) {
  return function (dispatch, getState) {
    dispatch(requestOrderData());

    return fetchOrderData(filter, page)
      .then(response => response.json())
      .then(json => dispatch(receiveOrderData(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function shouldfetchOrderData(state, page) {
  const orderData = state.orderData;
  if (!orderData) {
    return true;
  }

  if (orderData.isFetching) {
    return false;
  }

  if ((orderData.meta || {}).current_page != page) {
    return true;
  }

  return orderData.didInvalidate;
}

export function fetchOrderDataIfNeeded(filter, page) {
  return (dispatch, getState) => {
    if (shouldfetchOrderData(getState())) {
      return dispatch(asyncFetchOrderData(filter, page));
    }
  }
}

function receiveCreateOrder(order) {
  return {
    type: RECEIVE_CREATE_ORDER,
    order: order,
    receivedAt: Date.now(),
  }
}

export function asyncCreateOrder(body, fileAttachment) {
  return function (dispatch, getState) {
    createOrder(body)
      .then(response => response.json())
      .then(json => {
        if (fileAttachment) {
          const formData = new FormData();
          formData.append('file', fileAttachment);

          uploadFileAttachment(json.document_number, formData)
            .then((response) => response.json())
            .then((order) => {
              dispatch(receiveCreateOrder(order))
            })
            .catch((err) => {
              console.error(err);
              dispatch(receiveCreateOrder(json));
            });
        } else {
          dispatch(receiveCreateOrder(json));
        }
      })
  }
}

export function receiveCreateOrderInvoice(order) {
  return {
    type: RECEIVE_CREATE_ORDER_INVOICE,
    order: order,
    receivedAt: Date.now(),
  }
}

export function asyncCreateOrderInvoice(orderNumber) {
  return function (dispatch, getState) {
    createOrderInvoice(orderNumber)
      .then(response => response.json())
      .then(json => dispatch(receiveCreateOrderInvoice(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

export function asyncUpdateOrder(body) {
  return function (dispatch, getState) {
    updateOrder(body)
      .then(response => response.json())
      .then(json => dispatch(receiveUpdateOrder(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

export function receiveUpdateOrder(order) {
  return {
    type: RECEIVE_UPDATE_ORDER,
    order: order,
    receivedAt: Date.now(),
  }
}

export function asyncCancelOrder(orderNumber) {
  return function (dispatch, getState) {
    cancelOrder(orderNumber)
      .then(response => response.json())
      .then(json => dispatch(receiveUpdateOrder(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

export function asyncEmailOrder(orderNumber) {
  return function (dispatch, getState) {
    emailOrder(orderNumber)
      .then(response => response.json())
      .then(json => dispatch(receiveUpdateOrder(json)))
      // .catch(error => console.log("error: ", error)))
  }
}
