import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
} from '@material-ui/core';
import Companies from '../companies/companies';
import FinancialYears from '../companies/financial-years';
import { Link } from "react-router-dom";
import LogoutButton from '../logout/logout-button';
import i18n from '../i18n';
import auth from '../authentication/authentication'

import './navbar.scss';

export default function Navbar(props) {
  const {
    activeCompany,
    companies,
    updateActiveCompany,
    subscriptions,
    financialYears,
    selectFinancialYear,
    selectedFinancialYear,
    changingPassword,
  } = props;
  const t = i18n.getFixedT();

  const background = subscriptions.length == 1 && subscriptions.includes('sie_convert') ? '#0284C7' : '#3568fe'

  return (
    <div className="navbar">
      {props.authenticated &&
        <AppBar position="fixed" style={{ background: background }}>
          <Toolbar>
            <Link to="/">
              <Typography variant="h6">
                EkonomiMolnet
              </Typography>
            </Link>
            <div className="navbar-links">
              <Grid
                container
                spacing={4}
              >
                <Grid item xs={6} sm={4} md={3} lg={3}>
                  {!changingPassword &&
                    <React.Fragment>
                      {!subscriptions.includes('sie_convert') &&
                        <Link to="/">
                          {t('home')}
                        </Link>
                      }
                      {subscriptions.includes('orders') &&
                        <Link to="/orders">
                          {t('orders')}
                        </Link>
                      }
                      {subscriptions.includes('invoices') &&
                        <Link to="/invoices">
                          {t('invoices')}
                        </Link>
                      }
                      {subscriptions.includes('supplier_invoices') &&
                        <Link to="/supplier_invoices">
                          {t('payments')}
                        </Link>
                      }
                      {!subscriptions.includes('sie_convert') &&
                        <Link to="/reports">
                          {t('reports')}
                        </Link>
                      }
                      {subscriptions.includes('receipts') &&
                        <Link to="/receipts">
                          {t('receipts')}
                        </Link>
                      }
                      {!subscriptions.includes('sie_convert') &&
                        <Link to="/transactions">
                          {t('transactions')}
                        </Link>
                      }
                      {subscriptions.includes('documents') &&
                        <Link to="/documents">
                          {t('documents')}
                        </Link>
                      }
                      {!subscriptions.includes('sie_convert') && (process.env.REACT_APP_SYS_ADMIN_EMAILS || '').split(',').includes(auth.getEmail()) &&
                        <Link to="/sie">
                          SIE
                        </Link>
                      }
                    </React.Fragment>
                  }
                </Grid>
              </Grid>
            </div>
            <div className="menu-right-container">
              {!subscriptions.includes('sie_convert') &&
                <React.Fragment>
                  <FinancialYears
                    selectedFinancialYear={selectedFinancialYear}
                    financialYears={financialYears}
                    selectFinancialYear={selectFinancialYear}
                    disabled={changingPassword}
                  />
                  <Companies
                    activeCompany={activeCompany}
                    companies={companies}
                    updateActiveCompany={updateActiveCompany}
                    disabled={changingPassword}
                  />
                </React.Fragment>
              }
              <LogoutButton/>
            </div>
          </Toolbar>
        </AppBar>
      }
    </div>
  )
}
