import request from './request';

export function createTaxReduction(body) {
  return request('tax_reductions', {
    method: 'POST',
    body: body
  });
}

export function updateTaxReduction(id, body) {
  return request(`tax_reductions/${id}`, {
    method: 'PUT',
    body: body
  });
}

export function getTaxReductionByInvoice(invoiceId) {
  return request(`tax_reduction_by_invoice/${invoiceId}`, {
    method: 'GET'
  });
}
