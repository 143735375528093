import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from './translations/en.json';
import sv from './translations/sv.json';

const resources = {
  en, sv
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "sv",
    react: {
      wait: true,
      omitBoundRerender: false
    },

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
