import { initialState } from './initial-state';
import {
  REQUEST_CUSTOMER_DATA,
  RECEIVE_CUSTOMER_DATA,
  INVALIDATE_CUSTOMER_DATA,
  RECEIVE_CREATE_CUSTOMER,
  RECEIVE_UPDATE_CUSTOMER,
  RECEIVE_DELETE_CUSTOMER
} from '../actions/customer-action-types';

export default function customerReducer(state = initialState.customer, action) {
  switch (action.type) {
    case INVALIDATE_CUSTOMER_DATA:
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case REQUEST_CUSTOMER_DATA:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVE_CUSTOMER_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        data: action.customerData,
        lastUpdated: action.receivedAt
      });
    case RECEIVE_CREATE_CUSTOMER:
      return Object.assign({}, state, {
        data: [
          ...state.data,
          action.customer,
        ]
      });
    case RECEIVE_UPDATE_CUSTOMER:

      for (let i =0; i < state.data.length; i++)
        if (state.data[i].fortnox_customer_number === action.customer.fortnox_customer_number) {
          state.data[i] = action.customer;
          break;
       }

      return Object.assign({}, state, {
        data: [
          ...state.data,
        ]
      });
    case RECEIVE_DELETE_CUSTOMER:

      for (let i =0; i < state.data.length; i++)
        if (state.data[i].fortnox_customer_number === action.customerNumber) {
          state.data.splice(i,1);
          break;
       }

      return Object.assign({}, state, {
        data: [
          ...state.data,
        ]
      });
    default:
      return state
  }
}
