import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

import './report-card.scss';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: '20px',
  },
}));

export default function ReportCard(props) {
  const classes = useStyles();

  const renderLargeCard = (headerColor, title, subHeader, link, titleWidth, children) => {
    return (
      <Card>
        <CardHeader
          className={classes.header}
          style={{ background: headerColor, color: '#FFFFFF' }}
          title={
            <span style={{fontSize:'15px', width: titleWidth, float: 'left'}}>
              { link ?
                <Link style={{color: '#FFFFFF'}} to={link}>
                  {title}
                </Link>
              : title
              }
            </span>
          }
          subheader={subHeader}
        />
        <CardContent>
          {children}
        </CardContent>
      </Card>
    )
  }

  const renderMediumCard = (title, subHeader, icon, titleWidth, headerColor, headerBackground, children) => {
    return (
      <Card>
        <CardHeader
          className={classes.header}
          avatar={icon}
          title={
            <span style={{
              fontSize:'15px',
              width: titleWidth,
              float: 'left'
            }}>
              {title}
            </span>
          }
          subheader={<span style={{color: headerColor || '#000000'}}>{subHeader}</span>}
          style={{
            height: '25px',
            background: headerBackground || '#FFFFFF',
            color: headerColor || '#000000'
          }}
          // action={
          //   <IconButton aria-label="settings" style={{marginTop: '-5px'}}>
          //     <MoreVertIcon />
          //   </IconButton>
          // }
        />
        <CardContent>
          {children}
        </CardContent>
      </Card>
    )
  }

  const renderSmallCard = (title, subHeader, icon, double, children) => {
    return (
      <div className={double ? 'small-card-double' : 'small-card'}>
        <Card className={classes.root}>
          <CardMedia>
            {icon}
          </CardMedia>
          <CardHeader
            className={classes.header}
            title={title}
            subheader={subHeader}
            subheaderTypographyProps={{ fontSize: 10 }}
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {children}
            </Typography>
          </CardContent>
        </Card>
      </div>
    )
  }

  const {
    small,
    medium,
    title,
    subHeader,
    icon,
    children,
    headerColor,
    headerBackground,
    link,
    double,
    titleWidth,
  } = props;

  if (small) {
    return renderSmallCard(title, subHeader, icon, double, children);
  }

  if (medium) {
    return renderMediumCard(title, subHeader, icon, titleWidth, headerColor, headerBackground, children);
  }

  return renderLargeCard(headerColor, title, subHeader, link, titleWidth, children);
}
