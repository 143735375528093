import { initialState } from './initial-state';
import {
  REQUEST_DASHBOARD_DATA,
  RECEIVE_DASHBOARD_DATA,
  INVALIDATE_DASHBOARD_DATA,
} from '../actions/dashboard-action-types';

export default function dashboardReducer(state = initialState.dashboard, action) {
  switch (action.type) {
    case INVALIDATE_DASHBOARD_DATA:
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case REQUEST_DASHBOARD_DATA:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVE_DASHBOARD_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        data: action.dashboardData,
        lastUpdated: action.receivedAt
      });
    default:
      return state
  }
}
