import React from 'react';
import { Grid } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ReportCard from '../reports/report-card';
import i18n from '../i18n';
import { formatMoney } from '../utils/money-utils';

import './dashboard.scss';

export default function DashBoardSmallCards(props) {
  const t = i18n.getFixedT();

  const { data } = props;

  const revenueSubHeader = <p style={{float: 'right', margin: '5px', padding: 0}}>
    {`${t('revenue')} ${new Date().getFullYear()}`}
  </p>

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <ReportCard
          small={true}
          title={formatMoney(data.revenue.amount, data.revenue.currency, true)}
          subHeader={t('revenue')}
          icon={
            <AssessmentIcon
              style={{ color: '#66bb6a' }}
              className="small-card-icon"
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <ReportCard
          small={true}
          title={formatMoney(data.debts.amount, data.debts.currency, true)}
          subHeader={t('liabilities')}
          icon={
            <CreditCardIcon
              style={{ color: '#ef5350' }}
              className="small-card-icon"
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <ReportCard
          small={true}
          title={formatMoney(data.claims.amount, data.claims.currency, true)}
          subHeader={t('claims')}
          icon={
            <AccountBalanceWalletIcon
              style={{ color: '#ffa726' }}
              className="small-card-icon"
            />
          }
        />
      </Grid>
    </React.Fragment>
  )
}
