import React from 'react';
import {
  Grid,
  Button,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import InvoiceInput from './invoice-input';
import Select, { components } from 'react-select';
import {
  selectArticleStyles,
  selectUnitStyles,
  selectVatStyles,
  selectDiscountStyles,
} from './invoice-select-styles';
import i18n from '../i18n';
import { ACTIONS } from '../constants/actions';
import { houseWorkTypes } from '../house-work-types/house-work-types';

import './invoice-row.scss';

const vatSelectOptions = [
  { label: '0%', value: '0' },
  { label: '6%', value: '6' },
  { label: '12%', value: '12' },
  { label: '25%', value: '25' },
];

const discountSelectOptions = [
  { label: '0%', value: '0' },
  { label: '5%', value: '5' },
  { label: '10%', value: '10' },
  { label: '15%', value: '15' },
  { label: '20%', value: '20' },
  { label: '25%', value: '25' },
  { label: '30%', value: '30' },
  { label: '35%', value: '35' },
  { label: '40%', value: '40' },
  { label: '45%', value: '45' },
  { label: '50%', value: '50' },
];

export default function InvoiceRow(props) {
  const {
    index,
    onChange,
    onArticleChange,
    onAddArticle,
    onDeleteArticle,
    commonFields,
    removeRow,
    clearRow,
    articles,
    createdArticle,
    rowValues,
    errors,
    openArticleModal,
    update,
    taxReductions,
    selectedCustomer,
    includeHouseWork,
    units,
  } = props;
  const t = i18n.getFixedT();

  let articleValue;

  if (!update) {
    articleValue = articles.find((opt) => {
    return opt.value.article_number === rowValues.articleNumber ||
           (createdArticle && opt.value.article_number === createdArticle.article_number);
    });
  }

  const formatOptionLabel = ({ value, label }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center"
      }}
    >
      {label}
      <Icon
        className="customer-settings-icon update-customer-icon"
        onClick={(e) => openArticleModal(e, value.article_number)}
        title={t('updateCustomer')}
      >
        { 'settings' }
      </Icon>
    </div>
  );

  const SingleValue = ({ ...props }) => {
    return (
      <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
      )
  };

  return (
    <React.Fragment>
      {!update &&
        <Grid item xs={12} sm={12} md={2} lg={1}>
          <Select
            styles={selectArticleStyles}
            placeholder={t('articleNo')}
            options={articles}
            onChange={(selectedOption) => onArticleChange(selectedOption.value)}
            selected={rowValues.articleNumber}
            value={articleValue || ''}
            noOptionsMessage={() => t('noArticles')}
            components={{ SingleValue }}
            formatOptionLabel={formatOptionLabel}
          />
        </Grid>
      }
      {
        // TODO: Use common/form-input
      }
      <InvoiceInput
        fullWidth={true}
        placeholder={!rowValues.description ? t('designation') : undefined}
        variant="outlined"
        onChange={(event) => onChange('description', event.target.value)}
        sm={12}
        md={12}
        lg={update ? 12 : (includeHouseWork ? 3 : 4)}
        value={rowValues.description}
        error={errors.includes('description')}
      />

      {includeHouseWork && taxReductions ?
        taxReductions.map(field => (
          <Grid item xs={12} sm={12} md={2} lg={update ? 12 : 1} key={field}>
            <Select
              styles={selectArticleStyles}
              placeholder={field === "RUT" ? "Ej Rut" : "Ej Rot"}
              options={houseWorkTypes[field]}
              onChange={(selectedOption) => onChange('houseWorkType', selectedOption.value)}
              value={houseWorkTypes[field].find(opt => opt.value === rowValues.houseWorkType) || ''}
            />
          </Grid>
        ))
      : null }

      <Grid item xs={12} sm={12} md={2} lg={update ? 12 : 1}>
        <Select
          styles={selectUnitStyles(errors.includes('unit'))}
          placeholder={t('unit')}
          options={units}
          onChange={(selectedOption) => onChange('unit', selectedOption.value)}
          value={units.find(opt => opt.value === rowValues.unit) || ''}
        />
      </Grid>

      {commonFields.map(field => (
        // TODO: Use common/form-input
        <InvoiceInput
          key={field}
          fullWidth={true}
          placeholder={!rowValues[field] ? t(field) : undefined}
          variant="outlined"
          onChange={(event) => onChange(field, event.target.value)}
          sm={6}
          md={3}
          lg={update ? 12 : 1}
          value={rowValues[field]}
          error={errors.includes(field)}
        />
      ))}

      <Grid item xs={12} sm={12} md={2} lg={update ? 12 : 1}>
        <Select
          styles={selectVatStyles(errors.includes('vat'))}
          placeholder={t('vat')}
          options={vatSelectOptions}
          onChange={(selectedOption) => onChange('vat', selectedOption.value)}
          value={{ label: `${rowValues.vat || 25}%`, value: rowValues.vat || '25' }}
        />
      </Grid>

      {!update &&
        <React.Fragment>
          <Grid item xs={12} sm={12} md={2} lg={update ? 12 : 1}>
            <Select
              styles={selectDiscountStyles(false)}
              placeholder={t('discount')}
              options={discountSelectOptions}
              onChange={(selectedOption) => onChange('discount', selectedOption.value)}
              value={discountSelectOptions.find(opt => opt.value === rowValues.discount) || ''}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={2}>
            {removeRow &&
              <Button
                style={{
                  color: '#FFFFFF',
                  fontSize: 11,
                  background: '#e53935',
                  marginTop: 5,
                  marginRight: 3,
                  float: 'left',
                }}
                onClick={() => removeRow(false)}
              >
                {t('remove')}
              </Button>
            }
            {clearRow &&
              <Button
                style={{
                  color: '#FFFFFF',
                  fontSize: 11,
                  background: '#e53935',
                  marginTop: 5,
                  float: 'left',
                }}
                onClick={() => clearRow(index)}
              >
                {t('clear')}
              </Button>
            }
            {!articleValue && ['unit', 'price', 'description'].every(field => rowValues[field]) ? (
              <Icon
                className="create-article-icon"
                onClick={() => onAddArticle(rowValues)}
                title={t('saveArticle')}
              >
                save
              </Icon>
            ) : (
              <Icon
                className="create-article-icon create-article-icon-disabled"
              >
                save
              </Icon>
            )}
          </Grid>
        </React.Fragment>
      }

      {update &&
        <Grid container justify="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              onClick={() => onAddArticle(rowValues, ACTIONS.UPDATE)}
              style={{
                color: '#FFFFFF',
                fontSize: 12,
                background: '#66bb6a',
                margin: '10px 0 5px 4px',
                float: 'left',
              }}
            >
              {t("updateArticle")}
            </Button>

            <Button
              onClick={() => onDeleteArticle()}
              style={{
                color: '#FFFFFF',
                fontSize: 12,
                background: '#e53935',
                margin: '10px 0 5px 4px',
                float: 'right',
              }}
            >
              {t('deleteArticle')}
            </Button>
          </Grid>
        </Grid>
      }
    </React.Fragment>
  )
}
