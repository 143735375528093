import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import './App.css';
import Navbar from './navbar/navbar';
import LoginForm from './login/login-form';
import Dashboard from './dashboard/dashboard';
import Invoices from './invoices/invoices';
import Orders from './orders/orders';
import SupplierInvoices from './supplier-invoices/supplier-invoices';
import Reports from './reports/reports';
import Receipts from './receipts/receipts';
import Transactions from './transactions/transactions';
import Documents from './documents/documents';
import DocumentPresenter from './documents/document-presenter';
import Sie from './sie/sie';
import Owner from './documents/owner'
import ChangePasswordForm from './employees/change-password-form';
import Oauth from './login/oauth';
import SessionExpired from './common/session-expired';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import auth from './authentication/authentication';
import  {
  updateActiveCompany,
  getCompanies,
  getProfile,
  changePassword,
} from './services/employees';
import { refreshOauth } from './services/authentication';
import { fetchFinancialYears } from './services/fortnox-companies';
import { uploadFileAttachment } from './services/orders';
import { invalidateDashboardData } from './actions/dashboard';
import { invalidateInvoiceData } from './actions/invoices';
import { invalidateSupplierInvoiceData } from './actions/supplier-invoices';
import { invalidateCustomerData } from './actions/customers';
import { invalidateArticleData } from './actions/articles';
import { invalidateReportData } from './actions/reports';
import { invalidateOrderData } from './actions/orders';
import { invalidateTransactionData } from './actions/transactions';
import { useInterval } from './common/use-interval';
import i18n from './i18n';

const whitelistedRoutes = ['/', '/transactions'];

function App(props) {
  const t = i18n.getFixedT();

  const [activeCompany, setActiveCompany] = useState(auth.getCompanyName());
  const [authenticated, setAuthenticated] = useState(auth.isAuthenticated());
  const [companies, setCompanies] = useState([]);
  const [subscriptions, setSubscriptions] = useState(auth.getSubscriptions());
  const [resetReportParams, setResetReportParams] = useState(false);
  const [financialYears, setFinancialYears] = useState([]);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState(null);
  const [promptChangePassword, setPromptChangePassword] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);

  const expireSession = () => {
    auth.logout();
  }

  const changeActiveCompany = (activeCompanyId) =>  {
    setResetReportParams(/accountGroups/.test(window.location.search));

    updateActiveCompany(auth.getEmployeeId(), activeCompanyId)
      .then(response => response.json())
      .then((json) => {
        const accessToRoute = json.subscriptions.some((subscription) => {
          if (whitelistedRoutes.includes(window.location.pathname)) {
            return true;
          }
          return new RegExp(subscription, 'g').test(window.location.pathname)
        });

        if (!accessToRoute) {
          window.location.pathname = null;
          window.location.reload();
        }

        setActiveCompany(json.active_company.name);
        auth.setCompanyName(json.active_company.name);
        auth.setCompanyId(json.active_company.id);
        auth.setCompanyTaxReductions(json.company_tax_reductions || []);
        auth.setSubscriptions(json.subscriptions);
        auth.setOauthToken(json.oauth_access_token || '');
        auth.setLegacyAuth(json.legacy_auth);
        setSubscriptions(json.subscriptions);
        setResetReportParams(false);

        if (json.legacy_auth) {
          fetchFinancialYears()
          .then(response => response.json())
          .then((financialYears) => {
            setFinancialYears(financialYears);
            const selectedYear = financialYears[0];
            auth.setSelectedFinancialYear(selectedYear.id);
            setSelectedFinancialYear(selectedYear);
          }).finally(() => {
            props.dispatch(invalidateDashboardData());
            props.dispatch(invalidateInvoiceData());
            props.dispatch(invalidateSupplierInvoiceData());
            props.dispatch(invalidateCustomerData());
            props.dispatch(invalidateArticleData());
            props.dispatch(invalidateReportData());
            props.dispatch(invalidateReportData());
            props.dispatch(invalidateOrderData());
            props.dispatch(invalidateTransactionData());
            window.location.reload();
          });
        } else {
          auth.setOauthToken(null);
          window.location.href = '/oauth';
        }
      });
  }

  const selectFinancialYear = (financialYear) => {
    props.dispatch(invalidateDashboardData());
    props.dispatch(invalidateInvoiceData());
    props.dispatch(invalidateSupplierInvoiceData());
    props.dispatch(invalidateCustomerData());
    props.dispatch(invalidateArticleData());
    props.dispatch(invalidateReportData());
    props.dispatch(invalidateOrderData());
    props.dispatch(invalidateTransactionData());

    auth.setSelectedFinancialYear(financialYear.id);
    setSelectedFinancialYear(financialYear);
  }

  const onChangePassword = (newPassword) => {
    changePassword(auth.getEmployeeId(), newPassword).then((resp) => {
      if (resp.status === 200) {
        setPromptChangePassword(false);
      }

      if (resp.status === 400) {
        resp.json().then((body) => {
          if (body.error === 'same_password') {
            setChangePasswordError('validations.samePassword')
          }
        });
      }
    }).catch((err) => {
      console.log("err: ", err);
    });
  }

  const resetPasswordValidationError = () => {
    setChangePasswordError(null);
  }

  const resolveSelectedFinancialYear = () => {
    fetchFinancialYears()
      .then(response => response.json())
      .then((financialYears) => {
        setFinancialYears(financialYears);

        const storedSelectedYear = financialYears.find((financialYear) => {
          return financialYear.id.toString() === auth.getSelectedFinancialYear();
        });

        const selectedYear = storedSelectedYear ? storedSelectedYear : financialYears[0];

        auth.setSelectedFinancialYear(selectedYear.id);
        setSelectedFinancialYear(selectedYear);
      });
  }

  useInterval(() => {
    const oauthTokenPresent = auth.getOauthToken() !== undefined &&
                              auth.getOauthToken() !== null &&
                              auth.getOauthToken() != '';
    const expiresInSeconds = (auth.getOauthTokenExpiresAt() - Date.now()) / 1000;
    if (authenticated && oauthTokenPresent && expiresInSeconds < 60) {
      refreshOauth(auth.getCompanyId())
        .then(response => response.json())
        .then((data) => {
          auth.setOauthToken(data.access_token);
          auth.setOauthTokenExpiresAt(data.expires_at);
        })
        .catch(() => {
          setSessionExpired(true);
        });
    }
  }, 5000);

  useEffect(() => {
    const oauthTokenPresent = auth.getOauthToken() !== undefined &&
                              auth.getOauthToken() !== null &&
                              auth.getOauthToken() != '';
    const path = window.location.pathname;

    if (authenticated &&
       (oauthTokenPresent || auth.getLegacyAuth()) &&
       path !== '/oauth' &&
       companies.length === 0) {

      resolveSelectedFinancialYear();

      getProfile(auth.getEmployeeId())
        .then(response => response.json())
        .then(data => {
          auth.setCompanyId(data.company_id);
          auth.setCompanyName(data.company_name);
          auth.setCompanyTaxReductions(data.company_tax_reductions);
          auth.setEmail(data.email);
          auth.setEmployeeId(data.employee_id);
          auth.setToken(data.token);
          auth.setSubscriptions(data.subscriptions);
          setSubscriptions(data.subscriptions);
          setPromptChangePassword(!data.changed_password)
        }).catch((err) => {
          auth.removeSessionData();
          setAuthenticated(false);
        });

      getCompanies(auth.getEmployeeId())
        .then(response => response.json())
        .then((companies) => {
          setCompanies(companies);
          setActiveCompany(auth.getCompanyName());
        });
    }
  });

  auth.onAuthenticated = (subscriptions, changedPassword) => {
    setPromptChangePassword(!changedPassword);
    setSubscriptions(subscriptions);
    setAuthenticated(true);

    if (auth.getLegacyAuth()) {
      resolveSelectedFinancialYear();
    }
  }

  auth.onLogout = () => {
    setAuthenticated(false);
    setSessionExpired(false);
  }

  return (
    <Router>
      <div className="App">
        { sessionExpired &&
          <SessionExpired onClose={expireSession}/>
        }

        <Navbar
          authenticated={authenticated}
          activeCompany={activeCompany}
          subscriptions={subscriptions}
          updateActiveCompany={changeActiveCompany}
          companies={companies}
          financialYears={financialYears}
          selectFinancialYear={selectFinancialYear}
          selectedFinancialYear={selectedFinancialYear}
          changingPassword={promptChangePassword}
        />
          <Switch>
            <UnAuthenticatedRoute
              authenticated={authenticated}
              path="/login"
            >
              <LoginForm/>
            </UnAuthenticatedRoute>

            {promptChangePassword ?
              <ChangePasswordForm
                error={changePasswordError}
                changePassword={onChangePassword}
                resetPasswordValidationError={resetPasswordValidationError}
              />
            :
              subscriptions.length == 1 && subscriptions.includes('sie_convert') ?
                <Sie/>
              :
                <React.Fragment>
                  <AuthenticatedRoute
                    authenticated={authenticated}
                    access={true}
                    path="/oauth"
                  >
                    <Oauth onAuthSuccess={resolveSelectedFinancialYear}/>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    authenticated={authenticated}
                    access={true}
                    exact
                    path="/"
                  >
                    <Dashboard
                      subscriptions={subscriptions}
                      financialYears={financialYears}
                      selectedFinancialYear={selectedFinancialYear}
                    />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    authenticated={authenticated}
                    access={true}
                    path="/reports"
                    resetRouteParams={resetReportParams}
                  >
                    <Reports
                      financialYears={financialYears}
                      selectedFinancialYear={selectedFinancialYear}
                    />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    authenticated={authenticated}
                    access={subscriptions.includes('orders')}
                    path="/orders"
                  >
                    <Orders/>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    authenticated={authenticated}
                    access={subscriptions.includes('invoices')}
                    path="/invoices"
                  >
                    <Invoices/>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    authenticated={authenticated}
                    access={subscriptions.includes('supplier_invoices')}
                    path="/supplier_invoices"
                  >
                    <SupplierInvoices/>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    authenticated={authenticated}
                    access={subscriptions.includes('receipts')}
                    path="/receipts"
                  >
                    <Receipts selectedFinancialYear={selectedFinancialYear}/>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    authenticated={authenticated}
                    access={true}
                    path="/transactions"
                  >
                    <Transactions selectedFinancialYear={selectedFinancialYear}/>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    authenticated={authenticated}
                    access={subscriptions.includes('documents')}
                    path="/documents"
                  >
                    <Documents selectedFinancialYear={selectedFinancialYear}/>
                      <AuthenticatedRoute
                        authenticated={authenticated}
                        access={subscriptions.includes('documents')}
                        path="/documents/owner"
                      >
                        <DocumentPresenter documentCategory='owner'/>
                      </AuthenticatedRoute>
                      <AuthenticatedRoute
                        authenticated={authenticated}
                        access={subscriptions.includes('documents')}
                        path="/documents/skatteverket"
                      >
                        <DocumentPresenter documentCategory='skatteverket'/>
                      </AuthenticatedRoute>
                      <AuthenticatedRoute
                        authenticated={authenticated}
                        access={subscriptions.includes('documents')}
                        path="/documents/bolagsverket"
                      >
                        <DocumentPresenter documentCategory='bolagsverket'/>
                      </AuthenticatedRoute>
                      <AuthenticatedRoute
                        authenticated={authenticated}
                        access={subscriptions.includes('documents')}
                        path="/documents/reports"
                      >
                        <DocumentPresenter documentCategory='reports'/>
                      </AuthenticatedRoute>
                      <AuthenticatedRoute
                        authenticated={authenticated}
                        access={subscriptions.includes('documents')}
                        path="/documents/contracts"
                      >
                        <DocumentPresenter documentCategory='contracts'/>
                      </AuthenticatedRoute>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute
                    authenticated={authenticated}
                    access={true}
                    path="/sie"
                  >
                    <Sie/>
                  </AuthenticatedRoute>
                </React.Fragment>
              }
          </Switch>
      </div>
    </Router>
  );
}

function UnAuthenticatedRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        rest.authenticated ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

function AuthenticatedRoute({ children, ...rest }) {
  if (rest.resetRouteParams && rest.authenticated && rest.access) {
    return <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: location.pathname,
            search: "",
          }}
        />
      )}
    />
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        rest.authenticated && rest.access ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired
}

export default connect(null)(App);
