import React from 'react';
import { useState, useEffect } from 'react';
import i18n from '../i18n';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Grid } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Loader from '../common/loader';
import { fetchFileAttachment } from '../services/orders';
import { extractUuid } from '../utils/file-utils';

import './file-uploader.scss';

export default class FileUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      fileUrl: null,
      contentType: null,
      numPages: null,
      pageNumber: 1
    }

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.renderPdf = this.renderPdf.bind(this);
    this.renderImage = this.renderImage.bind(this);
    this.renderFilePreview = this.renderFilePreview.bind(this);
  }

  componentDidMount() {
    const {
      tempFile,
      orderFileAttachmentPath,
      order,
    } = this.props;

    if (orderFileAttachmentPath) {
      fetchFileAttachment(order.document_number, extractUuid(orderFileAttachmentPath))
        .then((response) => response.json())
        .then((result) => {
          this.setState({
            contentType: result.content_type,
            fileUrl: result.url,
          })
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

    if (tempFile && !this.state.file) {
      this.setState({
        contentType: tempFile.type,
        file: tempFile,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      tempFile,
      orderFileAttachmentPath,
      order,
    } = this.props;

    if (orderFileAttachmentPath && orderFileAttachmentPath !== prevProps.orderFileAttachmentPath) {
      fetchFileAttachment(order.document_number, extractUuid(orderFileAttachmentPath))
        .then((response) => response.json())
        .then((result) => {
          this.setState({
            contentType: result.content_type,
            fileUrl: result.url,
          })
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

    if (tempFile && prevProps.tempFile !== tempFile && !this.state.file) {
      this.setState({
        contentType: tempFile.type,
        file: tempFile,
      })
    }
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages: numPages });
  }

  handleChange(event) {
    const file = event.target.files[0];

    this.setState({
      contentType: file.type,
      file: file,
    })
  }

  handleSubmit() {
    const { order, onUpload } = this.props;

    onUpload && onUpload(order, this.state.file);
  }

  handleDelete() {
    const { orderFileAttachmentPath, onDelete, order, onClose } = this.props;

    this.setState({
      contentType: null,
      fileUrl: null,
      file: null,
    });

    if (orderFileAttachmentPath) {
      onDelete && onDelete(order.document_number, extractUuid(orderFileAttachmentPath));
    }
  }

  renderActions() {
    const t = i18n.getFixedT();
    const { loading, orderFileAttachmentPath } = this.props;

    if (loading) {
      return (
        <Loader
          style={{marginLeft: 10}}
          size={30}
        />
      )
    }

    if (!this.state.file || orderFileAttachmentPath) {
      return null;
    }

    return (
      <Button
        onClick={this.handleSubmit}
        style={{
          color: '#FFFFFF',
          fontSize: 12,
          background: '#66bb6a',
          float: 'left',
        }}
      >
        {t('save')}
      </Button>
    )
  }

  renderPdf(fileUrl, file) {
    const { pageNumber, numPages } = this.state;

    return (
      <div className="file-uploader-pdf">
        <Document
          file={fileUrl || file}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page
            height={600}
            width={600}
            pageNumber={pageNumber}
          />
        </Document>
        <p>Sida {pageNumber} av {numPages}</p>
      </div>
    )
  }

  renderImage(fileUrl, file) {
    if (fileUrl) {
      return (
        <img id="img-preview" width={600} src={fileUrl}/>
      )
    }

    const fileReader = new FileReader();

    fileReader.onload = function(){
      const imgPreview = document.getElementById('img-preview');
      imgPreview.src = fileReader.result;
    };

    fileReader.readAsDataURL(file);

    return (
      <img id="img-preview" width={600}/>
    )
  }

  renderFilePreview() {
    const { file, fileUrl, contentType } = this.state;

    if (!file && !fileUrl) {
      return;
    }

    if (contentType === 'application/pdf') {
      return this.renderPdf(fileUrl, file);
    }

    if (contentType === 'image/png' || contentType === 'image/jpeg') {
      return this.renderImage(fileUrl, file)
    }
  }

  render() {
    const t = i18n.getFixedT();
    const {
      open,
      onClose,
      orderFileAttachmentPath
    } = this.props;

    const { file, fileUrl } = this.state;
    return (
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="custom-modal">
          <div className="modal-header">
            <span className="modal-title">{t('uploadOrderAttachment')}</span>
            <Icon
              onClick={() => onClose(false)}
              style={{
                margin: '10px 0 5px 4px',
                float: 'right',
                cursor: 'pointer'
              }}
            >
              clear
            </Icon>
          </div>
          <div className="modal-body">
            { !orderFileAttachmentPath &&
              <React.Fragment>
                <input
                  onChange={(event) => this.handleChange(event)}
                  type="file"
                  id="file-upload"
                  accept="application/pdf,image/png,image/jpeg"
                  hidden
                  value={fileUrl || ''}
                />
                <label htmlFor="file-upload" className="choose-file-label">
                  {t('chooseFile')}
                </label>
              </React.Fragment>
            }

            { this.renderFilePreview() }

            { orderFileAttachmentPath && fileUrl &&
              <Button
                variant="outlined"
                onClick={() => window.open(fileUrl)}
              >
                {t('openInNewTab')}
              </Button>
            }

            { (file || fileUrl) &&
              <Icon
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  color: '#e53935',
                  fontSize: '35px'
                }}
                onClick={this.handleDelete}
              >
                delete
              </Icon>
            }

            { this.renderActions() }
          </div>
        </div>
      </Modal>
    );
  }
}

