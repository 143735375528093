import { initialState } from './initial-state';
import {
  REQUEST_RESULT_DOCUMENT_DATA,
  RECEIVE_RESULT_DOCUMENT_DATA,
  INVALIDATE_RESULT_DOCUMENT_DATA,
} from '../actions/document-action-types';

export default function documentReducer(state = initialState.document, action) {
  switch (action.type) {
    case INVALIDATE_RESULT_DOCUMENT_DATA:
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case REQUEST_RESULT_DOCUMENT_DATA:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVE_RESULT_DOCUMENT_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        data: action.documentData,
        lastUpdated: action.receivedAt
      });
    default:
      return state
  }
}
