import request from './request';

export function fetchInvoiceData(page) {
  return request(`invoices?page=${page || 1}`, {
    method: 'GET'
  });
}

export function fetchInvoice(invoiceNumber) {
  return request(`invoices/${invoiceNumber}`, {
    method: 'GET'
  });
}

export function createInvoice(invoice) {
  return request('invoices', {
    method: 'POST',
    body: invoice
  });
}

export function updateInvoice(invoice) {
  return request(`invoices/${invoice.document_number}`, {
    method: 'PATCH',
    body: invoice
  });
}

export function createInvoicePayment(data) {
  return request(`invoices/${data.document_number}/payments`, {
    method: 'POST',
    body: data
  });
}

export function bookkeepInvoice(invoiceNumber) {
  return requestInvoiceAction('bookkeep', invoiceNumber);
}

export function cancelInvoice(invoiceNumber) {
  return requestInvoiceAction('cancel', invoiceNumber);
}

export function creditInvoice(invoiceNumber) {
  return requestInvoiceAction('credit', invoiceNumber);
}

export function emailInvoice(invoiceNumber) {
  return requestInvoiceAction('email', invoiceNumber);
}

export function fetchInvoicePdf(invoiceNumber) {
  request(`invoices/${invoiceNumber}/preview`, {
    method: 'GET',
  }).then(response => response.blob())
    .then((blob) => {
      const file = new Blob([blob], {
        type: 'application/pdf',
      });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL);
    });
}

export function printInvoice(invoiceNumber) {
  request(`invoices/${invoiceNumber}/print`, {
    method: 'GET',
  }).then(response => response.blob())
    .then((blob) => {
      const file = new Blob([blob], {
        type: 'application/pdf',
      });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL);
    });
}

function requestInvoiceAction(action, invoiceNumber) {
  return request(`invoices/${invoiceNumber}/${action}`, {
    method: 'POST',
    body: {}
  });
}
