import request from './request';

export function fetchCustomerData(query) {
  const url = query ? `customers?query=${query}` : 'customers';
  return request(url, {
    method: 'GET'
  });
}

export function fetchCustomer(customerNumber) {
  return request(`customers/${customerNumber}`, {
    method: 'GET'
  });
}

export function createCustomer(body) {
  return request('customers', {
    method: 'POST',
    body: body
  });
}

export function updateCustomer(body, customerNumber) {
  return request(`customers/${customerNumber}`, {
    method: 'PUT',
    body: body
  });
}

export function deleteCustomer(customerNumber) {
  return request(`customers/${customerNumber}`, {
    method: 'DELETE'
  });
}
