import { initialState } from './initial-state';
import {
  REQUEST_INVOICE_DATA,
  RECEIVE_INVOICE_DATA,
  INVALIDATE_INVOICE_DATA,
  RECEIVE_CREATE_INVOICE,
  RECEIVE_UPDATE_INVOICE,
  RECEIVE_CREATE_INVOICE_PAYMENT,
  RECEIVE_BOOKKEEP_INVOICE,
} from '../actions/invoice-action-types';

export default function invoiceReducer(state = initialState.invoice, action) {
  switch (action.type) {
    case INVALIDATE_INVOICE_DATA:
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case REQUEST_INVOICE_DATA:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVE_INVOICE_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        data: action.invoiceData,
        meta: action.meta,
        lastUpdated: action.receivedAt
      });
    case RECEIVE_CREATE_INVOICE:
      return Object.assign({}, state, {
        data: [
          action.invoice,
          ...state.data,
        ],
        latestInvoice: action.invoice,
        lastUpdated: action.receivedAt
      });
    case RECEIVE_UPDATE_INVOICE:
      return Object.assign({}, state, {
        data: state.data.map((invoice) => {
          if (invoice.document_number === action.invoice.document_number) {
            return action.invoice;
          }

          return invoice;
        }),
        lastUpdated: action.receivedAt
      });
    case RECEIVE_CREATE_INVOICE_PAYMENT:
      return Object.assign({}, state, {
        data: state.data.map((invoice) => {
          if (invoice.document_number === action.invoicePayment.invoice_number) {
            invoice.paid = true;
            invoice.updated = true;
          }

          return invoice;
        }),
        lastUpdated: action.receivedAt
      });
    case RECEIVE_BOOKKEEP_INVOICE:
      return Object.assign({}, state, {
        data: state.data.map((invoice) => {
          if (invoice.document_number === action.invoice.document_number) {
            invoice.booked = true;
            invoice.updated = true;
          }

          return invoice;
        }),
        lastUpdated: action.receivedAt
      });
    default:
      return state
  }
}


