import React from 'react';
import { useState, useEffect } from 'react';
import i18n from '../i18n';
import FileUploader from '../common/file-uploader';
import { uploadFileAttachments } from '../services/receipts';
import auth from '../authentication/authentication';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: '20px',
  },
}));

export default function Receipts(props) {
  const t = i18n.getFixedT();
  const { selectedFinancialYear } = props;
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [successMessageTransKey, setSuccessMessageTransKey] = useState(null);

  const openFileUploadModal = () => {
    setSuccessModalOpen(false);
    setSuccessMessageTransKey(null);
    setUploadModalOpen(true);
  }

  const uploadAttachments = (attachments, fileName) => {
    setUploading(true);
    setSuccessMessageTransKey(null);

    const formData = new FormData();

    attachments.forEach((attachment) => {
      formData.append('files[]', attachment.file);
    });

    formData.append('attachment_type', 'receipt');
      uploadFileAttachments(formData)
        .then((response) => response.json())
        .then((result) => {
          if (result.status && result.status !== 200) {
            alert("Något fick fel, vänligen försök igen eller kontakta support@ekonomimolnet.se");
          } else {
            setUploadModalOpen(false);
            setSuccessMessageTransKey('receiptUploadSuccess');
            setSuccessModalOpen(true);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          alert("Något fick fel, vänligen försök igen eller kontakta support@ekonomimolnet.se");
        })
        .finally(() => setUploading(false));
  }

  const classes = useStyles();

  return (
    <Grid container justify="center" spacing={4} style={{marginTop: '20px'}}>
      <Grid item xs={12} sm={10} md={8} lg={4}>
        <Card>
          <CardHeader
            className={classes.header}
            style={{ background: '#f1f1f1', color: '#000000' }}
            title={
              <span style={{fontSize:'15px', width: '100%', float: 'left'}}>
                {t('uploadReceipt')}
              </span>
            }
          />
          <CardContent>
            <div style={{ fontSize: '12px', textAlign: 'left' }}>
              <p>
                Här kan du ladda upp kvitton som rör din verksamhet.

                Det kan exempelvis vara kvitton för inköp, utlägg eller resor.
              </p>
              <p>
                Du kan ladda upp flera kvitton på samma gång.
              </p>
              <p style={{ fontStyle: 'italic'}}>
                Glöm inte att arkivera kvittot om det finns ett original i pappersformat.
              </p>
              <Button
                onClick={openFileUploadModal}
                style={{
                  color: '#FFFFFF',
                  fontSize: 12,
                  background: '#66bb6a',
                  marginTop: 20,
                  width: '200px',
                  height: '50px'
                }}
              >
                {t('upload')}
              </Button>
            </div>
          </CardContent>
        </Card>

        <FileUploader
          onClose={() => setUploadModalOpen(false)}
          onUpload={uploadAttachments}
          open={uploadModalOpen}
          multiple={true}
          loading={uploading}
        />

        <Modal
          open={successModalOpen}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="custom-modal">
            <div className="modal-header">
              <span className="modal-title">{t('uploadSucceeded')}</span>
              <Icon
                onClick={() => setSuccessModalOpen(false)}
                style={{
                  margin: '10px 0 5px 4px',
                  float: 'right',
                  cursor: 'pointer'
                }}
              >
                clear
              </Icon>
            </div>
            <div className="modal-body">
              <Grid container>
                <Grid item xs={12}>
                  <p>{t(successMessageTransKey)}</p>

                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={openFileUploadModal}
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      background: '#66bb6a',
                      float: 'left',
                      marginRight: '5px'
                    }}
                  >
                    {t('uploadMore')}
                  </Button>
                  <Button
                    onClick={() => setSuccessModalOpen(false)}
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      background: '#66bb6a',
                      float: 'left',
                    }}
                  >
                    {t('close')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Modal>
      </Grid>
    </Grid>
  )
}
