import { initialState } from './initial-state';

import {
  REQUEST_TRANSACTION_DATA,
  RECEIVE_TRANSACTION_DATA,
  INVALIDATE_TRANSACTION_DATA,
} from '../actions/transaction-action-types';

export default function transactionReducer(state = initialState.transaction, action) {
  switch (action.type) {
    case INVALIDATE_TRANSACTION_DATA:
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case REQUEST_TRANSACTION_DATA:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVE_TRANSACTION_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        data: action.transactionData,
        meta: action.meta,
        lastUpdated: action.receivedAt
      });
    default:
      return state
  }
}


