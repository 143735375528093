import {
  REQUEST_CUSTOMER_DATA,
  RECEIVE_CUSTOMER_DATA,
  INVALIDATE_CUSTOMER_DATA,
  RECEIVE_CREATE_CUSTOMER,
  RECEIVE_UPDATE_CUSTOMER,
  RECEIVE_DELETE_CUSTOMER,
} from './customer-action-types';
import {
  fetchCustomerData,
  createCustomer,
  updateCustomer,
  deleteCustomer
} from '../services/customers';

function receiveCustomerData(customerData) {
  return {
    type: RECEIVE_CUSTOMER_DATA,
    customerData: customerData,
    receivedAt: Date.now(),
  }
}

function requestCustomerData() {
  return {
    type: REQUEST_CUSTOMER_DATA
  }
}

export function invalidateCustomerData() {
  return {
    type: INVALIDATE_CUSTOMER_DATA
  }
}

function asyncFetchCustomerData() {
  return function (dispatch, getState) {
    dispatch(requestCustomerData());

    return fetchCustomerData()
      .then(response => response.json())
      .then(json => dispatch(receiveCustomerData(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function shouldfetchCustomerData(state) {
  const customerData = state.customerData;
  if (!customerData) {
    return true;
  }

  if (customerData.isFetching) {
    return false;
  }

  return customerData.didInvalidate;
}

export function fetchCustomerDataIfNeeded() {
  return (dispatch, getState) => {
    if (shouldfetchCustomerData(getState())) {
      return dispatch(asyncFetchCustomerData());
    }
  }
}

function receiveCreateCustomer(customer) {
  return {
    type: RECEIVE_CREATE_CUSTOMER,
    customer: customer,
  }
}

export function asyncCreateCustomer(body) {
  return function (dispatch, getState) {
    createCustomer(body)
      .then(response => response.json())
      .then(json => dispatch(receiveCreateCustomer(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function receiveUpdateCustomer(customer) {
  return {
    type: RECEIVE_UPDATE_CUSTOMER,
    customer: customer,
  }
}

export function asyncUpdateCustomer(body, customerNumber) {
  return function (dispatch, getState) {
    updateCustomer(body, customerNumber)
      .then(response => response.json())
      .then(json => dispatch(receiveUpdateCustomer(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function receiveDeleteCustomer(customerNumber) {
  return {
    type: RECEIVE_DELETE_CUSTOMER,
    customerNumber: customerNumber,
  }
}

export function asyncDeleteCustomer(customerNumber) {
  return function (dispatch, getState) {
    deleteCustomer(customerNumber)
      .then(response => response.json())
      .then(json => dispatch(receiveDeleteCustomer(customerNumber)))
      // .catch(error => console.log("error: ", error))
  }
}
