import auth from '../authentication/authentication';

const BASE_URL = process.env.REACT_APP_AR_API_URL;

export default function request(endpoint, options = {}) {
  const {
    headers = {},
    body,
    ...restOptions
  } = options;

  headers["Accept"] = options.accept || "application/json";

  if (body) {
    restOptions.body = JSON.stringify(body);
    headers['Content-Type'] = options.contentType || 'application/json';
  }

  const token = auth.getToken();
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const requestOptions = {
    ...restOptions,
    method: options.method || 'GET',
    headers: headers,
  }

  return new Promise((resolve, reject) => {
    fetch(`${BASE_URL}/${endpoint}`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          if (!/tokens/.test(endpoint) && !/refresh_oauth2/.test(endpoint)) {
            auth.logout();
          }
          reject(response);
        }
        resolve(response);
      })
      .catch(error => reject(error));
  });
}
