import React from 'react';
import { useState, useEffect } from 'react';
import FormInput from '../common/form-input';
import {
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import i18n from '../i18n';

export default function ChangePasswordForm(props) {
  const {
    resetPasswordValidationError,
    error,
  } = props;
  const t = i18n.getFixedT();

  const [errorTransKey, setErrorTransKey] = useState(error);
  const [password, setPassword] = useState(null);
  const [passwordAgain, setPasswordAgain] = useState(null);

  useEffect(() => {
    if (error && error != errorTransKey) {
      setErrorTransKey(error);
    }
  });

  const onSubmit = (event) => {
    event.preventDefault();

    if (password !== passwordAgain) {
      resetPasswordValidationError();
      setErrorTransKey('validations.passwordMismatch');
      return;
    }

    if (password.length < 8) {
      resetPasswordValidationError();
      setErrorTransKey('validations.passwordToShort');
      return;
    }

    props.changePassword(password);
  }

  return (
    <Grid container justify="center" spacing={2} style={{marginTop: 100}}>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Grid item xs={12} align="left">
          <h1>{t('welcomeToEkonomiMolnet')}</h1>
          <p>{t('firstLoginText')}</p>
          <p>{t('enterPasswordBelow')}</p>
        </Grid>
        <form onSubmit={(event) => onSubmit(event)}>
          {errorTransKey &&
            <Grid item xs={12} align="left">
              <p style={{color: 'red'}}>
                {t(errorTransKey)}
              </p>
            </Grid>
          }
          <Grid item xs={12}>
            <FormInput
              style={{marginTop: 10, background: '#FFFFFF'}}
              fullWidth={true}
              placeholder="Nytt lösenord"
              variant="outlined"
              onChange={(event) => setPassword(event.target.value)}
              sm={12}
              value={password}
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              style={{marginTop: 10, background: '#FFFFFF'}}
              fullWidth={true}
              placeholder="Upprepa Lösenord"
              variant="outlined"
              onChange={(event) => setPasswordAgain(event.target.value)}
              sm={12}
              value={passwordAgain}
              type="password"
            />
          </Grid>
          <Button
            type="submit"
            style={{
              color: '#FFFFFF',
              fontSize: 15,
              background: '#66bb6a',
              margin: '10px 0 5px 0',
              padding: '10px 20px',
              float: 'left',
            }}
          >
            {t('save')}
          </Button>
        </form>
      </Grid>
    </Grid>
  )
}
