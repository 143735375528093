import React from 'react';
import { Grid, Button } from '@material-ui/core';
import ReportCard from '../reports/report-card';
import DashboardTable from './dashboard-table';
import { postPayment } from '../services/payments';
import { Link } from "react-router-dom";
import i18n from '../i18n';
import { formatMoney } from '../utils/money-utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function DashboardLargeCards(props) {
  const t = i18n.getFixedT();
  const {
    actions,
    data,
    balance,
    headingsData,
    subscriptions,
    type,
    paySupplierInvoice,
    payMultipleSupplierInvoices,
  } = props;

  const currentBalance = (balance.current_year[balance.current_year.length - 1] || {}).amount

  const payInvoice = (row) => {
    return postPayment(row.total, row.given_number);
  }

  const totalAmount = () => {
    if (data.supplier_invoices.length === 0) {
      return null;
    }

    const total = data.supplier_invoices.reduce((result, invoice) => {
      return result + parseInt(invoice.total || '0')
    }, 0);

    return `${total} ${data.supplier_invoices[0].currency}`;
  }

  const invoiceSubheader = <p style={{fontSize: '14px', float: 'right', margin: 0, color: '#FFFFFF'}}>
    {`${t('arrears')}: ${formatMoney(headingsData.claims.amount, headingsData.claims.currency)}`}
  </p>;

  const supplierInvoiceSubheader = <p style={{fontSize: '14px', float: 'right', margin: 0, color: '#FFFFFF'}}>
    {`${t('liabilities')}: ${formatMoney(headingsData.debts.amount, headingsData.debts.currency)}`}
  </p>;

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <ReportCard
          large={true}
          headerColor='linear-gradient(60deg, rgb(102, 187, 106), rgb(63, 158, 67))'
          title='Senaste fakturor'
          link='/invoices'
          subHeader={invoiceSubheader}
          titleWidth='50%'
        >
          <DashboardTable
            data={data.invoices}
            subscriptions={subscriptions}
            type="customer"
          />
          {subscriptions.includes('invoices') &&
            <Grid item xs={12}>
              <Link
                to="/invoices"
                style={{float: 'right', padding: 10, color: '#000000'}}
              >
                {t('showAll')}
              </Link>
            </Grid>
          }
        </ReportCard>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <ReportCard
          large={true}
          headerColor='linear-gradient(60deg, rgb(239, 83, 80), rgb(229, 57, 53))'
          title='Senaste leverantörsfakturor'
          link='/supplier_invoices'
          subHeader={supplierInvoiceSubheader}
          titleWidth='50%'
        >
          <DashboardTable
            data={data.supplier_invoices}
            paySupplierInvoice={paySupplierInvoice}
            subscriptions={subscriptions}
            type="supplier"
          />
          {subscriptions.includes('supplier_invoices') &&
            <Grid item xs={12}>
              <Link
                to="/supplier_invoices"
                style={{float: 'right', padding: 10, color: '#000000', marginTop: 5}}
              >
                {t('showAll')}
              </Link>
            </Grid>
          }
        </ReportCard>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <ReportCard
          large={true}
          headerColor='linear-gradient(60deg, #ffa726, #ffa41e)'
          title={t('latestTransactions')}
          link='/transactions'
          subHeader=<p style={{fontSize: '14px', float: 'right', margin: 0, color: '#FFFFFF'}}>
            Kassa: {formatMoney(currentBalance, 'SEK', true)}
          </p>
          titleWidth='50%'
        >
          <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
            <Table aria-label="dashboard table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }} align="left">Beskrivning</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="left">{t('date')}</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">{t('amount')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.transactions.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{row.description}</TableCell>
                    <TableCell align="left">{row.date}</TableCell>
                    <TableCell align="right">{formatMoney(row.amount, row.currency, true)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item xs={12}>
              <Link
                to="/transactions"
                style={{float: 'right', padding: 10, color: '#000000', marginTop: 5}}
              >
                {t('showAll')}
              </Link>
            </Grid>
        </ReportCard>
      </Grid>
    </React.Fragment>
  )
}
