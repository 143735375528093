import request from './request.js';

export function login(email, password) {
  return request('tokens', {
    method: 'POST',
    body: {
      email: email,
      password: password
    }
  });
}

export function oauth(authorizationCode, companyId) {
  return request(`tokens/oauth2?company_id=${companyId}`, {
    method: 'POST',
    body: {
      authorization_code: authorizationCode
    }
  });
}

export function refreshOauth(companyId) {
  return request(`tokens/refresh_oauth2?company_id=${companyId}`, {
    method: 'POST',
    body: {}
  });
}

export function initiateOauth() {
  return request(`tokens/initiate_oauth2`, {
    method: 'GET',
  });
}

export function logout(token) {
  return request(`tokens/${token}`, {
    method: 'DELETE'
  });
}

// TODO: Can we decide in backend which bank?
export function bankAuth(employeeId, seb_auth_code) {
  return request('tokens/seb_callback', {
    method: 'POST',
    body: {
      employee_id: employeeId,
      seb_auth_code: seb_auth_code
    }
  });
}
