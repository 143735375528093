import React from 'react';
import { connect } from 'react-redux'
import {
  fetchInvoiceDataIfNeeded,
  asyncFetchInvoiceData,
  asyncCreateInvoicePayment,
  asyncBookkeepInvoice,
  asyncCreateInvoice,
  asyncUpdateInvoice,
  asyncCancelInvoice,
  asyncEmailInvoice,
  asyncCreditInvoice,
} from '../actions/invoices';
import {
  createInvoicePayment,
} from '../services/invoices';
import {
  asyncCreateTaxReduction,
  asyncUpdateTaxReduction,
  asyncGetTaxReductionByInvoice,
} from '../actions/tax-reductions';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Loader from '../common/loader';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import InvoiceList from './invoice-list';
import InvoiceForm from './invoice-form';
import i18n from '../i18n';

class Invoices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceFormOpen: false,
      invoices: [],
      pagination: {
        page: 1,
        totalPages: 1
      },
      taxReductionInvoice: null,
      taxReduction: {}
    }

    this.toggleInvoiceFormOpen = this.toggleInvoiceFormOpen.bind(this);
    this.submitInvoicePayment = this.submitInvoicePayment.bind(this);
    this.bookkeepInvoice = this.bookkeepInvoice.bind(this);
    this.createInvoice = this.createInvoice.bind(this);
    this.updateInvoice = this.updateInvoice.bind(this);
    this.cancelInvoice = this.cancelInvoice.bind(this);
    this.emailInvoice = this.emailInvoice.bind(this);
    this.creditInvoice = this.creditInvoice.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.createTaxReduction = this.createTaxReduction.bind(this);
    this.updateTaxReduction = this.updateTaxReduction.bind(this);
    this.fetchTaxReductionByInvoice = this.fetchTaxReductionByInvoice.bind(this);
  }

  componentDidMount() {
    const { dispatch, data, meta } = this.props;

    dispatch(fetchInvoiceDataIfNeeded(this.state.pagination.page));

    this.setState({
      invoices: data,
      pagination: {
        ...this.state.pagination,
        totalPages: meta.total_pages
      }
    });
  }

  componentDidUpdate(prevProps) {
    const {
      data,
      meta,
      didInvalidate,
      dispatch,
      lastUpdated,
      latestInvoice,
    } = this.props;

    if (prevProps.lastUpdated !== lastUpdated) {
      const newInvoices = data.sort((a, b) => {
        const docNumA = parseInt(a.document_number);
        const docNumB = parseInt(b.document_number);

        if (docNumA > docNumB) return -1;
        if (docNumB > docNumA) return 1;

        return 0;
      });

      this.setState({
        invoices: newInvoices,
        pagination: {
          ...this.state.pagination,
          totalPages: meta.total_pages
        }
      });
    }

    if (didInvalidate && prevProps.didInvalidate !== didInvalidate) {
      dispatch(fetchInvoiceDataIfNeeded(this.state.pagination.page));

      this.setState({
        invoices: data,
        pagination: {
          ...this.state.pagination,
          totalPages: meta.total_pages
        }
      });
    }
    if ((latestInvoice && !prevProps.latestInvoice && latestInvoice.tax_reduction) ||
        latestInvoice && prevProps.latestInvoice &&
        latestInvoice.document_number !== prevProps.latestInvoice.document_number &&
        latestInvoice.tax_reduction)
    {
      this.setState({ taxReductionInvoice: latestInvoice });
    }

    if (this.props.taxReduction && this.props.taxReduction !== prevProps.taxReduction) {
      this.setState({ taxReduction: this.props.taxReduction });
    }
  }

  toggleInvoiceFormOpen() {
    this.setState({
      toggleInvoiceFormOpen: !this.state.toggleInvoiceFormOpen
    });
  }

  submitInvoicePayment(data) {
    this.props.dispatch(asyncCreateInvoicePayment(data));
  }

  bookkeepInvoice(invoiceNumber) {
    this.props.dispatch(asyncBookkeepInvoice(invoiceNumber));
  }

  createInvoice(invoice, hasTaxReduction) {
    this.props.dispatch(asyncCreateInvoice(invoice));

    if (!hasTaxReduction) {
      setTimeout(() => {
        this.setState({
          toggleInvoiceFormOpen: !this.state.toggleInvoiceFormOpen
        });
      }, 500);
    }
  }

  createTaxReduction(taxReduction) {
    this.props.dispatch(asyncCreateTaxReduction(taxReduction));

    setTimeout(() => {
      this.setState({
        toggleInvoiceFormOpen: !this.state.toggleInvoiceFormOpen
      });
    }, 500);
  }

  updateTaxReduction(id, taxReduction) {
    this.props.dispatch(asyncUpdateTaxReduction(id, taxReduction));

    setTimeout(() => {
      this.setState({
        toggleInvoiceFormOpen: !this.state.toggleInvoiceFormOpen
      });
    }, 500);
  }

  fetchTaxReductionByInvoice(invoiceId) {
    this.props.dispatch(asyncGetTaxReductionByInvoice(invoiceId));
  }

  updateInvoice(invoice) {
    this.props.dispatch(asyncUpdateInvoice(invoice));
  }

  cancelInvoice(invoiceNumber) {
    this.props.dispatch(asyncCancelInvoice(invoiceNumber));
  }

  emailInvoice(invoiceNumber) {
    this.props.dispatch(asyncEmailInvoice(invoiceNumber));
  }

  creditInvoice(invoiceNumber) {
    this.props.dispatch(asyncCreditInvoice(invoiceNumber));
  }

  handlePageChange(page) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        page: page
      }
    }, () => {
      this.props.dispatch(
        fetchInvoiceDataIfNeeded(this.state.pagination.page)
      )
    })
  };

  render() {
    const t = i18n.getFixedT();
    const {
      toggleInvoiceFormOpen,
      invoices,
      pagination,
      taxReductionInvoice,
    } = this.state;
    const { isFetching, lastUpdated } = this.props;

    return (
      <Grid container justify="center" spacing={1}>
        <Grid item xs={11}>
          <Button
            style={{
              color: '#FFFFFF',
              fontSize: 14,
              background: '#66bb6a',
              marginBottom: 5,
              float: 'left',
            }}
            onClick={this.toggleInvoiceFormOpen}
          >
            {toggleInvoiceFormOpen ? t('cancel') : t('createInvoice')}
          </Button>
          <Pagination
            style={{marginTop: '2px'}}
            variant="outlined"
            shape="rounded"
            onChange={(_, page) => this.handlePageChange(page)}
            page={pagination.page}
            count={pagination.totalPages}
          />
        </Grid>
        <Grid item xs={11} style={{padding: 0}}>
          { toggleInvoiceFormOpen &&
            <InvoiceForm
              taxReduction={this.props.taxReduction}
              onSubmit={this.createInvoice}
              taxReductionInvoice={taxReductionInvoice}
              onCreateTaxReduction={this.createTaxReduction}
              onUpdateTaxReduction={this.updateTaxReduction}
              fetchTaxReductionByInvoice={this.fetchTaxReductionByInvoice}
            />
          }
        </Grid>
        <Grid item xs={11}>
          <InvoiceList
            submitInvoicePayment={this.submitInvoicePayment}
            bookkeepInvoice={this.bookkeepInvoice}
            updateInvoice={this.updateInvoice}
            cancelInvoice={this.cancelInvoice}
            emailInvoice={this.emailInvoice}
            creditInvoice={this.creditInvoice}
            fetchTaxReductionByInvoice={this.fetchTaxReductionByInvoice}
            taxReduction={this.props.taxReduction}
            onUpdateTaxReduction={this.updateTaxReduction}
            onCreateTaxReduction={this.createTaxReduction}
            data={invoices}
            isFetching={isFetching}
          />
        </Grid>
        <Grid item xs={11} style={{margin: '10px 0 10px 0'}}>
          <Pagination
            variant="outlined"
            shape="rounded"
            onChange={(_, page) => this.handlePageChange(page)}
            page={pagination.page}
            count={pagination.totalPages}
          />
        </Grid>
      </Grid>
    )
  }
}

Invoices.propTypes = {
  data: PropTypes.array.isRequired,
  taxReductionData: PropTypes.object,
  meta: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  latestInvoice: PropTypes.object,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { invoiceData, taxReductionData } = state;
  const { lastUpdated, didInvalidate, latestInvoice } = invoiceData;
  const data = invoiceData.data || [];
  const meta = invoiceData.meta || {};
  const taxReduction = taxReductionData.data || {};
  const isFetching = invoiceData.isFetching === undefined ? true : invoiceData.isFetching;

  return {
    isFetching,
    lastUpdated,
    latestInvoice,
    data,
    taxReduction,
    meta,
    didInvalidate,
  }
}

export default connect(mapStateToProps)(Invoices)
