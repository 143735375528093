import { initialState } from './initial-state';

import {
  REQUEST_ORDER_DATA,
  RECEIVE_ORDER_DATA,
  INVALIDATE_ORDER_DATA,
  RECEIVE_CREATE_ORDER,
  RECEIVE_CREATE_ORDER_INVOICE,
  RECEIVE_UPDATE_ORDER,
} from '../actions/order-action-types';

export default function orderReducer(state = initialState.order, action) {
  switch (action.type) {
    case INVALIDATE_ORDER_DATA:
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case REQUEST_ORDER_DATA:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVE_ORDER_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        data: action.orderData,
        meta: action.meta,
        lastUpdated: action.receivedAt
      });
    case RECEIVE_CREATE_ORDER:
      return Object.assign({}, state, {
        data: [
          ...state.data,
          action.order,
        ],
        lastUpdated: action.receivedAt
      });
    case RECEIVE_CREATE_ORDER_INVOICE:
      return Object.assign({}, state, {
        data: state.data.filter(order => order.document_number !== action.order.document_number),
        lastUpdated: action.receivedAt
      });
    case RECEIVE_UPDATE_ORDER:
      return Object.assign({}, state, {
        data: state.data.map((order) => {
          if (order.document_number === action.order.document_number) {
            return action.order;
          }

          return order;
        }),
        lastUpdated: action.receivedAt
      });
    default:
      return state
  }
}


