import React from 'react';
import {
  VictoryChart,
  VictoryPie,
  VictoryAxis,
  VictoryLabel,
} from 'victory';
import i18n from '../i18n';

export default class PieChart extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      pieData: [
        { x: 'income', y: 50 },
        { x: 'expenditure', y: 10 },
        { x: 'salaries', y: 30 },
        { x: 'excess', y: 10 },
      ]
    }
  }

  componentDidMount() {
    const { data } = this.props;

    console.log("data: ", data);
    // TODO: Temp hack to enable transition on pie chart.
    // See: https://github.com/FormidableLabs/victory-native/issues/144
    this.setState({
      pieData: [
        { x: 'income', y: data.income },
        { x: 'expenditure', y: data.expenditure },
        { x: 'salaries', y: data.salaries },
        { x: 'excess', y: data.excess },
      ]
    });
  }

  render() {
    const t = i18n.getFixedT();
    const { pieData } = this.state;
    const {
      axisColor,
      labelColor,
      dataColor,
      background,
    } = this.props;

    return (
      <VictoryChart
        style={{
          parent: {
            background: background || 'linear-gradient(60deg, #ef5350, #e53935)',
            borderRadius: '5px'
          },
        }}
        animate={{duration: 1500}}
      >
        <VictoryAxis
          style={{
            axis: {stroke: 'none'},
            ticks: {stroke: 'none' },
            grid: { stroke: 'none' }
          }}
          tickLabelComponent={<VictoryLabel style={{ fill: 'none' }}/>}
        />
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: 'none' },
            ticks: { stroke: 'none' },
            grid: { stroke: 'none' }
          }}
          tickLabelComponent={<VictoryLabel style={{ fill: 'none' }}/>}
        />

        <VictoryPie
          width={10}
          animate={{
            duration: 1500,
          }}
          colorScale={['#66bb6a', '#ef5350', '#ffa726', 'gold', 'navy' ]}
          style={{
            data: {
              fillOpacity: 0.9, stroke: dataColor || '#FFFFFF', strokeWidth: 3
            },
            labels: {
              fill: labelColor || '#FFFFFF', fontSize: 10
            },
            axis: {stroke: axisColor || '#FFFFFF', opacity: '0.9'},
            ticks: {stroke: labelColor || '#FFFFFF' },
            grid: { stroke: 'none' }
          }}
          data={pieData.map(item => { return { x: t(item.x), y: item.y } })}
        />
      </VictoryChart>
    )
  }
}
