import { initialState } from './initial-state';
import {
  REQUEST_REPORT_DATA,
  RECEIVE_REPORT_DATA,
  INVALIDATE_REPORT_DATA,
} from '../actions/report-action-types';

export default function reportReducer(state = initialState.report, action) {
  switch (action.type) {
    case INVALIDATE_REPORT_DATA:
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case REQUEST_REPORT_DATA:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVE_REPORT_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        data: action.reportData,
        lastUpdated: action.receivedAt
      });
    default:
      return state
  }
}
