import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Loader from '../common/loader';
import i18n from '../i18n';
import { formatMoney } from '../utils/money-utils';

import './dashboard-table.scss';

export default function DashboardTable(props) {
  const [loading, setLoading] = useState(false);
  const t = i18n.getFixedT();
  const {
    actions,
    data,
    payInvoice,
    subscriptions,
    type,
  } = props;

  const pay = (row) => {
    setLoading(true);

    // TODO: Catch => present error?
    // TODO: Do we need to keep in redux state?
    // TODO: How do we bookeep payment??
    payInvoice(row).finally(() => setLoading(false));
  }

  const renderDueDate = (row) => {
    if (row.cancelled) {
      return <p style={{margin: 0, padding: 0, color: '#aaaaaa'}}>
        {row.due_date}
      </p>
    }

    if (Date.parse(row.due_date) > Date.now() && !row.paid) {
      return <p style={{margin: 0, padding: 0, color: '#e7ea0f'}}>
        {row.due_date}
      </p>
    }


    if (row.paid || Date.parse(row.due_date) > Date.now()) {
      return <p style={{margin: 0, padding: 0, color: '#66bb6a'}}>
        {row.due_date}
      </p>
    }

    return <p style={{margin: 0, padding: 0, color: '#e53935'}}>
      {row.due_date}
    </p>
  }

  return (
    <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
      <Table aria-label="dashboard table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }} align="left">{t(type)}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="left">{t('invoiceDate')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="left">{t('dueDate')}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">{t('amount')}</TableCell>
            {data[0] && data[0].supplier_name &&
              <TableCell align="right"></TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.given_number || row.ocr}>
              <TableCell
                component="th"
                scope="row"
                style={{color: row.cancelled ? '#aaaaaa' : '#000000'}}
              >
                {type === 'customer' ? row.customer_name : row.supplier_name}
              </TableCell>
              <TableCell align="left" style={{color: row.cancelled ? '#aaaaaa' : '#000000'}}>{row.invoice_date}</TableCell>
              <TableCell align="left" style={{color: row.cancelled ? '#aaaaaa' : '#000000'}}>{renderDueDate(row)}</TableCell>
              <TableCell align="right" style={{color: row.cancelled ? '#aaaaaa' : '#000000'}}>{formatMoney(row.total, row.currency, true)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
