import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Icon
} from '@material-ui/core';
import logo from '../logo.svg';
import i18n from '../i18n';
import auth from '../authentication/authentication';
import { withRouter } from "react-router-dom";

import './login-form.scss';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      rememberEmail: true,
    }

    this.emailInput = React.createRef();
    this.passwordInput = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (auth.getRememberedEmail()) {
      this.handleChange('email', auth.getRememberedEmail())
      this.passwordInput.current.focus();
    } else {
      this.emailInput.current.focus();
    }
  }

  handleChange(field, value) {
    this.setState({ [field]: value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { history, location } = this.props;
    const { email, password, rememberEmail } = this.state;
    const { from } = location.state || { from: { pathname: "/" } };

    auth.login(email, password, rememberEmail)
      .then(() => {
        if (auth.getLegacyAuth()) {
          history.replace({ pathname: "/" })
        } else {
          history.replace({ pathname: "/oauth" })
        }
      })
      .catch(err => console.log("handle error: ", err));
  }

  render() {
    const t = i18n.getFixedT();

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="row"
        className="login-container"
        style={{marginTop: -90}}
      >
        <Grid item xs={12} sm={8} md={6} lg={3} style={{marginBottom: 20}}>
          <h1 style={{fontSize: 64,  color: '#3568fe'}}>EkonomiMolnet</h1>
          <div className="login-form-container">
            <h1>{t('login')}</h1>
            <form onSubmit={this.handleSubmit}>
              <TextField
                value={this.state.email}
                inputRef={this.emailInput}
                variant="filled"
                margin='dense'
                fullWidth={true}
                helperText={t('enterEmail')}
                label={t('email')}
                onChange={(event) => this.handleChange('email', event.target.value)}
              />
              <TextField
                inputRef={this.passwordInput}
                variant="filled"
                margin='dense'
                fullWidth={true}
                type='password'
                helperText={t('enterPassword')}
                label={t('password')}
                onChange={(event) => this.handleChange('password', event.target.value)}
              />
              <FormControlLabel
                style={{color: 'white'}}
                control={
                  <Checkbox
                    style={{color: 'white'}}
                    checked={this.state.rememberEmail}
                    onChange={(event) => this.handleChange('rememberEmail', event.target.checked)}
                    name="rememberEmail"
                    color="primary"
                  />
                }
                label={t('rememberEmail')}
              />
              <Button style={{color: '#FFFFFF'}} type='submit' disabled={!this.state.email || !this.state.password}>
                {t('login')}
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}

LoginForm.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(LoginForm);
