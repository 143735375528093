import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from '../i18n';
import { formatMoney } from '../utils/money-utils';
import Modal from '@material-ui/core/Modal';
import FormInput from '../common/form-input';
import Icon from '@material-ui/core/Icon';
import {
  Grid,
  Button,
} from '@material-ui/core';
import { fetchCustomer } from '../services/customers';
import auth from '../authentication/authentication';

class TaxReductionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      socialSecurityNumber: null,
      customerName: null,
      askedAmount: null,
      propertyDesignation: null, // TODO: Kan vi skippa denna?
      errors: []
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    if (this.props.invoice) {
      fetchCustomer(this.props.invoice.customer_number)
        .then(response => response.json())
        .then(customer => {
          this.setState({
            customerName: customer.name,
            socialSecurityNumber: customer.corporate_identity_number
          })
        })

      this.setState({ customerName: this.props.invoice.customer_name });
    }

    if (this.props.invoice && this.props.updateTaxReduction) {
      this.props.fetchTaxReductionByInvoice(this.props.invoice.document_number);
    }

    if (auth.getCompanyTaxReductions().includes('ROT') && this.props.calculatedROTAmount) {
      this.setState({ askedAmount: this.props.calculatedROTAmount })
    }

    if (auth.getCompanyTaxReductions().includes('RUT') && this.props.calculatedRUTAmount) {
      this.setState({ askedAmount: this.props.calculatedRUTAmount })
    }
  }

  componentDidUpdate(prevProps) {
    const { taxReduction } = this.props;
    if (Object.keys(taxReduction).length > 0 && taxReduction !== prevProps.taxReduction) {
      this.setState({
        askedAmount: taxReduction.asked_amount,
        customerName: taxReduction.customer_name,
        socialSecurityNumber: taxReduction.social_security_number,
        propertyDesignation: taxReduction.property_designation,
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const { invoice, taxReduction } = this.props;
    const {
      socialSecurityNumber,
      customerName,
      askedAmount,
      propertyDesignation
    } = this.state;

    const errors = [];

    ['socialSecurityNumber', 'customerName', 'askedAmount'].forEach((field) => {
      if (!this.state[field]) {
        errors.push(field);
      }
    });

    if (auth.getCompanyTaxReductions().includes('ROT')) {
      if (!this.state.propertyDesignation) {
        errors.push('propertyDesignation')
      }
    }

    if (errors.length > 0) {
      this.setState({ errors: errors });
      return;
    }

    this.setState({ errors: [] });

    const body = {
      social_security_number: socialSecurityNumber,
      customer_name: customerName,
      asked_amount: askedAmount,
      property_designation: propertyDesignation,
      reference_document_type: 'INVOICE',
      reference_number: invoice.document_number
    }

    if ((taxReduction || {}).id) {
      this.props.onUpdateTaxReduction && this.props.onUpdateTaxReduction(taxReduction.id, body);
    } else {
      this.props.onCreateTaxReduction && this.props.onCreateTaxReduction(body);
    }
  }

  handleInputChange(field, value) {
    this.setState({ [field]: value });
  }

  render() {
    const t = i18n.getFixedT();
    const { invoice, onCloseModal } = this.props;
    const { errors } = this.state;

    return (
      <Modal
        open={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="custom-modal modal-form update-article-modal">
          <div className="modal-header">
            <span className="modal-title">{t('taxReduction')}</span>
            <Icon
              onClick={() => onCloseModal()}
              style={{
                margin: '10px 0 5px 4px',
                float: 'right',
                cursor: 'pointer'
              }}
            >
              clear
            </Icon>
          </div>
          <div className="modal-body">
            <form onSubmit={(event) => this.handleSubmit(event)}>
              <Grid container>
                <Grid item xs={12}>
                  {
                    ['socialSecurityNumber', 'customerName', 'askedAmount'].map(field => (
                      <FormInput
                        key={field}
                        fullWidth={true}
                        label={t(field)}
                        variant="outlined"
                        placeholder={field == 'socialSecurityNumber' ? 'YYMMDD-XXXX' : ''}
                        onChange={(event) => this.handleInputChange(field, event.target.value)}
                        sm={12}
                        md={12}
                        lg={12}
                        value={this.state[field]}
                        error={errors.includes(field)}
                      />
                  ))}

                  {
                    auth.getCompanyTaxReductions().includes('ROT') &&
                      <FormInput
                        key={'propertyDesignation'}
                        fullWidth={true}
                        label={t('propertyDesignation')}
                        variant="outlined"
                        onChange={(event) => this.handleInputChange('propertyDesignation', event.target.value)}
                        sm={12}
                        md={12}
                        lg={12}
                        value={this.state.propertyDesignation}
                        error={errors.includes('propertyDesignation')}
                      />
                  }

                  <p>
                    Total: { formatMoney(this.state.askedAmount || 0, 'SEK') }
                  </p>

                  <Button
                    type="submit"
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      background: '#66bb6a',
                      margin: '10px 0 5px 4px',
                      float: 'left',
                    }}
                  >
                    {t('save')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
    )
  }
}

TaxReductionForm.propTypes = {
  action: PropTypes.string,
  dispatch: PropTypes.func.isRequired
}

export default connect(null)(TaxReductionForm);
