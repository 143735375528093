import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import {
  Grid,
  Paper
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import i18n from '../i18n';
import Button from '@material-ui/core/Button';
import FileUploader from '../common/file-uploader';
import {
  asyncFetchDocumentData,
  fetchDocumentDataIfNeeded,
} from '../actions/documents';
import {
  uploadDocument,
  fetchDocumentUrl
} from '../services/documents';
import auth from '../authentication/authentication';

import './documents.scss';

class DocumentPresenter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      uploading: false,
      uploadModalOpen: false,
    }

    this.handleOpenDocument = this.handleOpenDocument.bind(this);
    this.handleUploadDocument = this.handleUploadDocument.bind(this);
  }

  handleOpenDocument(document) {
    fetchDocumentUrl(document.key, document.path)
      .then((response) => response.json())
      .then((result) => {
        window.open(result.url);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  handleUploadDocument(documents, fileName) {
    this.setState({
      uploading: true
    });

    const formData = new FormData();

    documents.forEach((document) => {
      formData.append('files[]', document.file);
    });

    formData.append('date', '2021-01')
    formData.append('attachment_type', this.props.documentCategory)
    formData.append('file_name', fileName)

    uploadDocument(formData)
      .then((response) => response.json())
      .then((result) => {
        if (result.status && result.status !== 200) {
          alert("Något fick fel, vänligen försök igen eller kontakta support@ekonomimolnet.se");
        } else {
          this.setState({
            uploadModalOpen: false,
            uploading: false
          })
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert("Något fick fel, vänligen försök igen eller kontakta support@ekonomimolnet.se");
      })
      .finally(() => this.setState({ uploading: false }));
  }

  componentDidMount() {
    const { dispatch, data, documentCategory } = this.props;

    dispatch(asyncFetchDocumentData(documentCategory));

    this.setState({
      documents: data,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      data,
      didInvalidate,
      dispatch,
      lastUpdated,
      documentCategory,
    } = this.props;

    if (prevProps.lastUpdated !== lastUpdated) {
      this.setState({
        documents: data
      });
    }

    if (documentCategory && documentCategory !== prevProps.documentCategory) {
      dispatch(fetchDocumentDataIfNeeded(documentCategory));

      this.setState({
        documents: data
      });
    }

    if (didInvalidate && prevProps.didInvalidate !== didInvalidate) {
      dispatch(fetchDocumentDataIfNeeded(documentCategory));

      this.setState({
        documents: data
      });
    }
  }

  render() {
    const t = i18n.getFixedT();
    const { documentCategory } = this.props;
    const { documents, uploading, uploadModalOpen } = this.state;

    return (
      <Grid container justify="center" style={{marginTop: '50px'}}>
        <Grid item xs={8}>
          {
            (process.env.REACT_APP_SYS_ADMIN_EMAILS || '').split(',').includes(auth.getEmail()) &&
              <Grid container justify="flex-start">
                <Grid item xs={12} sm={6} lg={3} lg={3}>
                  <Button
                    onClick={() => this.setState({ uploadModalOpen: !uploadModalOpen })}
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      background: '#666',
                      marginBottom: '10px',
                      width: '235px',
                      height: '50px',
                      float: 'left'
                    }}
                  >
                    {`${t('upload')} i ${t(documentCategory)}`}
                  </Button>
                </Grid>
                <FileUploader
                  onClose={() => this.setState({ uploadModalOpen: false })}
                  onUpload={this.handleUploadDocument}
                  open={uploadModalOpen}
                  multiple={false}
                  loading={uploading}
                  includeFileNameInput={true}
                />
              </Grid>
          }
          <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
            <Table aria-label="dashboard table">
              <TableHead style={{background: '#2333cf'}}>
                <TableRow>
                  <TableCell style={{fontWeight: 'bold', color: '#FFFFFF' }} align="left">Filnnamn</TableCell>
                  <TableCell style={{fontWeight: 'bold', color: '#FFFFFF' }} align="left">Öppna fil</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  documents.map(document => (
                    <React.Fragment key={document.key}>
                      <TableRow>
                        <TableCell style={{color: '#000000'}} align="left">
                          {document.key}
                        </TableCell>
                        <TableCell style={{color: '#000000'}} align="left">
                          <Icon
                            style={{marginRight: 25, cursor: 'pointer'}}
                            onClick={() => this.handleOpenDocument(document)}
                          >
                            description
                          </Icon>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    )
  }
}

DocumentPresenter.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { documentData } = state;
  const { lastUpdated, didInvalidate } = documentData;
  const data = documentData.data || [];
  const isFetching = documentData.isFetching === undefined ? true : documentData.isFetching;

  return {
    isFetching,
    lastUpdated,
    data,
    didInvalidate,
  }
}

export default connect(mapStateToProps)(DocumentPresenter)
