import React from 'react';
import auth from '../authentication/authentication';
import { Button } from '@material-ui/core';
import i18n from '../i18n';
import { useHistory } from "react-router-dom";

export default function LogoutButton() {
  const t = i18n.getFixedT();
  const history = useHistory();

  const handleClick = () => {
    auth.logout().then(() => history.push('/login'));
  }

  if (!auth.isAuthenticated()) {
    return null;
  }

  return (
    <Button
      color="inherit"
      onClick={handleClick}
    >
      {t('logout')}
    </Button>
  )
}
