import React from 'react';
import { useEffect, useState } from 'react';
import i18n from '../i18n';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Grid } from '@material-ui/core';
import Loader from '../common/loader';
import { fetchOrder, updateOrder } from '../services/orders';
import { fetchCompany } from '../services/fortnox-companies';
import { validateEmail } from '../utils/validation-utils';
import FormInput from '../common/form-input';

import './order-email-form.scss';

export default function OrderEmailForm(props) {
  const t = i18n.getFixedT();
  const {
    onEmail,
    onPrint,
    open,
    onClose,
    orderNumber,
  } = props;

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [cc, setCC] = useState(null);
  const [subject, setSubject] = useState(`Order ${orderNumber} bifogas`);
  const [body, setBody] = useState(null);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  if (!order) {
    fetchOrder(orderNumber)
      .then(response => response.json())
      .then((orderResp => {
        setOrder(orderResp);

        fetchCompany()
          .then(response => response.json())
          .then((company => {
            if (company.email) {
              setFrom(company.email);
            }
          }));

        if (orderResp.email_information) {
          const emailInfo = orderResp.email_information;

          setFrom(emailInfo.address_from);
          setTo(emailInfo.address_to);
          setCC(emailInfo.address_cc);
          setBody(emailInfo.body);
        }
      }));
  }

  const sendEmail = () => {
    const errors = [];

    const fields = {
      from: [from],
      to: [to],
      cc: [cc, true],
    }

    Object.entries(fields).forEach(([field, args]) => {
      if (!validateEmail(...args)) {
        errors.push(field);
      }
    });

    if (errors.length > 0) {
      setValidationErrors(errors);
      return;
    } else {
      setValidationErrors([]);
    }

    setLoading(true);

    const emailData = {
      email_information: {
        address_from: from,
        address_to: to,
        address_cc: cc,
        subject: subject,
        body: body,
      }
    }

    updateOrder({...order, ...emailData})
      .then(response => response.json())
      .then((resp) => {
        onEmail(resp.document_number);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="custom-modal modal-form update-article-modal">
        <div className="modal-header">
          <span className="modal-title">{t('sendOrder')}</span>
          <Icon
            onClick={() => onClose(null, false)}
            style={{
              margin: '10px 0 5px 4px',
              float: 'right',
              cursor: 'pointer'
            }}
          >
            clear
          </Icon>
        </div>
        <div className={loading ? "modal-body loading" : "modal-body send-order-body"}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <p style={{
                margin: '15px 0 15px 5px',
                color: validationErrors.length > 0 ? '#e53935' : '#000000',
              }}>
                {validationErrors.length > 0
                  ? t('validations.invalidEmailFormat')
                  : t('sendOrderInfo')
                }
              </p>
            </Grid>
            <FormInput
              fullWidth={true}
              label={t('replyAddress')}
              variant="outlined"
              onChange={(event) => setFrom(event.target.value)}
              value={from}
              style={{marginTop: 5}}
              error={validationErrors.includes('from')}
            />
            <FormInput
              fullWidth={true}
              label={t('receiver')}
              variant="outlined"
              onChange={(event) => setTo(event.target.value)}
              value={to}
              style={{marginTop: 5}}
              error={validationErrors.includes('to')}
            />
            <FormInput
              fullWidth={true}
              label={t('copy')}
              variant="outlined"
              onChange={(event) => setCC(event.target.value)}
              value={cc}
              style={{marginTop: 5}}
              error={validationErrors.includes('cc')}
            />
            <FormInput
              fullWidth={true}
              label={t('subject')}
              variant="outlined"
              onChange={(event) => setSubject(event.target.value)}
              value={subject}
              style={{marginTop: 5}}
            />
            <FormInput
              fullWidth={true}
              label={t('content')}
              variant="outlined"
              onChange={(event) => setBody(event.target.value)}
              value={body}
              rows={5}
              multiline
              style={{marginTop: 5}}
            />
            <Grid item xs={12} style={{margin: '5px 0 15px 0'}}>
              <Button
                style={{
                  color: '#FFFFFF',
                  background: '#66bb6a',
                }}
                onClick={sendEmail}
              >
                {t('sendWithEmail')}
              </Button>
              <Button
                style={{
                  color: '#FFFFFF',
                  background: '#66bb6a',
                  marginLeft: 5,
                }}
                onClick={onPrint}
              >
                {t('openForPrint')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Modal>
  )
}
