import request from './request';
import { buildUrl } from '../utils/link-builder-utils';

export function fetchReportData(accountGroups, cumulative, financialYear) {
  const url = buildUrl('reports', {
    account_groups: accountGroups,
    cumulative: cumulative,
    financial_year: financialYear
  });

  return request(url, {
    method: 'GET'
  });
}
