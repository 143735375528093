import request from './request';
import upload from './file-upload';

export function uploadExcel(formData) {
  return upload('sie/generate_from_xlsx', formData);
}

export function uploadSkv(formData) {
  return upload('sie/generate_from_skv', formData);
}

export function fetchSieData() {
  return request(`sie`, {
    method: 'GET'
  });
}

export function fetchSieUrl(fileKey, filePath) {
  return request(`sie/${fileKey}?path=${filePath}`, {
    method: 'GET'
  });
}
