import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loader(props) {
  const {
    size,
    thickness,
    style,
  } = props;

  return (
    <CircularProgress
      size={size || 200}
      thickness={thickness || 1}
      style={style || {marginTop: 100}}
    />
  )
}
