import React from 'react';
import { connect } from 'react-redux'
import { fetchCustomerDataIfNeeded, searchCustomers } from '../actions/customers';
import {
  asyncCreateArticle,
  fetchArticleDataIfNeeded,
  asyncDeleteArticle,
  asyncUpdateArticle
} from '../actions/articles';
import { fetchTermsOfPayment } from '../services/terms-of-payment';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Loader from '../common/loader';
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async';
import InvoiceRow from './invoice-row';
import InvoiceInput from './invoice-input';
import CustomerForm from '../customers/customer-form.js';
import TaxReductionForm from '../tax-reductions/tax-reduction-form.js';
import i18n from '../i18n';
import {
  selectCustomerStyles,
  selectTermsOfPaymentsStyles,
} from './invoice-select-styles';
import {
  calculateNetPrice,
  calculateTotalPrice,
  calculateROT,
  calculateRUT,
} from './invoice-utils';
import { formatMoney } from '../utils/money-utils';
import { ACTIONS } from '../constants/actions';
import auth from '../authentication/authentication';
import { fetchArticle } from '../services/articles';
import { fetchUnits } from '../services/units';
import { fetchCustomerData, fetchCustomer } from '../services/customers';

import './invoice-form.scss';

const defaultUnits = [
  { label: 'Förpackning', value: 'förp' },
  { label: 'Timmar', value: 'h' },
  { label: 'Kilometer', value: 'km' },
  { label: 'Styck', value: 'st' },
  { label: 'Utlägg', value: 'utl' },
];

class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);

    const defaultDueDate = new Date();
    defaultDueDate.setDate(defaultDueDate.getDate() + 30);

    this.state = {
      createdArticle: null,
      createdArticleAt: null,
      customerNumber: '',
      invoiceDate: new Date(Date.now()),
      dueDate: defaultDueDate,
      termsOfPayment: null,
      termsOfPaymentOptions: [],
      rowCount: 1,
      invoiceRows: [],
      invoiceRowErrors: {},
      invoiceErrors: [],
      articleModalOpen: false,
      selectedArticle: null,
      updatedArticle: {},
      customerFormOpen: false,
      customerFormAction: null,
      selectedCustomer: null,
      invoice: {},
      taxReductionModalOpen: false,
      units: defaultUnits
    }

    this.handleAddArticle = this.handleAddArticle.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);
    this.handleArticleChange = this.handleArticleChange.bind(this);
    this.handleInvoiceInputChange = this.handleInvoiceInputChange.bind(this);
    this.handleSelectCustomer = this.handleSelectCustomer.bind(this);
    this.handleFilterCustomer = this.handleFilterCustomer.bind(this);
    this.handleUpdateCustomer = this.handleUpdateCustomer.bind(this);
    this.handleRowInputChange = this.handleRowInputChange.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleClearRow = this.handleClearRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpenTaxReductionForm = this.handleOpenTaxReductionForm.bind(this);
    this.toggleCustomerForm = this.toggleCustomerForm.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.closeCustomerModal = this.closeCustomerModal.bind(this);
    this.openArticleModal = this.openArticleModal.bind(this);
    this.createTaxReduction = this.createTaxReduction.bind(this);
    this.updateTaxReduction = this.updateTaxReduction.bind(this);
  }

  componentDidMount() {
    const { dispatch, invoice } = this.props;

    dispatch(fetchCustomerDataIfNeeded());
    dispatch(fetchArticleDataIfNeeded());

    fetchTermsOfPayment()
      .then(response => response.json())
      .then((termsOfPayments) => {
        const options = termsOfPayments.map((payment) => {
          return { label: payment.description, value: payment.code };
        });

        this.setState({ termsOfPaymentOptions: options });

        if (invoice) {
          const termsOfPayment = this.state.termsOfPaymentOptions.find((option) => {
            return option.value === invoice.terms_of_payment;
          });

          this.handleSelectCustomer(invoice.customer_number);

          this.setState({
            invoiceDate: invoice.invoice_date,
            dueDate: invoice.due_date,
            termsOfPayment: termsOfPayment ? termsOfPayment.value : 30,
            invoiceRows: invoice.invoice_rows,
            rowCount: invoice.invoice_rows.length,
            customerNumber: invoice.customer_number,
            invoice: {
              documentNumber: invoice.document_number,
              credit: invoice.credit,
              creditInvoiceReference: invoice.credit_invoice_reference,
              booked: invoice.booked,
              taxReductionPossible: invoice.tax_reduction_type !== 'none'
            }
          });
        }
      });

    fetchUnits()
      .then(response => response.json())
      .then((units) => {
        const options = units.map((unit) => {
          const description = `${unit.description.charAt(0).toUpperCase()}${unit.description.slice(1)}`;
          return { label: description, value: unit.code };
        });

        this.setState({ units: options });
      });
  }

  componentDidUpdate(prevProps) {
    // When a new customer is created we want to automatically select it in the customer select menu.
    if (this.state.customerFormOpen && prevProps.customers.length < this.props.customers.length) {
      const customer = this.props.customers.filter(c => !prevProps.customers.includes(c))[0];

      this.setState({
        customerNumber: customer.fortnox_customer_number,
        customerFormOpen: false
      });
    }

    // When a new article is created we want to automatically select it in the article select menu.
    if ((prevProps.articles || []).length < (this.props.articles || []).length) {
      const newArticle = this.props.articles.find(article => !prevProps.articles.includes(article));

      const createdRecently = new Date();
      createdRecently.setDate(createdRecently.getDate() - 1);

      if (newArticle && this.state.createdArticleAt > createdRecently) {
        this.setState({ createdArticle: newArticle, createdArticleAt: null });
      }
    }

    if (this.props.taxReductionInvoice && this.props.taxReductionInvoice !== prevProps.taxReductionInvoice) {
      this.setState({ taxReductionModalOpen: true, taxReductionInvoice: this.props.taxReductionInvoice });
    }
  }

  handleAddArticle(rowValues, action = ACTIONS.CREATE) {
    delete rowValues.quantity;

    if (action === ACTIONS.UPDATE) {
      this.props.dispatch(asyncUpdateArticle(rowValues, this.state.selectedArticle.article_number));

      const updatedInvoiceRows = [];

      // TODO: Implement creating housework article?

      this.state.invoiceRows.forEach((invoiceRow) => {
        if (invoiceRow.articleNumber === rowValues.article_number) {
          const updatedInvoiceRow = {
            ...invoiceRow,
            description: rowValues.description,
            unit: rowValues.unit,
            price: rowValues.price,
            vat: rowValues.vat,
            discount: rowValues.discount,
          };

          updatedInvoiceRows.push(updatedInvoiceRow);
        } else {
          updatedInvoiceRows.push(invoiceRow);
        }
      })

      return this.setState({
        invoiceRows: updatedInvoiceRows,
        articleModalOpen: false
      });
    }

    this.setState({ createdArticleAt: Date.now() });

    this.props.dispatch(asyncCreateArticle(rowValues));
  }

  handleDeleteArticle() {
    const {
      selectedArticle,
      invoiceRows
    } = this.state;

    this.props.dispatch(asyncDeleteArticle(selectedArticle.article_number));

    const updatedInvoiceRows = invoiceRows.filter((invoiceRow) => {
      return invoiceRow.articleNumber && invoiceRow.articleNumber !== selectedArticle.article_number;
    });

    this.setState({
      invoiceRows: updatedInvoiceRows,
      rowCount: updatedInvoiceRows.length || 1,
      articleModalOpen: false
    });
  }

  handleArticleChange(article, rowIndex) {
    const invoiceRows = this.state.invoiceRows;
    const invoiceRow = invoiceRows[rowIndex];
    const newInvoiceRow = invoiceRow ? invoiceRow : {};

    if (article.house_work) {
      fetchArticle(article.article_number)
        .then(response => response.json())
        .then((articleData) => {
          newInvoiceRow.articleNumber = articleData.article_number;
          newInvoiceRow.vat = articleData.vat || '25';
          newInvoiceRow.description = articleData.description;
          newInvoiceRow.unit = articleData.unit;
          newInvoiceRow.price = articleData.sales_price;
          newInvoiceRow.deliveredQuantity = 1;
          newInvoiceRow.houseWork = articleData.house_work;
          newInvoiceRow.houseWorkType = articleData.house_work_type;

          invoiceRows[rowIndex] = newInvoiceRow;

          this.setState({
            invoiceRows: invoiceRows,
            invoiceRowErrors: {
              rowIndex: []
            }
          });
        });
    } else {
      newInvoiceRow.articleNumber = article.article_number;
      newInvoiceRow.vat = article.vat || '25';
      newInvoiceRow.description = article.description;
      newInvoiceRow.unit = article.unit;
      newInvoiceRow.price = article.sales_price;
      newInvoiceRow.deliveredQuantity = 1;

      invoiceRows[rowIndex] = newInvoiceRow;

      this.setState({
        invoiceRows: invoiceRows,
        invoiceRowErrors: {
          rowIndex: []
        }
      });
    }
  }

  handleInvoiceInputChange(field, value) {
    const { invoiceErrors } = this.state;

    if (invoiceErrors.includes(field)) {
      const index = invoiceErrors.indexOf(field);
      invoiceErrors.splice(index, 1);
    }

    if (field === 'termsOfPayment') {
      let date = new Date();
      const days = parseInt(value);

      if (this.state.invoiceDate) {
        date = new Date(this.state.invoiceDate);
        date.setDate(this.state.invoiceDate.getDate() + days)
      } else {
        date.setDate((days || days === 0) ? (date.getDate() + days) : date.getDate());
      }

      this.setState({ dueDate: date });
    }

    if (field === 'invoiceDate' && this.state.termsOfPayment) {
      const date = new Date(value);
      const days = parseInt(this.state.termsOfPayment);

      date.setDate(value.getDate() + days)

      this.setState({ dueDate: date });
    }

    this.setState({ [field]: value, invoiceErrors: invoiceErrors });
  }

  handleSelectCustomer(customerNumber) {
    fetchCustomer(customerNumber)
      .then(response => response.json())
      .then(customer => {
        this.setState({
          selectedCustomer: customer,
          customerNumber: customerNumber,
        });
      })
  }

  handleFilterCustomer(query) {
    return fetchCustomerData(query)
      .then(response => response.json())
      .then((customers) => {
        return customers
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((customer) => {
                  return {
                    label: `${customer.name} - ${customer.fortnox_customer_number}`,
                    value: customer.fortnox_customer_number
                  };
                });
      });
  }

  handleUpdateCustomer(customerNumber) {
    fetchCustomer(customerNumber)
      .then(response => response.json())
      .then(customer => {
        this.setState({
          selectedCustomer: customer,
          customerFormAction: ACTIONS.UPDATE,
          customerNumber: customerNumber,
          customerFormOpen: true
        });
      })
  }

  handleRowInputChange(field, value, rowIndex) {
    if (this.state.articleModalOpen) {
      const selectedArticle = this.state.selectedArticle;
      selectedArticle[field] = value;

      this.setState({
        selectedArticle: selectedArticle
      });
    } else {
      const invoiceRows = this.state.invoiceRows;
      const invoiceRow = invoiceRows[rowIndex];
      const newInvoiceRow = invoiceRow ? invoiceRow : {};

      if (['price', 'deliveredQuantity'].includes(field)) {
        newInvoiceRow[field] = value.replace(',', '.');
      } else {
        newInvoiceRow[field] = value;
      }

      newInvoiceRow.vat = newInvoiceRow.vat || '25';
      if (field === 'houseWorkType' && value) {
        newInvoiceRow['houseWork'] = true;
      }
      invoiceRows[rowIndex] = newInvoiceRow;

      this.setState({
        invoiceRows: invoiceRows
      });
    }
  }

  handleRowChange(increment) {
    const { rowCount } = this.state;

    if (!increment) {
      const invoiceRows = this.state.invoiceRows;
      const lastRow = invoiceRows[rowCount];
      invoiceRows.splice(rowCount - 1, 1);

      this.setState({
        invoiceRows: invoiceRows
      });
    }

    const newRowCount = increment ? rowCount + 1 : rowCount - 1;

    this.setState({
      rowCount: newRowCount,
    });
  }

  handleClearRow(rowIndex) {
    const invoiceRows = this.state.invoiceRows;
    invoiceRows.splice(rowIndex, 1);

    this.setState({
      invoiceRows: invoiceRows
    })
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      customerNumber,
      invoiceRows,
      invoice,
      invoiceDate,
      dueDate,
      termsOfPayment,
    } = this.state;

    if (!customerNumber) {
      this.setState({
        invoiceErrors: ['customerNumber']
      });

      return;
    }

    const errorFields = [];

    if (invoiceRows.length === 0) {
      this.setState({
        invoiceRowErrors: {
          0: ['description', 'unit', 'price', 'deliveredQuantity']
        }
      });

      return;
    }

    invoiceRows.forEach((row, index) => {
      ['description', 'unit', 'price', 'deliveredQuantity', 'vat'].forEach((field) => {
        if (Object.keys(invoice || {}).length === 0 && !invoice.credit && !row[field]) {
          errorFields.push(field);
        }
      });

      if (errorFields.length > 0) {
        this.setState({
          invoiceRowErrors: {
            [index]: errorFields
          }
        });

        return;
      }
    });

    if (errorFields.length > 0) {
      return;
    }

    const hasTaxReduction = invoiceRows.some(row => row.houseWork);
    const rows = invoiceRows.map((row) => {
      if (hasTaxReduction && (row.houseWork === null || row.houseWork === undefined)) {
        row.houseWorkType = 'EMPTYHOUSEWORK';
      }

      return row;
    });

    invoiceDate.setHours(invoiceDate.getHours() + 2);  // GMT+0200 to UTC
    dueDate.setHours(dueDate.getHours() + 2);  // GMT+0200 to UTC

    const body = {
      customer_number: customerNumber,
      invoice_rows: rows,
      due_date: dueDate,
      invoice_date: invoiceDate,
      terms_of_payment: termsOfPayment,
    }

    if (invoice) {
      body.document_number = invoice.documentNumber;
    }

    this.props.onSubmit && this.props.onSubmit(body, hasTaxReduction);
  }

  handleOpenTaxReductionForm() {
    this.setState({
      taxReductionModalOpen: true,
      taxReductionInvoice: this.props.invoice
    })
  }

  openArticleModal(e, articleNumber) {
    e.stopPropagation();

    const article = this.props.articles.find((article) => {
      return article.article_number === articleNumber
    });

    const articleToUpdate = article ? article : {};

    articleToUpdate.articleNumber = article.article_number;
    articleToUpdate.vat = article.vat;
    articleToUpdate.description = article.description;
    articleToUpdate.unit = article.unit;
    articleToUpdate.price = article.sales_price;

    this.setState({
      articleModalOpen: true,
      selectedArticle: articleToUpdate
    });
  }

  toggleCustomerForm(action) {
    this.setState({
      customerFormOpen: !this.state.customerFormOpen,
      customerFormAction: action,
    });
  }

  closeCustomerModal() {
    this.setState({
      customerFormOpen: false,
    });
  }

  createTaxReduction(body) {
    setTimeout(() => {
      this.setState({
        taxReductionModalOpen: false
      });
    }, 500);

    this.props.onCreateTaxReduction(body);
  }

  updateTaxReduction(id, body) {
    setTimeout(() => {
      this.setState({
        taxReductionModalOpen: false
      });
    }, 500);

    this.props.onUpdateTaxReduction(id, body);
  }

  renderRows(includeHouseWork) {
    const articles = this.props.articles.map((article) => {
      return {
        label: `${article.article_number} - ${article.description}`,
        value: article
      }
    });

    const {
      createdArticle,
      rowCount,
      invoiceRows,
      invoiceRowErrors,
      invoice,
      selectedCustomer,
      units,
    } = this.state;
    const t = i18n.getFixedT();
    const commonFields = [
      'deliveredQuantity',
      'price',
    ];
    const rows = [];

    for (let i = 0; i < rowCount; i++) {
      rows.push(
        <InvoiceRow
          index={i}
          articles={articles}
          createdArticle={createdArticle}
          commonFields={commonFields}
          key={i}
          onChange={(field, value) => this.handleRowInputChange(field, value, i)}
          onArticleChange={(article) => this.handleArticleChange(article, i)}
          onAddArticle={this.handleAddArticle}
          removeRow={Object.keys(invoice).length === 0 && i > 0 && i === rowCount - 1 ? this.handleRowChange : null}
          rowValues={invoiceRows[i] || {}}
          clearRow={Object.keys(invoice).length === 0 && invoiceRows[i] !== undefined ? this.handleClearRow : null}
          errors={invoiceRowErrors[i] || []}
          openArticleModal={this.openArticleModal}
          taxReductions={auth.getCompanyTaxReductions()}
          selectedCustomer={selectedCustomer}
          includeHouseWork={includeHouseWork}
          units={units}
        />
      )
    }

    return rows;
  }

  render() {
    const t = i18n.getFixedT();
    const {
      dueDate,
      invoiceDate,
      invoiceErrors,
      customerFormOpen,
      customerNumber,
      invoiceRows,
      articleModalOpen,
      customerFormAction,
      selectedCustomer,
      invoice,
      termsOfPayment,
      termsOfPaymentOptions,
      taxReductionModalOpen,
      taxReductionInvoice,
      units,
    } = this.state;

    const {
      isFetching,
      lastUpdated,
      customers,
      creditInvoice,
      taxReduction,
      fetchTaxReductionByInvoice,
    } = this.props;

    const customerSelectOptions = customers
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((customer) => {
        return {
          label: `${customer.name} - ${customer.fortnox_customer_number}`,
          value: customer.fortnox_customer_number
        };
      });

    if (isFetching) {
      return (
        <Loader
          size={25}
        />
      )
    }

    const formatOptionLabel = ({ value, label }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {label}
        <Icon
          className="customer-settings-icon update-customer-icon"
          title={t('updateCustomer')}
          onClick={() => this.handleUpdateCustomer(value)}
        >
          { 'settings' }
        </Icon>
      </div>
    );

    const SingleValue = ({ ...props }) => {
      return (
        <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
      )
    };

    const netAmount = calculateNetPrice(invoiceRows);
    const totalAmount = calculateTotalPrice(invoiceRows);
    const vatAmount = totalAmount - netAmount;
    const rotAmount = calculateROT(invoiceRows.filter((invoiceRow) => invoiceRow.houseWork));
    const rutAmount = calculateRUT(invoiceRows.filter((invoiceRow) => invoiceRow.houseWork));
    const includeHouseWork = (!selectedCustomer || selectedCustomer.private) &&
                              (!invoice.documentNumber || invoice.taxReductionPossible) &&
                              auth.getCompanyTaxReductions().length > 0;

    return (
      <div className="invoice-form-container">

        {taxReductionModalOpen &&
          <TaxReductionForm
            invoice={taxReductionInvoice}
            taxReduction={taxReduction}
            onCreateTaxReduction={this.createTaxReduction}
            onUpdateTaxReduction={this.updateTaxReduction}
            fetchTaxReductionByInvoice={fetchTaxReductionByInvoice}
            onCloseModal={() => this.setState({ taxReductionModalOpen: false })}
            updateTaxReduction={this.props.updateTaxReduction}
            calculatedROTAmount={rotAmount}
            calculatedRUTAmount={rutAmount}
          />
        }

        {articleModalOpen &&
          <Modal
            open={articleModalOpen}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className="custom-modal modal-form update-article-modal">
              <div className="modal-header">
                <span className="modal-title">{t('updateArticle')}</span>
                <Icon
                  onClick={() => this.setState({ articleModalOpen: false })}
                  style={{
                    margin: '10px 0 5px 4px',
                    float: 'right',
                    cursor: 'pointer'
                  }}
                >
                  clear
                </Icon>
              </div>
              <div className="update-article-content">
                <InvoiceRow
                  update
                  index={0}
                  rowValues={this.state.selectedArticle}
                  commonFields={['price']}
                  openArticleModal={this.openArticleModal}
                  onChange={(field, value) => this.handleRowInputChange(field, value, 0)}
                  onAddArticle={this.handleAddArticle}
                  onDeleteArticle={this.handleDeleteArticle}
                  errors={[]}
                  includeHouseWork={includeHouseWork}
                  units={units}
                />
              </div>
            </div>
          </Modal>
        }

        {customerFormOpen &&
          <CustomerForm
            action={customerFormAction}
            selectedCustomer={selectedCustomer}
            closeCustomerModal={this.closeCustomerModal}
          />
        }
        <form onSubmit={(event) => this.handleSubmit(event)}>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <AsyncSelect
                components={{ SingleValue }}
                formatOptionLabel={formatOptionLabel}
                styles={selectCustomerStyles(invoiceErrors.includes('customerNumber'))}
                placeholder={t('customer')}
                defaultOptions={customerSelectOptions}
                loadOptions={this.handleFilterCustomer}
                value={customerSelectOptions.find(opt => opt.value === customerNumber) || ''}
                onChange={(selectedOption) => this.handleSelectCustomer(selectedOption.value)}
              />
              <Icon
                className="customer-settings-icon create-customer-icon"
                onClick={() => this.toggleCustomerForm(ACTIONS.CREATE)}
                title={t('createCustomer')}
              >
                { customerFormOpen && customerFormAction === ACTIONS.CREATE ? 'remove_square' : 'add_square' }
              </Icon>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Select
                placeholder={t('termsOfPayment')}
                options={termsOfPaymentOptions}
                styles={selectTermsOfPaymentsStyles(false)}
                value={termsOfPaymentOptions.find(opt => opt.value === termsOfPayment) || ''}
                onChange={(selectedOption) => this.handleInvoiceInputChange('termsOfPayment', selectedOption.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="invoice-date"
                  label={t('invoiceDate')}
                  value={invoiceDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  onChange={(value) => this.handleInvoiceInputChange('invoiceDate', value)}
                  style={{ float: 'left', marginTop: 0 }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="due-date"
                  label={t('dueDate')}
                  value={dueDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  onChange={(value) => this.handleInvoiceInputChange('dueDate', value)}
                  style={{ float: 'left', marginTop: 0 }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                justify="flex-start"
                spacing={2}
                style={{
                  textAlign: 'left',
                  background: '#048c9c',
                  color: '#ffffff',
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                  width: '100%',
                  marginLeft: 1,
                }}
              >
                <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 11}}>
                  {t('articleNo')}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={includeHouseWork ? 3 : 4} style={{paddingLeft: 13}}>
                  {t('designation')}
                </Grid>

                {includeHouseWork ?
                  auth.getCompanyTaxReductions().map(field => (
                    <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 19}} key={field}>
                      {field}
                    </Grid>
                  ))
                : null }

                <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 19}}>
                  {t('unit')}
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 19}}>
                  {t('quantity')}
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 19}}>
                  {t('price')}
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 19}}>
                  {t('vat')}
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 19}}>
                  {t('discount')}
                </Grid>
              </Grid>
            </Grid>
            {this.renderRows(includeHouseWork)}
          </Grid>
          <Grid container justify="flex-start" spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={includeHouseWork ? 4 : 5}
              lg={includeHouseWork ? 4 : 5}
            >
              {!invoice.booked ?
                <React.Fragment>
                  <Button
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      background: '#66bb6a',
                      margin: '10px 5px 5px 0',
                      float: 'left',
                    }}
                    onClick={() => this.handleRowChange(true)}
                  >
                    {t('addRow')}
                  </Button>

                  <Button
                    type="submit"
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      background: '#66bb6a',
                      margin: '10px 0 5px 0',
                      float: 'left',
                    }}
                  >
                    {t('save')}
                  </Button>
                </React.Fragment>
                : null
              }
              {invoice.booked && invoice.creditInvoiceReference ?
                <p>{t('editNotAllowed')}</p> : null
              }
              {
                invoice.documentNumber && invoice.booked && !invoice.credit && !invoice.creditInvoiceReference &&
                <Button
                  style={{
                    color: '#FFFFFF',
                    fontSize: 12,
                    background: '#2da3f5',
                    margin: '10px 0 5px 5px',
                    float: 'left',
                  }}
                  onClick={() => creditInvoice(invoice.documentNumber)}
                >
                  { t('credit') }
                </Button>
              }
              {
                invoice.documentNumber && invoice.taxReductionPossible && auth.getCompanyTaxReductions().length > 0 &&
                <Button
                  style={{
                    color: '#FFFFFF',
                    fontSize: 12,
                    background: '#2da3f5',
                    margin: '10px 0 5px 5px',
                    float: 'left',
                  }}
                  onClick={() => this.handleOpenTaxReductionForm()}
                >
                  { t('addTaxReduction') }
                </Button>
              }
            </Grid>
            {totalAmount || netAmount || vatAmount ?
              // TODO: Fetch customers/show on select customer and get currency.
              <React.Fragment>
                <Grid item xs={4} sm={4} md={2} lg={2}>
                  <p>{t('netPrice')}: {formatMoney(netAmount || 0, 'SEK')}</p>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2}>
                  <p>{t('vat')}: {formatMoney(vatAmount, 'SEK')}</p>
                </Grid>

                <Grid item xs={4} sm={4} md={2} lg={2}>
                  <p>{t('total')}: {formatMoney(totalAmount, 'SEK')}</p>
                </Grid>

                {
                  includeHouseWork && auth.getCompanyTaxReductions().includes('ROT') &&
                    <Grid item xs={4} sm={4} md={2} lg={2}>
                      <p>{t('ROT')}: {formatMoney(rotAmount, 'SEK')}</p>
                    </Grid>
                }

                {
                  // TODO: Fakturan måste ju innehålla rut med...
                  // Nu räknar vi rut även om fakturan inte innehåller någon rut
                  includeHouseWork && auth.getCompanyTaxReductions().includes('RUT') &&
                    <Grid item xs={4} sm={4} md={2} lg={2}>
                      <p>{t('RUT')}: {formatMoney(rutAmount, 'SEK')}</p>
                    </Grid>
                }
              </React.Fragment>
            : null }
          </Grid>
        </form>
      </div>
    )
  }
}

InvoiceForm.propTypes = {
  articles: PropTypes.array.isRequired,
  customers: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { customerData, articleData } = state;
  const customers = customerData.data || [];
  const articles = articleData.data || [];
  const isFetching = articleData.isFetching === undefined &&
                     customerData.isFetching === undefined ? true
                     : (articleData.isFetching || customerData.isFetching);

  return {
    isFetching,
    customers,
    articles,
  }
}

export default connect(mapStateToProps)(InvoiceForm)
