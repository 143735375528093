import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import { formatMoney } from '../utils/money-utils';
import Loader from '../common/loader';
import {
  fetchTransactionDataIfNeeded,
} from '../actions/transactions';
import i18n from '../i18n';

class Transactions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderFormOpen: false,
      transactions: [],
      pagination: {
        page: 1,
        totalPages: 1
      }
    }

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    const { dispatch, data, meta, selectedFinancialYear } = this.props;
    const { pagination } = this.state;

    const id = selectedFinancialYear ? selectedFinancialYear.id : null;

    dispatch(fetchTransactionDataIfNeeded(pagination.page, id));

    this.setState({
      transactions: data,
      pagination: {
        ...this.state.pagination,
        totalPages: meta.total_pages
      }
    });
  }

  componentDidUpdate(prevProps) {
    const {
      data,
      meta,
      didInvalidate,
      dispatch,
      lastUpdated,
      selectedFinancialYear,
    } = this.props;
    const { pagination } = this.state;

    if (prevProps.lastUpdated !== lastUpdated) {
      this.setState({
        transactions: data,
        pagination: {
          ...this.state.pagination,
          totalPages: meta.total_pages
        }
      });
    }

    if (didInvalidate && prevProps.didInvalidate !== didInvalidate) {
      const id = selectedFinancialYear ? selectedFinancialYear.id : null;
      dispatch(fetchTransactionDataIfNeeded(pagination.page, id));

      this.setState({
        transactions: data,
        pagination: {
          ...this.state.pagination,
          totalPages: meta.total_pages
        }
      });
    }
  }

  handlePageChange(page) {
    const { pagination } = this.state;
    this.setState({
      pagination: {
        ...this.state.pagination,
        page: page
      }
    }, () => {
      const id = this.props.selectedFinancialYear ? this.props.selectedFinancialYear.id : null;
      this.props.dispatch(
        fetchTransactionDataIfNeeded(this.state.pagination.page, id)
      )
    })
  };

  render() {
    const t = i18n.getFixedT();
    const { transactions, pagination } = this.state;
    const { isFetching, lastUpdated } = this.props;

    return (
      <Grid container justify="center">
        <Grid item xs={10} style={{margin: '10px 0 10px 0'}}>
          <Pagination
            variant="outlined"
            shape="rounded"
            onChange={(_, page) => this.handlePageChange(page)}
            page={pagination.page}
            count={pagination.totalPages}
          />
        </Grid>
        <Grid item xs={10}>
          <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
            <Table aria-label="table">
              <TableHead style={{color: '#ffffff', background: 'linear-gradient(60deg, #ffa726, #ffa41e)' }}>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold', color: '#FFFFFF', width: '50%' }} align="left">{t('description')}</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('date')}</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#FFFFFF' }} align="right">{t('amount')}</TableCell>
                </TableRow>
              </TableHead>
              {isFetching?
                <TableBody>
                  <TableRow>
                    <TableCell style={{height: '100vh'}}>
                      <Loader
                        style={{
                          position: 'absolute',
                          top: '35%',
                          left: '40%'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              :
                <TableBody>
                  {transactions.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell align="left" style={{width: '50%'}}>{row.description}</TableCell>
                      <TableCell align="left">{row.date}</TableCell>
                      <TableCell align="right">{formatMoney(row.amount, row.currency, true)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              }
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={10} style={{margin: '10px 0 10px 0'}}>
          <Pagination
            variant="outlined"
            shape="rounded"
            onChange={(_, page) => this.handlePageChange(page)}
            page={pagination.page}
            count={pagination.totalPages}
          />
        </Grid>
      </Grid>
    )
  }
}

Transactions.propTypes = {
  data: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { transactionData } = state;
  const { lastUpdated, didInvalidate } = transactionData;
  const data = transactionData.data || [];
  const meta = transactionData.meta || {};
  const isFetching = transactionData.isFetching === undefined ? true : transactionData.isFetching;

  return {
    isFetching,
    lastUpdated,
    data,
    meta,
    didInvalidate,
  }
}

export default connect(mapStateToProps)(Transactions)
