import request from './request';
import upload from './file-upload';

export function fetchOrderData(filter, page) {
  let url = filter ? `orders?filter=${filter}&page=${page}` : `orders?page=${page}`;
  return request(url, {
    method: 'GET'
  });
}

export function fetchOrder(orderNumber) {
  return request(`orders/${orderNumber}`, {
    method: 'GET'
  });
}

export function createOrder(order) {
  return request('orders', {
    method: 'POST',
    body: order
  });
}

export function updateOrder(order) {
  return request(`orders/${order.document_number}`, {
    method: 'PATCH',
    body: order
  });
}

export function cancelOrder(orderNumber) {
  return requestOrderAction('cancel', orderNumber);
}

export function emailOrder(orderNumber) {
  return requestOrderAction('email', orderNumber);
}

export function createOrderInvoice(orderNumber) {
  return requestOrderAction('create_invoice', orderNumber);
}

export function fetchOrderPdf(orderNumber) {
  request(`orders/${orderNumber}/preview`, {
    method: 'GET',
  }).then(response => response.blob())
    .then((blob) => {
      const file = new Blob([blob], {
        type: 'application/pdf',
      });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL);
    });
}

export function printOrder(orderNumber) {
  request(`orders/${orderNumber}/print`, {
    method: 'GET',
  }).then(response => response.blob())
    .then((blob) => {
      const file = new Blob([blob], {
        type: 'application/pdf',
      });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL);
    });
}

export function uploadFileAttachment(orderNumber, formData) {
  return upload(`orders/${orderNumber}/upload_file_attachment`, formData);
}

export function deleteFileAttachment(orderNumber, orderFileAttachmentUuid) {
  return request(`orders/${orderNumber}/attachment?file_uuid=${orderFileAttachmentUuid}`, {
    method: 'DELETE'
  });
}

export function fetchFileAttachment(orderNumber, orderFileAttachmentUuid) {
  return request(`orders/${orderNumber}/attachment?file_uuid=${orderFileAttachmentUuid}`, {
    method: 'GET'
  });
}

function requestOrderAction(action, orderNumber) {
  return request(`orders/${orderNumber}/${action}`, {
    method: 'POST',
    body: {}
  });
}
