import {
  REQUEST_RESULT_DOCUMENT_DATA,
  RECEIVE_RESULT_DOCUMENT_DATA,
  INVALIDATE_RESULT_DOCUMENT_DATA,
} from './document-action-types';

import { fetchDocumentData } from '../services/documents';

function receiveDocumentData(documentData) {
  return {
    type: RECEIVE_RESULT_DOCUMENT_DATA,
    documentData: documentData,
    receivedAt: Date.now(),
  }
}

function requestDocumentData() {
  return {
    type: REQUEST_RESULT_DOCUMENT_DATA
  }
}

export function invalidateDocumentData() {
  return {
    type: INVALIDATE_RESULT_DOCUMENT_DATA
  }
}

export function asyncFetchDocumentData(documentCategory) {
  return function (dispatch, getState) {
    dispatch(requestDocumentData());

    return fetchDocumentData(documentCategory)
      .then(response => response.json())
      .then(json => dispatch(receiveDocumentData(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function shouldfetchDocumentData(state) {
  const documentData = state.documentData;
  if (!documentData) {
    return true;
  }

  if (documentData.isFetching) {
    return false;
  }

  return documentData.didInvalidate;
}

export function fetchDocumentDataIfNeeded(documentCategory) {
  return (dispatch, getState) => {
    if (shouldfetchDocumentData(getState())) {
      return dispatch(asyncFetchDocumentData(documentCategory));
    }
  }
}

