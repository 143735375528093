import React from 'react';
import { connect } from 'react-redux'
import {
  fetchSupplierInvoiceDataIfNeeded,
} from '../actions/supplier-invoices';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Loader from '../common/loader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import InvoiceList from '../invoices/invoice-list';
import InvoiceForm from '../invoices/invoice-form';
import i18n from '../i18n';
import { formatMoney } from '../utils/money-utils';

const resolveStatus = (supplierInvoice) => {
  const t = i18n.getFixedT();

  if (supplierInvoice.cancelled) {
    return t('cancelled');
  }

  return supplierInvoice.final_pay_date ? t('paid') : t('unpaid');
}

const resolveStatusColor = (supplierInvoice) => {
  if (supplierInvoice.cancelled) {
    return { color: '#aaaaaa' }
  }

  if (Date.parse(supplierInvoice.due_date) > Date.now() && !supplierInvoice.final_pay_date) {
    return { color: '#e7ea0f' };
  }

  return supplierInvoice.final_pay_date ? { color: "#66bb6a" } : { color: "#e53935" }
}

class SupplierInvoices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      supplierInvoices: [],
      pagination: {
        page: 1,
        totalPages: 1
      }
    }

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    const { dispatch, data, meta } = this.props;

    dispatch(fetchSupplierInvoiceDataIfNeeded(this.state.pagination.page));

    this.setState({
      supplierInvoices: data,
      pagination: {
        ...this.state.pagination,
        totalPages: meta.total_pages
      }
    });
  }

  componentDidUpdate(prevProps) {
    const {
      data,
      meta,
      didInvalidate,
      dispatch,
      lastUpdated,
    } = this.props;

    if (prevProps.lastUpdated !== lastUpdated) {
      const newSupplierInvoices = data.sort((a, b) => {
        const docNumA = parseInt(a.document_number);
        const docNumB = parseInt(b.document_number);

        if (docNumA > docNumB) return -1;
        if (docNumB > docNumA) return 1;

        return 0;
      });

      this.setState({
        supplierInvoices: newSupplierInvoices,
        pagination: {
          ...this.state.pagination,
          totalPages: meta.total_pages
        }
      });
    }

    if (didInvalidate && prevProps.didInvalidate !== didInvalidate) {
      dispatch(fetchSupplierInvoiceDataIfNeeded(this.state.pagination.page));

      this.setState({
        supplierInvoices: data,
        pagination: {
          ...this.state.pagination,
          totalPages: meta.total_pages
        }
      });
    }
  }

  handlePageChange(page) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        page: page
      }
    }, () => {
      this.props.dispatch(
        fetchSupplierInvoiceDataIfNeeded(this.state.pagination.page)
      )
    })
  };

  render() {
    const t = i18n.getFixedT();
    const { supplierInvoices, pagination } = this.state;
    const { isFetching, lastUpdated } = this.props;

    return (
      <Grid container justify="center" spacing={1}>
        <Grid item xs={11}>
          <Pagination
            variant="outlined"
            shape="rounded"
            onChange={(_, page) => this.handlePageChange(page)}
            page={pagination.page}
            count={pagination.totalPages}
          />
        </Grid>
        <Grid item xs={11}></Grid>
        <Grid item xs={11}>
          <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
            <Table aria-label="dashboard table">
              <TableHead style={{background: 'linear-gradient(60deg, rgb(239, 83, 80), rgb(229, 57, 53))'}}>
                <TableRow>
                  <TableCell style={{ width: '28%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('supplier')}</TableCell>
                  <TableCell style={{ width: '17%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('invoiceDate')}</TableCell>
                  <TableCell style={{ width: '17%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('dueDate')}</TableCell>
                  <TableCell style={{ width: '17%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('status')}</TableCell>
                  <TableCell style={{ width: '17%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('amount')}</TableCell>
                  <TableCell align="right" style={{ width: '4%' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { isFetching ?
                  <TableRow>
                    <TableCell style={{height: '100vh'}}>
                      <Loader
                        style={{
                          position: 'absolute',
                          top: '35%',
                          left: '40%'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                :
                  supplierInvoices.map((supplierInvoice, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell style={{color: supplierInvoice.cancelled ? '#aaaaaa' : '#000000'}} component="th" scope="row">
                          {supplierInvoice.supplier_name}
                        </TableCell>
                        <TableCell style={{color: supplierInvoice.cancelled ? '#aaaaaa' : '#000000'}} align="left">{supplierInvoice.invoice_date}</TableCell>
                        <TableCell
                          align="left"
                          style={resolveStatusColor(supplierInvoice)}
                        >
                          {supplierInvoice.due_date}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={resolveStatusColor(supplierInvoice)}
                        >
                          {resolveStatus(supplierInvoice)}
                        </TableCell>
                        <TableCell style={{color: supplierInvoice.cancelled ? '#aaaaaa' : '#000000'}} align="left">{formatMoney(supplierInvoice.total, supplierInvoice.currency, true)}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                }
              </TableBody>

            </Table>
          </TableContainer>

          <Grid item xs={11} style={{margin: '10px 0 10px 0'}}>
            <Pagination
              variant="outlined"
              shape="rounded"
              onChange={(_, page) => this.handlePageChange(page)}
              page={pagination.page}
              count={pagination.totalPages}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

SupplierInvoices.propTypes = {
  data: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { supplierInvoiceData } = state;
  const { lastUpdated, didInvalidate } = supplierInvoiceData;
  const data = supplierInvoiceData.data || [];
  const meta = supplierInvoiceData.meta || {};
  const isFetching = supplierInvoiceData.isFetching === undefined ? true : supplierInvoiceData.isFetching;

  return {
    isFetching,
    lastUpdated,
    data,
    meta,
    didInvalidate,
  }
}

export default connect(mapStateToProps)(SupplierInvoices)
