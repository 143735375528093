import request from './request';
import upload from './file-upload';

export function uploadDocument(formData) {
  return upload('files', formData);
}

export function fetchDocumentData(documentCategory) {
  return request(`files?attachment_type=${documentCategory}`, {
    method: 'GET'
  });
}

export function fetchDocumentUrl(fileKey, filePath) {
  return request(`files/${fileKey}?path=${filePath}`, {
    method: 'GET'
  });
}
