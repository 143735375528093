import React from 'react';
import {
  Grid,
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';

import './form-input.scss';

export default function FormInput(props) {
  const {
    onChange,
    fullWidth,
    label,
    variant,
    xs,
    sm,
    md,
    lg,
    value,
    error,
    rows,
    style,
    className,
    placeholder,
    type,
  } = props;

  const inputClasses = classNames({
    'form-input': true,
    'form-input-error': error,
  });

  return (
    <Grid item xs={xs || 12} sm={sm || 12} md={md || 12} lg={lg || 12}>
      <TextField
        className={`${inputClasses} ${className}`}
        fullWidth={fullWidth}
        label={label}
        variant={variant}
        onChange={onChange}
        value={value || ''}
        style={Object.keys(style || {}).length > 0 ? style : {marginLeft: 4}}
        rows={rows || 1}
        multiline={rows > 1}
        placeholder={placeholder}
        type={type}
      />
    </Grid>
  )
}
