import { login, logout } from '../services/authentication';

class Authentication {
  login(email, password, rememberEmail) {
    return login(email, password)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error;
        }
        return response.json();
      })
      .then(data => {
        this.setCompanyId(data.company_id);
        this.setCompanyName(data.company_name);
        this.setCompanyTaxReductions(data.company_tax_reductions);
        this.setEmail(data.email);
        this.setEmployeeId(data.employee_id);
        this.setToken(data.token);
        this.setLegacyAuth(data.legacy_auth);
        this.setRememberedEmail(rememberEmail, email);
        this.onAuthenticated && this.onAuthenticated(
          data.subscriptions, data.changed_password
        );

        return data;
      }).catch((err) => {
        console.log("err: ", err);
        // TODO: Handle error?
    });
  }

  logout() {
    return logout(this.getToken()).finally(() => {
      this.removeSessionData();
      this.onLogout && this.onLogout();
    }).catch((err) => {
      // TODO: Handle error?
    })
  }

  isAuthenticated() {
    const token = this.getToken();

    return token !== undefined && token !== null;
  }

  getCompanyId() {
    return localStorage.getItem('AR_SESSION_COMPANY_ID');
  }

  getCompanyName() {
    return localStorage.getItem('AR_SESSION_COMPANY_NAME');
  }

  getCompanyTaxReductions() {
    const company_tax_reductions = localStorage.getItem('AR_SESSION_COMPANY_TAX_REDUCTIONS');

    return company_tax_reductions ? company_tax_reductions.split(",") : [];
  }

  getEmail() {
    return localStorage.getItem('AR_SESSION_EMAIL');
  }

  getEmployeeId() {
    return localStorage.getItem('AR_SESSION_EMPLOYEE_ID');
  }

  getToken() {
    return localStorage.getItem('AR_SESSION_TOKEN');
  }

  getLegacyAuth() {
    return this.typecast(localStorage.getItem('AR_SESSION_LEGACY_AUTH'));
  }

  getOauthToken() {
    return localStorage.getItem('AR_SESSION_OAUTH_TOKEN');
  }

  getOauthTokenExpiresAt() {
    return parseInt(localStorage.getItem('AR_SESSION_OAUTH_TOKEN_EXPIRES_AT') || 0);
  }

  getRememberedEmail() {
    return localStorage.getItem('AR_SESSION_REMEMBERED');
  }

  getSubscriptions() {
    const subscriptions = localStorage.getItem('AR_SESSION_SUBSCRIPTIONS');

    return subscriptions ? subscriptions.split(",") : [];
  }

  getSelectedFinancialYear() {
    return localStorage.getItem('AR_SESSION_SELECTED_FINANCIAL_YEAR');
  }

  setCompanyId(companyId) {
    localStorage.setItem('AR_SESSION_COMPANY_ID', companyId);
  }

  setCompanyName(companyName) {
    localStorage.setItem('AR_SESSION_COMPANY_NAME', companyName);
  }

  setCompanyTaxReductions(companyTaxReductions) {
    localStorage.setItem('AR_SESSION_COMPANY_TAX_REDUCTIONS', companyTaxReductions.join(','));
  }

  setEmail(email) {
    localStorage.setItem('AR_SESSION_EMAIL', email);
  }

  setEmployeeId(employeeId) {
    localStorage.setItem('AR_SESSION_EMPLOYEE_ID', employeeId);
  }

  setToken(token) {
    localStorage.setItem('AR_SESSION_TOKEN', token);
  }

  setLegacyAuth(legacyAuth) {
    localStorage.setItem('AR_SESSION_LEGACY_AUTH', legacyAuth);
  }

  setOauthToken(token) {
    if (!token && localStorage.getItem('AR_SESSION_OAUTH_TOKEN')) {
      localStorage.removeItem('AR_SESSION_OAUTH_TOKEN');
    } else {
      localStorage.setItem('AR_SESSION_OAUTH_TOKEN', token);
    }
  }

  setOauthTokenExpiresAt(expiresAt) {
    localStorage.setItem('AR_SESSION_OAUTH_TOKEN_EXPIRES_AT', Date.parse(expiresAt));
  }

  setRememberedEmail(rememberEmail, email) {
    if (rememberEmail) {
      localStorage.setItem('AR_SESSION_REMEMBERED', email);
    } else {
      localStorage.removeItem('AR_SESSION_REMEMBERED');
    }
  }

  setSubscriptions(subscriptions) {
    localStorage.setItem('AR_SESSION_SUBSCRIPTIONS', subscriptions.join(','));
  }

  setSelectedFinancialYear(selectedFinancialYear) {
    localStorage.setItem('AR_SESSION_SELECTED_FINANCIAL_YEAR', selectedFinancialYear);
  }

  removeSessionData() {
    localStorage.removeItem('AR_SESSION_COMPANY_ID');
    localStorage.removeItem('AR_SESSION_COMPANY_NAME');
    localStorage.removeItem('AR_SESSION_COMPANY_BANK');
    localStorage.removeItem('AR_SESSION_EMAIL');
    localStorage.removeItem('AR_SESSION_EMPLOYEE_ID');
    localStorage.removeItem('AR_SESSION_TOKEN');
    localStorage.removeItem('AR_SESSION_OAUTH_TOKEN');
    localStorage.removeItem('AR_SESSION_LEGACY_AUTH');
    localStorage.removeItem('AR_SESSION_COMPANY_TAX_REDUCTIONS');
    localStorage.removeItem('AR_SESSION_SUBSCRIPTIONS')
    localStorage.removeItem('AR_SESSION_SELECTED_FINANCIAL_YEAR');
  }

  typecast(value) {
    switch (value) {
      case 'true':
        value = true;
        break;
      case 'false':
        value = false;
        break;
    }

    return value;
  }
}

const auth = new Authentication();
export default auth;
