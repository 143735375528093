import React from 'react';
import { Grid } from '@material-ui/core';
import ReportCard from '../reports/report-card';
import LineChart from '../charts/line-chart';
import BarChart from '../charts/bar-chart';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  presentFinancialYear,
  presentPreviousFinancialYear,
} from '../utils/financial-year-utils';
import i18n from '../i18n';

export default function ReportList(props) {
  const { reports, financialYears, selectedFinancialYear } = props;
  const t = i18n.getFixedT();

  return (
    <Grid container justify="center" spacing={4}>
      {reports.map((report) => (
        <Grid key={report.account} item xs={12} sm={12} md={6} lg={6}>
          <ReportCard
            medium={true}
            title={report.description}
            icon={<AssessmentIcon style={{ color: '#66bb6a' }}/>}
            subHeader={report.previous_year &&
              <div>
                <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#3c4fb3' }}/>
                <p style={{margin: '2px', float: 'left'}}>
                  {presentFinancialYear(selectedFinancialYear)}
                </p>
                <FiberManualRecordIcon style={{ float: 'left', marginLeft: '10px', color: '#ffa726' }}/>
                <p style={{margin: '2px', float: 'left'}}>
                  {presentPreviousFinancialYear(financialYears, selectedFinancialYear)}
                </p>
              </div>
            }
          >
            { report.current_year.length === 1
              ?
                <BarChart
                  data={report.current_year}
                  secondaryData={report.previous_year}
                  barWidth={10}
                  dataColor='#3c4fb3'
                  secondaryDataColor='#ffa726'
                  background='#FFFFFF'
                  axisColor='#AAAAAA'
                  labelColor='#000000'
                />
              :
                <LineChart
                  data={report.current_year}
                  secondaryData={report.previous_year}
                  barWidth={10}
                  dataColor='#3c4fb3'
                  secondaryDataColor='#ffa726'
                  background='#FFFFFF'
                  axisColor='#AAAAAA'
                  labelColor='#000000'
                />
            }
          </ReportCard>
        </Grid>
      ))}
    </Grid>
  )
}
