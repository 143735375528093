import React from 'react';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
  VictoryGroup,
} from 'victory';
import { formatMoney } from '../utils/money-utils';

export default function BarChart(props) {
  const {
    data,
    barWidth,
    dataColor,
    axisColor,
    background,
    labelColor,
  } = props;

  const months = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'Maj',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Okt',
    11: 'Nov',
    12: 'Dec'
  }

  const toggleDisplayLabels = (target) => {
    return {
      target: target,
      eventHandlers: {
        onMouseOver: () => {
          return [{
            target: 'labels',
            mutation: (props) => {
              return { style: { ...props.style, opacity: 1 } };
            }
          }];
        },
        onMouseOut: () => {
          return [{
            target: 'labels',
            mutation: (props) => {
              return { style: { ...props.style, opacity: 0 } }
            }
          }];
        }
      }
    }
  }

  return (
    <VictoryChart
      domainPadding={10}
      theme={VictoryTheme.material}
      style={{
        parent: {
          background: background || 'linear-gradient(60deg, #ef5350, #e53935)',
          borderRadius: '5px',
        },
      }}
      animate={{ duration: 1500 }}
      height={233}
    >
      <VictoryAxis
        tickFormat={(v) => months[v ? v.toString().split('-')[1] : null]}
        tickValues={data.map(item => item.date)}
        tickLabelComponent={<VictoryLabel style={{ fontSize: 8, fill: axisColor || '#FFFFFF' }}/>}
        style={{
          axis: { stroke: axisColor || '#FFFFFF', opacity: '0.9' },
          grid: { stroke: axisColor || '#FFFFFF', opacity: '0.15' },
          ticks: { stroke: axisColor || '#FFFFFF' },
        }}
      />
      <VictoryAxis
        style={{
          axis: { stroke: axisColor || '#FFFFFF', opacity: '0.9' },
          grid: { stroke: axisColor || '#FFFFFF', opacity: '0.15' },
          ticks: { stroke: axisColor || '#FFFFFF' },
        }}
        dependentAxis
        tickFormat={(v) => v < 10000 ? `${v} SEK` : `${v / 1000}K SEK`}
        tickLabelComponent={<VictoryLabel style={{ fontSize: 8, fill: axisColor || '#FFFFFF' }}/>}
      />

      <VictoryGroup offset={10} colorScale={'qualitative'}>
        <VictoryBar
          barWidth={barWidth || 15}
          data={data}
          x='date'
          y='amount'
          style={{
            data: { fill: dataColor || '#FFFFFF', opacity: '0.9', cursor: 'pointer' },
            labels: { fill: labelColor || '#FFFFFF', fontSize: 10, opacity: 0, cursor: 'pointer' }
          }}
          events={[toggleDisplayLabels('data'), toggleDisplayLabels('labels')]}
          labels={({ datum }) => formatMoney(datum.amount, datum.currency, true)}
        />
      </VictoryGroup>
    </VictoryChart>
  )
}
