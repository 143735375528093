import { initialState } from './initial-state';
import {
  REQUEST_ARTICLE_DATA,
  RECEIVE_ARTICLE_DATA,
  INVALIDATE_ARTICLE_DATA,
  RECEIVE_CREATE_ARTICLE,
  RECEIVE_UPDATE_ARTICLE,
  RECEIVE_DELETE_ARTICLE,
} from '../actions/article-action-types';

export default function articleReducer(state = initialState.article, action) {
  switch (action.type) {
    case INVALIDATE_ARTICLE_DATA:
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case REQUEST_ARTICLE_DATA:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case RECEIVE_ARTICLE_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        data: action.articleData,
        lastUpdated: action.receivedAt
      });
    case RECEIVE_CREATE_ARTICLE:
      return Object.assign({}, state, {
        data: [
          ...state.data,
          action.article,
        ]
      });
    case RECEIVE_UPDATE_ARTICLE:
      for (let i = 0; i < state.data.length; i++)
        if (state.data[i].article_number === action.article.article_number) {
          state.data[i] = action.article;
          break;
       }

      return Object.assign({}, state, {
        data: [
          ...state.data,
        ]
      });
    case RECEIVE_DELETE_ARTICLE:

      for (let i = 0; i < state.data.length; i++)
        if (state.data[i].article_number === action.articleNumber) {
          state.data.splice(i, 1);
          break;
       }

      return Object.assign({}, state, {
        data: [
          ...state.data,
        ]
      });
    default:
      return state
  }
}
