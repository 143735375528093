import React from 'react';
import { connect } from 'react-redux'
import { fetchCustomerDataIfNeeded } from '../actions/customers';
import {
  asyncCreateArticle,
  fetchArticleDataIfNeeded,
  asyncDeleteArticle,
  asyncUpdateArticle
} from '../actions/articles';
import {
  fetchFileAttachment,
  deleteFileAttachment,
  uploadFileAttachment,
} from '../services/orders';
import FileUploader from './file-uploader';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Loader from '../common/loader';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import OrderRow from './order-row';
import FormInput from '../common/form-input';
import CustomerForm from '../customers/customer-form.js';
import i18n from '../i18n';
import {
  selectCustomerStyles,
  selectTermsOfPaymentsStyles,
} from '../invoices/invoice-select-styles';
import {
  calculateNetPrice,
  calculateTotalPrice
} from '../invoices/invoice-utils';
import { base64Encode } from '../utils/file-utils';
import { fetchTermsOfPayment } from '../services/terms-of-payment';
import { formatMoney } from '../utils/money-utils';
import { ACTIONS } from '../constants/actions';
import { fetchCustomer, fetchCustomerData } from '../services/customers';

import './order-form.scss';

class OrderForm extends React.Component {
  constructor(props) {
    super(props);

    const defaultOrderDate = new Date(Date.now());

    this.state = {
      createdArticle: null,
      createdArticleAt: null,
      customerNumber: '',
      orderDate: defaultOrderDate,
      deliveryDate: null,
      ourReference: null,
      yourOrderNumber: null,
      rowCount: 1,
      orderRows: [],
      orderRowErrors: {},
      orderErrors: [],
      articleModalOpen: false,
      fileUploadModalOpen: false,
      tempFileAttachment: null,
      fileAttachmentPath: null,
      uploading: false,
      selectedArticle: null,
      updatedArticle: {},
      customerFormOpen: false,
      customerFormAction: null,
      selectedCustomer: null,
      termsOfPayment: null,
      termsOfPaymentOptions: [],
      order: {},
    }

    this.handleAddArticle = this.handleAddArticle.bind(this);
    this.handleDeleteArticle = this.handleDeleteArticle.bind(this);
    this.handleArticleChange = this.handleArticleChange.bind(this);
    this.handleOrderInputChange = this.handleOrderInputChange.bind(this);
    this.handleSelectCustomer = this.handleSelectCustomer.bind(this);
    this.handlefilterCustomer = this.handleFilterCustomer.bind(this);
    this.handleRowInputChange = this.handleRowInputChange.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleClearRow = this.handleClearRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleCustomerForm = this.toggleCustomerForm.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.closeCustomerModal = this.closeCustomerModal.bind(this);
    this.openArticleModal = this.openArticleModal.bind(this);
    this.toggleFileUploadModalOpen = this.toggleFileUploadModalOpen.bind(this);
    this.uploadAttachment = this.uploadAttachment.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
  }

  componentDidMount() {
    const { dispatch, order } = this.props;

    dispatch(fetchCustomerDataIfNeeded());
    dispatch(fetchArticleDataIfNeeded());

    fetchTermsOfPayment()
      .then(response => response.json())
      .then((termsOfPayments) => {
        const options = termsOfPayments.map((payment) => {
          return { label: payment.description, value: payment.code };
        });

        this.setState({ termsOfPaymentOptions: options });

        if (order) {
          const termsOfPayment = this.state.termsOfPaymentOptions.find((option) => {
            return option.value === order.terms_of_payment;
          });

          this.setState({
            orderDate: order.order_date,
            deliveryDate: order.delivery_date,
            orderRows: order.order_rows,
            rowCount: order.order_rows.length,
            customerNumber: order.customer_number,
            ourReference: order.our_reference,
            yourOrderNumber: order.your_order_number,
            termsOfPayment: termsOfPayment ? termsOfPayment.value : 30,
            order: {
              document_number: order.document_number,
            },
            fileAttachmentPath: order.file_attachment_path
          });
        }
      });
  }

  componentDidUpdate(prevProps) {
    // When a new customer is created we wan't to automatically select it in the customer select menu.
    if (this.state.customerFormOpen && prevProps.customers.length < this.props.customers.length) {
      const customer = this.props.customers.filter(c => !prevProps.customers.includes(c))[0];

      this.setState({
        customerNumber: customer.customer_number,
        customerFormOpen: false
      });
    }

    // When a new article is created we wan't to automatically select it in the article select menu.
    if ((prevProps.articles || []).length < (this.props.articles || []).length) {
      const newArticle = this.props.articles.find(article => !prevProps.articles.includes(article));

      const createdRecently = new Date();
      createdRecently.setDate(createdRecently.getDate() - 1);

      if (newArticle && this.state.createdArticleAt > createdRecently) {
        this.setState({ createdArticle: newArticle, createdArticleAt: null });
      }
    }
  }

  handleAddArticle(rowValues, action = ACTIONS.CREATE) {
    delete rowValues.quantity;

    if (action === ACTIONS.UPDATE) {
      this.props.dispatch(asyncUpdateArticle(rowValues, this.state.selectedArticle.article_number));

      const orderRows = [];

      this.state.orderRows.forEach((orderRow) => {
        if (orderRow.articleNumber === rowValues.article_number) {
          const updatedOrderRow = {
            ...orderRow,
            description: rowValues.description,
            unit: rowValues.unit,
            price: rowValues.price,
            vat: rowValues.vat,
            discount: rowValues.discount,
          };

          orderRows.push(updatedOrderRow);
        } else {
          orderRows.push(orderRow);
        }
      })

      return this.setState({
        orderRows: orderRows,
        articleModalOpen: false
      });
    }

    this.setState({ createdArticleAt: Date.now() });

    this.props.dispatch(asyncCreateArticle(rowValues));
  }

  handleDeleteArticle() {
    const {
      selectedArticle,
      orderRows
    } = this.state;

    this.props.dispatch(asyncDeleteArticle(selectedArticle.article_number));

    const updatedOrderRows = orderRows.filter((orderRow) => {
      return orderRow.articleNumber && orderRow.articleNumber !== selectedArticle.article_number;
    });

    this.setState({
      orderRows: updatedOrderRows,
      rowCount: updatedOrderRows.length || 1,
      articleModalOpen: false
    });
  }

  handleArticleChange(article, rowIndex) {
    const orderRows = this.state.orderRows;
    const orderRow = orderRows[rowIndex];
    const newOrderRow = orderRow ? orderRow : {};

    newOrderRow.articleNumber = article.article_number;
    newOrderRow.vat = article.vat;
    newOrderRow.description = article.description;
    newOrderRow.unit = article.unit;
    newOrderRow.price = article.sales_price;
    newOrderRow.deliveredQuantity = 1;

    orderRows[rowIndex] = newOrderRow;

    this.setState({
      orderRows: orderRows,
      orderRowErrors: {
        rowIndex: []
      }
    });
  }

  handleOrderInputChange(field, value) {
    const { orderErrors } = this.state;

    if (orderErrors.includes(field)) {
      const index = orderErrors.indexOf(field);
      orderErrors.splice(index, 1);
    }

    this.setState({ [field]: value, orderErrors: orderErrors });
  }

  handleSelectCustomer(customerNumber) {
    fetchCustomer(customerNumber)
      .then(response => response.json())
      .then(customer => {
        this.setState({
          selectedCustomer: customer,
          customerNumber: customerNumber,
        });
      })
  }

  handleFilterCustomer(query) {
    return fetchCustomerData(query)
      .then(response => response.json())
      .then((customers) => {
        return customers
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((customer) => {
                  return {
                    label: `${customer.name} - ${customer.fortnox_customer_number}`,
                    value: customer.fortnox_customer_number
                  };
                });
      });
  }

  handleRowInputChange(field, value, rowIndex) {
    if (this.state.articleModalOpen) {
      const selectedArticle = this.state.selectedArticle;
      selectedArticle[field] = value;

      this.setState({
        selectedArticle: selectedArticle
      });
    } else {
      const orderRows = this.state.orderRows;
      const orderRow = orderRows[rowIndex];
      const newOrderRow = orderRow ? orderRow : {};

      if (['price', 'deliveredQuantity'].includes(field)) {
        newOrderRow[field] = value.replace(',', '.');
      } else {
        newOrderRow[field] = value;
      }

      newOrderRow.vat = newOrderRow.vat || '25';

      orderRows[rowIndex] = newOrderRow;

      this.setState({
        orderRows: orderRows
      });
    }
  }

  handleRowChange(increment) {
    const { rowCount } = this.state;

    if (!increment) {
      const orderRows = this.state.orderRows;
      const lastRow = orderRows[rowCount];
      orderRows.splice(rowCount - 1, 1);

      this.setState({
        orderRows: orderRows
      });
    }

    const newRowCount = increment ? rowCount + 1 : rowCount - 1;

    this.setState({
      rowCount: newRowCount,
    });
  }

  handleClearRow(rowIndex) {
    const orderRows = this.state.orderRows;
    orderRows.splice(rowIndex, 1);

    this.setState({
      orderRows: orderRows
    })
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      customerNumber,
      orderRows,
      order,
      orderDate,
      deliveryDate,
      ourReference,
      yourOrderNumber,
      termsOfPayment,
      tempFileAttachment,
    } = this.state;

    if (!customerNumber) {
      this.setState({
        orderErrors: ['customerNumber']
      });

      return;
    }


    const errorFields = [];

    if (orderRows.length === 0) {
      this.setState({
        orderRowErrors: {
          0: ['description', 'unit', 'price', 'deliveredQuantity']
        }
      });

      return;
    }

    orderRows.forEach((row, index) => {
      ['description', 'unit', 'price', 'deliveredQuantity', 'vat'].forEach((field) => {
        if (Object.keys(order || {}).length === 0 && !row[field]) {
          errorFields.push(field);
        }
      });

      if (errorFields.length > 0) {
        this.setState({
          orderRowErrors: {
            [index]: errorFields
          }
        });
      }
    });

    if (errorFields.length > 0) {
      return;
    }

    const body = {
      customer_number: customerNumber,
      order_rows: orderRows,
      order_date: orderDate,
      delivery_date: deliveryDate,
      our_reference: ourReference,
      your_order_number: yourOrderNumber,
      terms_of_payment: termsOfPayment,
    }

    if (order) {
      body.document_number = order.document_number;
    }


    this.props.onSubmit && this.props.onSubmit(body, tempFileAttachment);
  }

  openArticleModal(e, articleNumber) {
    e.stopPropagation();

    const article = this.props.articles.find((article) => {
      return article.article_number === articleNumber
    });

    const articleToUpdate = article ? article : {};

    articleToUpdate.articleNumber = article.article_number;
    articleToUpdate.vat = article.vat;
    articleToUpdate.description = article.description;
    articleToUpdate.unit = article.unit;
    articleToUpdate.price = article.sales_price;

    this.setState({
      articleModalOpen: true,
      selectedArticle: articleToUpdate
    });
  }

  toggleCustomerForm(action) {
    this.setState({
      customerFormOpen: !this.state.customerFormOpen,
      customerFormAction: action,
    });
  }

  closeCustomerModal() {
    this.setState({
      customerFormOpen: false,
    });
  }

  toggleFileUploadModalOpen(open) {
    this.setState({
      fileUploadModalOpen: open,
    });
  }

  uploadAttachment(order, attachment) {
    this.setState({
      tempFileAttachment: attachment,
      uploading: true
    });

    if ((order || {}).document_number && attachment) {
      const formData = new FormData();
      formData.append('file', attachment);

      uploadFileAttachment(order.document_number, formData)
        .then((response) => response.json())
        .then((result) => {
          this.props.receiveUploadFileAttachment(result);
          this.setState({
            fileAttachmentPath: result.file_attachment_path,
            tempFileAttachment: null
          });
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          this.setState({
            uploading: false,
          });
        });
    } else {
      setTimeout(() => {
        this.setState({
          uploading: false,
          fileUploadModalOpen: false
        })
      }, 500);
    }
  }

  deleteAttachment(orderNumber, orderFileAttachmentUuid) {
    this.setState({ tempFileAttachment: null });

    if (!orderNumber || !orderFileAttachmentUuid) {
      return;
    }

    deleteFileAttachment(orderNumber, orderFileAttachmentUuid)
      .then((response) => response.json())
      .then((result) => {
        this.props.receiveUploadFileAttachment(result);
        this.setState({ fileAttachmentPath: null })
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  renderRows() {
    const articles = this.props.articles.map((article) => {
      return {
        label: `${article.article_number} - ${article.description}`,
        value: article
      }
    });

    const {
      createdArticle,
      rowCount,
      orderRows,
      orderRowErrors,
      order,
    } = this.state;
    const t = i18n.getFixedT();
    const commonFields = [
      'deliveredQuantity',
      'price',
    ];
    const rows = [];

    for (let i = 0; i < rowCount; i++) {
      rows.push(
        <OrderRow
          index={i}
          articles={articles}
          createdArticle={createdArticle}
          commonFields={commonFields}
          key={i}
          onChange={(field, value) => this.handleRowInputChange(field, value, i)}
          onArticleChange={(article) => this.handleArticleChange(article, i)}
          onAddArticle={this.handleAddArticle}
          removeRow={(i > 0 && i === rowCount - 1) || i > rowCount ? this.handleRowChange : null}
          rowValues={orderRows[i] || {}}
          clearRow={orderRows[i] !== undefined ? this.handleClearRow : null}
          errors={orderRowErrors[i] || []}
          openArticleModal={this.openArticleModal}
        />
      )
    }

    return rows;
  }

  render() {
    const t = i18n.getFixedT();
    const {
      deliveryDate,
      orderDate,
      orderErrors,
      customerFormOpen,
      customerNumber,
      orderRows,
      articleModalOpen,
      fileUploadModalOpen,
      customerFormAction,
      selectedCustomer,
      order,
      termsOfPayment,
      termsOfPaymentOptions,
      ourReference,
      yourOrderNumber,
      tempFileAttachment,
      fileAttachmentPath,
      uploading,
    } = this.state;

    const {
      isFetching,
      lastUpdated,
      customers,
    } = this.props;

    const customerSelectOptions = customers
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((customer) => {
        return {
          label: `${customer.name} - ${customer.fortnox_customer_number}`,
          value: customer.fortnox_customer_number
        };
      });

    if (isFetching) {
      return (
        <Loader
          size={25}
        />
      )
    }

    const formatOptionLabel = ({ value, label }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {label}
        <Icon
          className="customer-settings-icon update-customer-icon"
          onClick={(e) => this.toggleCustomerForm(ACTIONS.UPDATE)}
          title={t('updateCustomer')}
        >
          { 'settings' }
        </Icon>
      </div>
    );

    const SingleValue = ({ ...props }) => {
      return (
        <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
      )
    };

    const netAmount = calculateNetPrice(orderRows);
    const totalAmount = calculateTotalPrice(orderRows);
    const vatAmount = totalAmount - netAmount;

    return (
      <div className="order-form-container">

        {articleModalOpen &&
          <Modal
            open={articleModalOpen}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className="custom-modal modal-form update-article-modal">
              <div className="modal-header">
                <span className="modal-title">{t('updateArticle')}</span>
                <Icon
                  onClick={() => this.setState({ articleModalOpen: false })}
                  style={{
                    margin: '10px 0 5px 4px',
                    float: 'right',
                    cursor: 'pointer'
                  }}
                >
                  clear
                </Icon>
              </div>
              <div className="update-article-content">
                <OrderRow
                  update
                  index={0}
                  rowValues={this.state.selectedArticle}
                  commonFields={['price']}
                  openArticleModal={this.openArticleModal}
                  onChange={(field, value) => this.handleRowInputChange(field, value, 0)}
                  onAddArticle={this.handleAddArticle}
                  onDeleteArticle={this.handleDeleteArticle}
                  errors={[]}
                />
              </div>
            </div>
          </Modal>
        }

        {
          fileUploadModalOpen &&
            <FileUploader
              open={fileUploadModalOpen}
              onUpload={this.uploadAttachment}
              onClose={this.toggleFileUploadModalOpen}
              onDelete={this.deleteAttachment}
              order={order}
              tempFile={tempFileAttachment}
              uploading={uploading}
              orderFileAttachmentPath={fileAttachmentPath}
            />
        }

        {customerFormOpen &&
          <CustomerForm
            action={customerFormAction}
            selectedCustomer={selectedCustomer}
            closeCustomerModal={this.closeCustomerModal}
          />
        }
        <form onSubmit={(event) => this.handleSubmit(event)}>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <AsyncSelect
                components={{ SingleValue }}
                formatOptionLabel={formatOptionLabel}
                styles={selectCustomerStyles(orderErrors.includes('customerNumber'))}
                placeholder={t('customer')}
                defaultOptions={customerSelectOptions}
                loadOptions={this.handleFilterCustomer}
                value={customerSelectOptions.find(opt => opt.value === customerNumber) || ''}
                onChange={(selectedOption) => this.handleSelectCustomer(selectedOption.value)}
              />
              <Icon
                className="customer-settings-icon create-customer-icon"
                onClick={() => this.toggleCustomerForm(ACTIONS.CREATE)}
                title={t('createCustomer')}
              >
                { customerFormOpen && customerFormAction === ACTIONS.CREATE ? 'remove_square' : 'add_square' }
              </Icon>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Grid item xs={12}>
                <FormInput
                  style={{marginTop: 3, marginLeft: -15}}
                  className='order-input'
                  fullWidth={true}
                  placeholder={t('ourReference')}
                  variant="outlined"
                  onChange={(event) => this.handleOrderInputChange('ourReference', event.target.value)}
                  sm={12}
                  value={ourReference}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  style={{marginTop: 3, marginLeft: -15, marginBottom: 3}}
                  className='order-input'
                  fullWidth={true}
                  placeholder={t('yourOrderNumber')}
                  variant="outlined"
                  onChange={(event) => this.handleOrderInputChange('yourOrderNumber', event.target.value)}
                  sm={12}
                  value={yourOrderNumber}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Select
                placeholder={t('termsOfPayment')}
                options={termsOfPaymentOptions}
                onInputChange={this.handleInputChange}
                styles={selectTermsOfPaymentsStyles(false)}
                value={termsOfPaymentOptions.find(opt => opt.value === termsOfPayment) || ''}
                onChange={(selectedOption) => this.handleOrderInputChange('termsOfPayment', selectedOption.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="order-date"
                  label={t('orderDate')}
                  value={orderDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  onChange={(value) => this.handleOrderInputChange('orderDate', value)}
                  style={{ float: 'left', marginTop: 0 }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="delivery-date"
                  label={t('deliveryDate')}
                  value={deliveryDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  onChange={(value) => this.handleOrderInputChange('deliveryDate', value)}
                  style={{ float: 'left', marginTop: 0 }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={1}>
              <div style={{
                float: 'left',
                marginTop: '-2.5px',
                color: '#0000008a',
                fontSize: '12px',
              }}>
                {t('orderAttachment')}
              </div>
              <Icon
                style={{
                  float: 'left',
                  fontSize: '40px',
                  color: '#0000009f',
                  cursor: 'pointer'
                }}
                onClick={() => this.toggleFileUploadModalOpen(true)}
              >
                attachment
              </Icon>
            </Grid>
          </Grid>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                justify="flex-start"
                spacing={2}
                style={{
                  textAlign: 'left',
                  background: '#048c9c',
                  color: '#ffffff',
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                  width: '100%',
                  marginLeft: 1,
                }}
              >
                <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 11}}>
                  {t('articleNo')}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} style={{paddingLeft: 13}}>
                  {t('designation')}
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 19}}>
                  {t('unit')}
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 19}}>
                  {t('quantity')}
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 19}}>
                  {t('price')}
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 19}}>
                  {t('vat')}
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={1} style={{paddingLeft: 19}}>
                  {t('discount')}
                </Grid>
              </Grid>
            </Grid>
            {this.renderRows()}
          </Grid>
          <Grid container justify="flex-start" spacing={2}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              {!order.invoice_reference ?
                <React.Fragment>
                  <Button
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      background: '#66bb6a',
                      margin: '10px 5px 5px 0',
                      float: 'left',
                    }}
                    onClick={() => this.handleRowChange(true)}
                  >
                    {t('addRow')}
                  </Button>

                  <Button
                    type="submit"
                    style={{
                      color: '#FFFFFF',
                      fontSize: 12,
                      background: '#66bb6a',
                      margin: '10px 0 5px 0',
                      float: 'left',
                    }}
                  >
                    {t('save')}
                  </Button>
                </React.Fragment>
                : null
              }
            </Grid>
            {totalAmount && netAmount && vatAmount ?
              // TODO: Fetch customers/show on select customer and get currency.
              <React.Fragment>
                <Grid item xs={4} sm={4} md={2} lg={2}>
                  <p>{t('netPrice')}: {formatMoney(netAmount, 'SEK')}</p>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2}>
                  <p>{t('vat')}: {formatMoney(vatAmount, 'SEK')}</p>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2}>
                  <p>{t('total')}: {formatMoney(totalAmount, 'SEK')}</p>
                </Grid>
              </React.Fragment>
            : null }
          </Grid>
        </form>
      </div>
    )
  }
}

OrderForm.propTypes = {
  articles: PropTypes.array.isRequired,
  customers: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { customerData, articleData } = state;
  const customers = customerData.data || [];
  const articles = articleData.data || [];
  const isFetching = articleData.isFetching === undefined &&
                     customerData.isFetching === undefined ? true
                     : (articleData.isFetching || customerData.isFetching);

  return {
    isFetching,
    customers,
    articles,
  }
}

export default connect(mapStateToProps)(OrderForm)
