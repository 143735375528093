import React from 'react';
import { useState, useEffect } from 'react';
import i18n from '../i18n';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Grid, TextField } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Loader from '../common/loader';
import { extractUuid } from '../utils/file-utils';

import './file-uploader.scss';

const maxFileSizeInMegaBytes = 5;

export default class FileUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      fileName: '',
      companyName: '',
    }

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.renderPdf = this.renderPdf.bind(this);
    this.renderImage = this.renderImage.bind(this);
    this.renderFilePreview = this.renderFilePreview.bind(this);
  }

  onDocumentLoadSuccess(doc, index) {
    const files = this.state.files;
    files[index].numPages = doc.numPages;
    this.setState({ files: files });
  }

  handleChange(event) {
    const newFiles = event.target.files;
    const files = this.state.files;

    for (let i = 0; i < newFiles.length; i++) {
      const sizeInMb = newFiles[i].size / 1024 / 1024;
      if (sizeInMb > maxFileSizeInMegaBytes) {
        files.push({
          error: {
            fileName: newFiles[i].name,
            messageKey: 'validations.fileTooLarge',
          }
        })
      } else {
        files.push({
          file: newFiles[i],
          contentType: newFiles[i].type
        });
      }
    }

    this.setState({ files: files });
  }

  handleSubmit() {
    const { onUpload } = this.props;

    if (this.state.files.some(file => file.hasOwnProperty('error'))) {
      const errorElement = document.getElementsByClassName('file-validation-error')[0];
      if (errorElement) errorElement.scrollIntoView();
      return;
    }

    onUpload && onUpload(this.state.files, this.state.fileName);

    this.setState({files: []})
  }

  handleDelete(index) {
    const files = this.state.files;
    files.splice(index, 1);
    this.setState({ files: files });
  }

  handleClose() {
    const { onClose } = this.props;

    this.setState({ files: [] });

    onClose && onClose(false);
  }

  renderActions() {
    const t = i18n.getFixedT();
    const { loading } = this.props;

    if (loading) {
      return (
        <Loader
          style={{marginLeft: 10}}
          size={30}
        />
      )
    }

    if (this.state.files.length === 0) {
      return null;
    }

    return (
      <Button
        onClick={this.handleSubmit}
        style={{
          color: '#FFFFFF',
          fontSize: 12,
          background: '#66bb6a',
          float: 'left',
          padding: '10px',
          margin: '-6px 0 0 -20px'
        }}
      >
        {t('save')}
      </Button>
    )
  }

  renderPdf(file, index) {
    const { files, pageNumber, numPages } = this.state;

    return (
      <React.Fragment>
        <Document
          file={file}
          onLoadSuccess={(doc) => this.onDocumentLoadSuccess(doc, index)}
        >
          <Page
            height={600}
            width={600}
            pageNumber={1}
          />
        </Document>
        <p>Sida 1 av {files[index].numPages}</p>
      </React.Fragment>
    )
  }

  renderImage(file) {
    const fileReader = new FileReader();

    fileReader.onload = function(){
      const imgPreview = document.getElementById(file.name);
      // if (imgPreview) {
        imgPreview.src = fileReader.result;
      // }
    };

    fileReader.readAsDataURL(file);

    return (
      <img id={file.name} width={600}/>
    )
  }

  renderFilePreview(file, contentType, index) {
    const t = i18n.getFixedT();

    if (file.error) {
      return (
        <div className="file-validation-error">
          <p>{`${file.error.fileName}: ${t(file.error.messageKey)}`}</p>
        </div>
      )
    }

    if (!file.file) {
      return;
    }

    if (contentType === 'application/pdf') {
      return this.renderPdf(file.file, index);
    }

    if (contentType === 'image/png' || contentType === 'image/jpeg') {
      return this.renderImage(file.file)
    }
  }

  render() {
    const t = i18n.getFixedT();
    const {
      open,
      multiple,
      loading,
      title,
      includeFileNameInput,
      includeCompanyName,
    } = this.props;

    const { files } = this.state;

    return (
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="custom-modal">
          <div className="modal-header">
            <span className="modal-title">{title || t(`uploadAttachment`)}</span>
            <Icon
              onClick={this.handleClose}
              style={{
                margin: '10px 0 5px 4px',
                float: 'right',
                cursor: 'pointer'
              }}
            >
              clear
            </Icon>
          </div>
          <div className="modal-body">
            <Grid container>
              {loading ?
                <Loader
                  size={100}
                  style={{margin: '0 auto'}}
                />
              :
                <React.Fragment>
                  {includeFileNameInput &&
                    <Grid item xs={12} style={{margin: '5px 0 15px 0'}}>
                      <TextField
                        fullWidth={true}
                        label='Filnamn'
                        variant='outlined'
                        onChange={(event) => this.setState({ fileName: event.target.value })}
                        value={this.state.fileName}
                      />
                    </Grid>
                  }
                  {includeCompanyName &&
                    <Grid item xs={12} style={{margin: '5px 0 15px 0'}}>
                      <TextField
                        fullWidth={true}
                        label="Företagsnamn"
                        variant='outlined'
                        onChange={(event) => this.setState({ companyName: event.target.value })}
                        value={this.state.companyName}
                      />
                    </Grid>
                  }
                  <Grid item xs={2} style={{margin: '5px 0 15px 0'}}>
                    <input
                      onChange={(event) => this.handleChange(event)}
                      type="file"
                      id="file-upload"
                      accept="application/pdf,image/png,image/jpeg,.doc,.docx,.skv,.xlsx"
                      hidden
                      multiple={multiple}
                    />
                    <label htmlFor="file-upload" className="choose-file-label">
                      {t('chooseFile')}
                    </label>
                  </Grid>

                  <Grid item xs={10}>
                    { this.renderActions() }
                  </Grid>

                  <Grid item xs={12}>
                    <div className="file-preview-container">
                      {
                        files.map((file, i) =>
                          <div className="file-preview-row" key={i}>
                            { this.renderFilePreview(file, file.contentType, i) }
                            <Button variant="outlined" onClick={() => this.handleDelete(i)}>
                              {t('remove')}
                            </Button>
                          </div>
                        )
                      }
                    </div>
                  </Grid>
                </React.Fragment>
              }
            </Grid>
          </div>
        </div>
      </Modal>
    );
  }
}

