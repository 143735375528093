import React from 'react';
import { useState } from 'react';
import { oauth, initiateOauth } from '../services/authentication';
import auth from '../authentication/authentication';
import { useSearchParams } from "react-router-dom";
import { useLocation, Redirect } from "react-router-dom";

export default function Oauth(props) {
  const [authUrl, setAuthUrl] = useState(null);
  const [authCodeReceived, setAuthCodeReceived] = useState(false);
  const [receivingAuthCode, setReceivingAuthCode] = useState(false);
  const [authError, setAuthError] = useState(false);
  const { location } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const authCode = urlParams.get('code');

  if (authCode && !authCodeReceived && !receivingAuthCode) {
    setReceivingAuthCode(true);
    oauth(authCode, auth.getCompanyId())
      .then(resp => resp.json())
      .then((data) => {
        auth.setOauthToken(data.access_token);
        auth.setOauthTokenExpiresAt(data.expires_at);
        setAuthCodeReceived(true);
      })
      .catch(() => {
        setAuthError(true);
      }, []);
  }

  if (authCodeReceived ||
    auth.getOauthToken() !== undefined &&
    auth.getOauthToken() !== null &&
    auth.getOauthToken() != '') {
    props.onAuthSuccess();

    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location }
        }}
      />
    )
  }

  if (authError) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location }
        }}
      />
    )
  }

  if (!authUrl && !authCode) {
    initiateOauth()
      .then(resp => resp.json())
      .then((data) => {
        setAuthUrl(data.url);
      })
  }

  if (authUrl) {
    window.location.href = authUrl;
  }

  return null;
}
