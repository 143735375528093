import React from 'react';
import {
  Grid,
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import './invoice-input.scss';

export default function InvoiceInput(props) {
  const {
    error,
    fullWidth,
    label,
    lg,
    md,
    multiline,
    onChange,
    placeholder,
    rows,
    sm,
    style,
    value,
    variant,
  } = props;

  const inputClasses = classNames({
    'invoice-input': true,
    'invoice-input-error': error
  });

  return (
    <Grid item xs={12} sm={sm} md={md} lg={lg}>
      <TextField
        className={inputClasses}
        fullWidth={fullWidth}
        label={label}
        multiline={multiline || false}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows || 1}
        style={Object.keys(style || {}).length > 0 ? style : {marginLeft: 4}}
        value={value || ''}
        variant={variant}
      />
    </Grid>
  )
}
