class Settings {
  getInvoiceCustomerType() {
    return localStorage.getItem('AR_INVOICE_CUSTOMER_TYPE');
  }

  setInvoiceCustomerType(type) {
    localStorage.setItem('AR_INVOICE_CUSTOMER_TYPE', type);
  }
}

const settings = new Settings();
export default settings;
