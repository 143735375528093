import {
  REQUEST_INVOICE_DATA,
  RECEIVE_INVOICE_DATA,
  INVALIDATE_INVOICE_DATA,
  RECEIVE_CREATE_INVOICE,
  RECEIVE_UPDATE_INVOICE,
  RECEIVE_CREATE_INVOICE_PAYMENT,
  BOOKKEEP_INVOICE,
  RECEIVE_BOOKKEEP_INVOICE,
} from './invoice-action-types';

import {
  fetchInvoiceData,
  createInvoice,
  updateInvoice,
  createInvoicePayment,
  bookkeepInvoice,
  fetchInvoicePdf,
  cancelInvoice,
  creditInvoice,
  emailInvoice,
} from '../services/invoices';

function receiveInvoiceData(invoiceData) {
  return {
    type: RECEIVE_INVOICE_DATA,
    invoiceData: invoiceData.data,
    meta: invoiceData.meta,
    receivedAt: Date.now(),
  }
}

function requestInvoiceData() {
  return {
    type: REQUEST_INVOICE_DATA
  }
}

export function invalidateInvoiceData() {
  return {
    type: INVALIDATE_INVOICE_DATA
  }
}

export function asyncFetchInvoiceData(page) {
  return function (dispatch, getState) {
    dispatch(requestInvoiceData());

    return fetchInvoiceData(page)
      .then(response => response.json())
      .then(json => dispatch(receiveInvoiceData(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function shouldfetchInvoiceData(state, page) {
  const invoiceData = state.invoiceData;
  if (!invoiceData) {
    return true;
  }

  if (invoiceData.isFetching) {
    return false;
  }

  if ((invoiceData.meta || {}).current_page != page) {
    return true;
  }

  return invoiceData.didInvalidate;
}

export function fetchInvoiceDataIfNeeded(page) {
  return (dispatch, getState) => {
    if (shouldfetchInvoiceData(getState(), page)) {
      return dispatch(asyncFetchInvoiceData(page));
    }
  }
}

export function receiveCreateInvoice(invoice) {
  return {
    type: RECEIVE_CREATE_INVOICE,
    invoice: invoice,
    receivedAt: Date.now(),
  }
}

export function asyncCreateInvoice(body) {
  return function (dispatch, getState) {
    createInvoice(body)
      .then(response => response.json())
      .then(json => dispatch(receiveCreateInvoice(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

export function asyncUpdateInvoice(body) {
  return function (dispatch, getState) {
    updateInvoice(body)
      .then(response => response.json())
      .then(json => dispatch(receiveUpdateInvoice(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function receiveUpdateInvoice(invoice) {
  return {
    type: RECEIVE_UPDATE_INVOICE,
    invoice: invoice,
    receivedAt: Date.now(),
  }
}

export function asyncCreateInvoicePayment(body) {
  return function (dispatch, getState) {
    createInvoicePayment(body)
      .then(response => response.json())
      .then(json => dispatch(receiveCreateInvoicePayment(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function receiveCreateInvoicePayment(invoicePayment) {
  return {
    type: RECEIVE_CREATE_INVOICE_PAYMENT,
    invoicePayment: invoicePayment,
  }
}

export function asyncBookkeepInvoice(invoiceNumber) {
  return function (dispatch, getState) {
    bookkeepInvoice(invoiceNumber)
      .then(response => response.json())
      .then(json => dispatch(receiveBookkeepInvoice(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function receiveBookkeepInvoice(invoice) {
  return {
    type: RECEIVE_BOOKKEEP_INVOICE,
    invoice: invoice,
    receivedAt: Date.now(),
  }
}

export function asyncCancelInvoice(invoiceNumber) {
  return function (dispatch, getState) {
    cancelInvoice(invoiceNumber)
      .then(response => response.json())
      .then(json => dispatch(receiveUpdateInvoice(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

export function asyncCreditInvoice(invoiceNumber) {
  return function (dispatch, getState) {
    creditInvoice(invoiceNumber)
      .then(response => response.json())
      .then((json) => {
        dispatch(receiveUpdateInvoice(json))
        dispatch(receiveCreateInvoice(json.credit_invoice_reference))
      })
      // .catch(error => console.log("error: ", error)))
  }
}

export function asyncEmailInvoice(invoiceNumber) {
  return function (dispatch, getState) {
    emailInvoice(invoiceNumber)
      .then(response => response.json())
      .then(json => dispatch(receiveUpdateInvoice(json)))
      // .catch(error => console.log("error: ", error)))
  }
}
