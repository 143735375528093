import React from 'react';
import { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import Icon from '@material-ui/core/Icon';
import Loader from '../common/loader';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import i18n from '../i18n';
import {
  fetchInvoicePdf,
  fetchInvoice,
  printInvoice,
} from '../services/invoices';
import InvoiceForm from './invoice-form';
import InvoiceEmailForm from './invoice-email-form';
import { format } from 'date-fns'
import { formatMoney } from '../utils/money-utils';

import './invoice-list.scss';

export default function InvoiceList(props) {
  const t = i18n.getFixedT();
  const {
    data,
    submitInvoicePayment,
    bookkeepInvoice,
    updateInvoice,
    cancelInvoice,
    emailInvoice,
    creditInvoice,
    isFetching,
  } = props;

  const [invoicePaymentFormOpen, setInvoicePaymentFormOpen] = useState(false);
  const [invoiceForPayment, setInvoiceForPayment] = useState(null);
  const [invoicePaymentDate, setInvoicePaymentDate] = useState(Date.now());
  const [bookkeepInvoiceCallback, setBookkeepInvoiceCallback] = useState(null);
  const [sendInvoiceModalOpen, setSendInvoiceModalOpen] = useState(false);
  const [invoiceToSend, setInvoiceToSend] = useState(null);
  const [invoiceToEdit, setInvoiceToEdit] = useState(null);

  useEffect(() => {
    data.map((invoice) => {
      if (invoice && invoice.updated && invoiceForPayment && invoiceForPayment.document_number === invoice.document_number) {
        setTimeout(() => {
          setInvoiceForPayment(null);
        }, 1000);
      }

      if (invoice && invoice.updated && invoice.booked && invoiceToSend && invoiceToSend.document_number === invoice.document_number) {
        bookkeepInvoiceCallback && bookkeepInvoiceCallback.func(invoiceToSend.document_number);
        setBookkeepInvoiceCallback(null);

        setSendInvoiceModalOpen(false);
      }
    })
  });

  const toggleInvoicePaymentForm = (invoice) => {
    if (invoicePaymentFormOpen) {
      setInvoiceForPayment(null);
    } else {
      setInvoiceForPayment(invoice);
    }

    setInvoicePaymentFormOpen(!invoicePaymentFormOpen);
  }

  const toggleEditInvoiceForm = (invoice) => {
    if (invoiceToEdit) {
      setInvoiceToEdit(null);
    } else {
      fetchInvoice(invoice.document_number)
        .then(response => response.json())
        .then(json => setInvoiceToEdit(json))
        .catch(error => console.log("error: ", error));
    }
  }

  const toggleSendInvoiceForm = (invoice, open) => {
    setInvoiceToSend(invoice);

    setSendInvoiceModalOpen(open);
  }

  const resolveStatus = (invoice) => {
    if (invoiceForPayment && invoiceForPayment.updating && invoice.document_number === invoiceForPayment.document_number) {
      return <Loader size={30} />
    }

    if (invoice.cancelled) {
      return t('cancelled');
    }

    return invoice.paid ? t('paid') : t('unpaid');
  }

  const resolveStatusColor = (invoice) => {
    if (invoice.cancelled) {
      return { color: '#aaaaaa' }
    }

    if (Date.parse(invoice.due_date) > Date.now() && !invoice.paid) {
      return { color: '#e7ea0f' };
    }

    return invoice.paid ? { color: "#66bb6a" } : { color: "#e53935" }
  }

  const handleInvoicePaymentDateChange = (date) => {
    const invoicePayment = invoiceForPayment;
    invoicePayment.paymentDate = date;
    setInvoicePaymentDate(date);
    setInvoiceForPayment(invoicePayment);
  }

  const handleUpdateInvoice = (invoice) => {
    updateInvoice(invoice);

    setTimeout(() => {
      setInvoiceToEdit(null);
    }, 500);
  }

  const handleCreditInvoice = (invoiceNumber) => {
    creditInvoice(invoiceNumber);

    setInvoiceToEdit(null);
  }

  const handleCancelInvoice = (invoiceNumber) => {
    if (window.confirm(t('confirms.deleteInvoice'))) {
      cancelInvoice(invoiceNumber)
    }
  }

  const handleSubmitPayment = () => {
    const data = {
      document_number: invoiceForPayment.document_number,
      amount: invoiceForPayment.total,
      payment_date: invoiceForPayment.paymentDate || format(Date.now(), 'yyyy-MM-dd'),
    }

    invoiceForPayment.updating = true;
    setInvoiceForPayment(invoiceForPayment);
    setInvoicePaymentFormOpen(false);

    submitInvoicePayment(data);
  }

  const handleFinalizeInvoice = (invoice, deliveryAction) => {
    bookkeepInvoice(invoice.document_number);

    setBookkeepInvoiceCallback(deliveryAction);
  }

  const renderBookkeepButton = (invoice) => {
    if (invoiceForPayment && invoiceForPayment.updatingBookkeeping && invoice.document_number === invoiceForPayment.document_number) {
      return <Loader size={30} style={{float: 'right', marginRight: 35}} />
    }

    return <Button
      style={{
        color: '#FFFFFF',
        fontSize: 14,
        background: '#66bb6a',
        float: 'right',
        marginRight: 5,
      }}
      onClick={() => toggleSendInvoiceForm(invoice, true)}
    >
      {t('bill')}
    </Button>
  }

  return (
    <React.Fragment>
      {sendInvoiceModalOpen &&
        <InvoiceEmailForm
          open={sendInvoiceModalOpen}
          onSubmit={handleFinalizeInvoice}
          onClose={toggleSendInvoiceForm}
          onEmail={() => handleFinalizeInvoice(invoiceToSend, { func: emailInvoice })}
          onPrint={() => handleFinalizeInvoice(invoiceToSend, { func: printInvoice })}
          invoiceNumber={invoiceToSend.document_number}
        />
      }

      <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
        <Table aria-label="dashboard table">
          <TableHead style={{background: 'linear-gradient(60deg, #66bb6a, #3f9e43)'}}>
            <TableRow>
              <TableCell style={{ width: '14%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('customer')}</TableCell>
              <TableCell style={{ width: '9%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('invoiceNumber')}</TableCell>
              <TableCell style={{ width: '9%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('invoiceDate')}</TableCell>
              <TableCell style={{ width: '9%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('dueDate')}</TableCell>
              <TableCell style={{ width: '9%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('status')}</TableCell>
              <TableCell style={{ width: '9%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('createdBy')}</TableCell>
              <TableCell style={{ width: '9%', fontWeight: 'bold', color: '#FFFFFF' }} align="left">{t('amount')}</TableCell>
              <TableCell style={{ width: '9%', fontWeight: 'bold', color: '#FFFFFF' }} align="right">{t('openPdf')}</TableCell>
              <TableCell align="right" style={{ width: '25%' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ?
              <TableRow>
                <TableCell style={{height: '100vh'}}>
                  <Loader
                    style={{
                      position: 'absolute',
                      top: '35%',
                      left: '40%'
                    }}
                  />
                </TableCell>
              </TableRow>
            :
              data.map(invoice => (
                <React.Fragment key={invoice.document_number}>
                  <TableRow>
                    <TableCell
                      style={{
                        color: invoice.cancelled ? '#aaaaaa' : '#000000',
                        maxWidth: '190px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}
                      component="th"
                      scope="row"
                    >
                      {invoice.customer_name}
                    </TableCell>
                    <TableCell style={{color: invoice.cancelled ? '#aaaaaa' : '#000000'}} align="left">{invoice.document_number}</TableCell>
                    <TableCell style={{color: invoice.cancelled ? '#aaaaaa' : '#000000'}} align="left">{invoice.invoice_date}</TableCell>
                    <TableCell
                      align="left"
                      style={resolveStatusColor(invoice)}
                    >
                      {invoice.due_date}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={resolveStatusColor(invoice)}
                    >
                      {resolveStatus(invoice)}
                    </TableCell>
                    <TableCell
                      style={{
                        color: invoice.cancelled ? '#aaaaaa' : '#000000',
                        maxWidth: '170px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}
                      align="left"
                    >
                      {invoice.created_by}
                    </TableCell>
                    <TableCell style={{color: invoice.cancelled ? '#aaaaaa' : '#000000'}} align="left">{formatMoney(invoice.total, invoice.currency, true)}</TableCell>
                    <TableCell align="right">
                      {!invoice.cancelled &&
                        <Icon
                          style={{marginRight: 25, cursor: 'pointer'}}
                          onClick={() => fetchInvoicePdf(invoice.document_number)}
                        >
                          description
                        </Icon>
                      }
                    </TableCell>
                    <TableCell align="right">
                      {!invoice.booked && !invoice.paid && !invoice.cancelled &&
                        <Icon
                          style={{background: '#e53935', fontSize: '35px'}}
                          className="invoice-action-icon"
                          onClick={() => handleCancelInvoice(invoice.document_number)}
                        >
                          delete
                        </Icon>
                      }
                      {!invoice.cancelled && (invoice.credit && !invoice.booked || !invoice.credit) &&
                        <Icon
                          style={{background: '#ffa726', fontSize: '35px'}}
                          className="invoice-action-icon"
                          onClick={() => toggleEditInvoiceForm(invoice)}
                        >
                          { invoiceToEdit && invoice.document_number === invoiceToEdit.document_number ? 'remove_square' : 'edit' }
                        </Icon>
                      }
                      {
                        // TODO: Comment out invoice payment button until we figure out if we want it.
                        // invoice.booked && !invoice.paid && !invoice.cancelled &&
                        // <Button
                        //   style={{
                        //     color: '#FFFFFF',
                        //     fontSize: 14,
                        //     background: '#66bb6a',
                        //     float: 'right',
                        //     marginRight: '3px'
                        //   }}
                        //   onClick={() => toggleInvoicePaymentForm(invoice)}
                        // >
                        //   <Icon
                        //     className="invoice-payment-icon"
                        //   >
                        //     { invoicePaymentFormOpen && invoiceForPayment && invoiceForPayment.document_number === invoice.document_number ? 'remove_square' : 'add_square' }
                        //   </Icon>
                        //   {t('invoicePayment')}
                        // </Button>
                      }
                      {!invoice.cancelled && !invoice.booked &&
                        renderBookkeepButton(invoice)
                      }
                      {invoicePaymentFormOpen && invoiceForPayment && invoiceForPayment.document_number === invoice.document_number &&
                        // TODO: Vill vi hantera att kunna öppna/stänga flera/enskilda, just nu är ju invoicePaymentFormOpen för hela och inte enskild rad.
                        <div className="invoice-payment-form-container">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="invoice-payment-date-picker"
                              disableToolbar
                              variant="inline"
                              format="yyyy-MM-dd"
                              margin="dense"
                              id="invoice-date"
                              label={t('paymentDate')}
                              value={invoicePaymentDate}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              onChange={(value) => handleInvoicePaymentDateChange(value)}
                              style={{ background: '#e5e5e5', zIndex: 999, float: 'left', marginTop: 0, padding: 5 }}
                            />
                          </MuiPickersUtilsProvider>
                          <Button
                            style={{
                              color: '#FFFFFF',
                              fontSize: 12,
                              background: '#66bb6a',
                              float: 'right',
                              marginTop: 18,
                            }}
                            onClick={() => handleSubmitPayment()}
                          >
                            {t('save')}
                          </Button>
                        </div>
                      }
                    </TableCell>
                  </TableRow>
                  {!invoice.cancelled && invoiceToEdit && invoiceToEdit.document_number === invoice.document_number &&
                    <TableRow>
                      <TableCell style={{width: '100%'}} colSpan="10">
                        <InvoiceForm
                          invoice={invoiceToEdit}
                          onSubmit={handleUpdateInvoice}
                          creditInvoice={handleCreditInvoice}
                          onUpdateTaxReduction={props.onUpdateTaxReduction}
                          onCreateTaxReduction={props.onCreateTaxReduction}
                          fetchTaxReductionByInvoice={props.fetchTaxReductionByInvoice}
                          taxReduction={props.taxReduction}
                          updateTaxReduction={true}
                        />
                      </TableCell>
                    </TableRow>
                  }
                </React.Fragment>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}
