export function extractUuid(filePath) {
  const parts = filePath.split('/');
  return parts[parts.length-1];
}

export function base64Encode(file)  {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
