import React from 'react';
import { useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';

import {
  presentFinancialYearWithMonth,
} from '../utils/financial-year-utils';

import i18n from '../i18n';

export default function FinancialYears(props) {
  const {
    selectFinancialYear,
    selectedFinancialYear,
    financialYears,
    disabled,
  } = props;
  const t = i18n.getFixedT();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    if (disabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleSelectFinancialYear = (financialYear) => {
    if (disabled) {
      return;
    }
    setAnchorEl(null);

    if (financialYear === selectedFinancialYear) {
      return;
    }

    selectFinancialYear(financialYear);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="companies-menu-container">
      { financialYears && financialYears.length > 1 ?
        <React.Fragment>
          <Button
            color="inherit"
            onClick={handleOpenMenu}
            aria-controls="companies-menu"
            aria-haspopup="true"
            style={{fontWeight: 'bold', fontSize: 16, marginTop: 2}}
          >
            {presentFinancialYearWithMonth(selectedFinancialYear)}
          </Button>
            <Menu
              id="companies-menu"
              className="companies-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{marginTop: 30}}
            >
              {
                financialYears.map(financialYear =>
                  <MenuItem
                    key={financialYear.id}
                    onClick={() => handleSelectFinancialYear(financialYear)}
                  >
                    {`${financialYear.from_date} - ${financialYear.to_date}`}
                  </MenuItem>
                )
              }
            </Menu>
        </React.Fragment>
        : <Button
            color="inherit"
            style={{fontWeight: 'bold', fontSize: 16, marginTop: 2}}
          >
            {presentFinancialYearWithMonth(selectedFinancialYear)}
          </Button>
      }
    </div>
  );
}
