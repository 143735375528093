import React from 'react';
import { connect } from 'react-redux'
import {
  fetchOrderDataIfNeeded,
  asyncFetchOrderData,
  asyncCreateOrder,
  asyncCreateOrderInvoice,
  asyncUpdateOrder,
  asyncCancelOrder,
  asyncEmailOrder,
  invalidateOrderData,
  receiveUpdateOrder,
} from '../actions/orders';
import {
  asyncBookkeepInvoice,
  asyncEmailInvoice,
} from '../actions/invoices';
import { receiveCreateInvoice } from '../actions/invoices';
import { receiveCreateOrderInvoice } from '../actions/orders';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Loader from '../common/loader';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import OrderList from './order-list';
import OrderForm from './order-form';
import i18n from '../i18n';
import classNames from 'classnames';

import './orders.scss';

class Orders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderFormOpen: false,
      orders: [],
      filter: 'invoicenotcreated',
      pagination: {
        page: 1,
        totalPages: 1
      }
    }

    this.toggleOrderFormOpen = this.toggleOrderFormOpen.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.createOrderInvoice = this.createOrderInvoice.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.emailOrder = this.emailOrder.bind(this);
    this.emailInvoice = this.emailInvoice.bind(this);
    this.bookkeepInvoice = this.bookkeepInvoice.bind(this);
    this.receiveCreateInvoice = this.receiveCreateInvoice.bind(this);
    this.receiveCreateOrderInvoice = this.receiveCreateOrderInvoice.bind(this);
    this.receiveUploadFileAttachment = this.receiveUploadFileAttachment.bind(this);
    this.filterOrders = this.filterOrders.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    const { dispatch, data, meta } = this.props;
    const { filter, pagination } = this.state;

    dispatch(fetchOrderDataIfNeeded(filter, pagination.page));

    this.setState({
      orders: data,
      pagination: {
        ...this.state.pagination,
        totalPages: meta.total_pages
      }
    });
  }

  componentDidUpdate(prevProps) {
    const {
      data,
      meta,
      didInvalidate,
      dispatch,
      lastUpdated,
    } = this.props;
    const { filter, pagination } = this.state;

    if (prevProps.lastUpdated !== lastUpdated) {
      // Fetch order data when changing company
      const newOrders = data.sort((a, b) => {
        const docNumA = parseInt(a.document_number);
        const docNumB = parseInt(b.document_number);

        if (docNumA > docNumB) return -1;
        if (docNumB > docNumA) return 1;

        return 0;
      });

      this.setState({
        orders: newOrders,
        pagination: {
          ...this.state.pagination,
          totalPages: meta.total_pages
        }
      });
    }

    if (didInvalidate && prevProps.didInvalidate !== didInvalidate) {
      dispatch(fetchOrderDataIfNeeded(filter, pagination.page));

      this.setState({
        orders: data,
        pagination: {
          ...this.state.pagination,
          totalPages: meta.total_pages
        }
      });
    }
  }

  toggleOrderFormOpen() {
    this.setState({
      toggleOrderFormOpen: !this.state.toggleOrderFormOpen
    });
  }

  createOrder(order, fileAttachment) {
    this.props.dispatch(asyncCreateOrder(order, fileAttachment));

    setTimeout(() => {
      this.setState({
        toggleOrderFormOpen: !this.state.toggleOrderFormOpen
      });
    }, 500);
  }

  createOrderInvoice(orderNumber) {
    this.props.dispatch(asyncCreateOrderInvoice(orderNumber));
  }

  updateOrder(order) {
    this.props.dispatch(asyncUpdateOrder(order));
  }

  cancelOrder(orderNumber) {
    this.props.dispatch(asyncCancelOrder(orderNumber));
  }

  emailOrder(orderNumber) {
    this.props.dispatch(asyncEmailOrder(orderNumber));
  }

  emailInvoice(invoiceNumber) {
    this.props.dispatch(asyncEmailInvoice(invoiceNumber));
  }

  bookkeepInvoice(invoiceNumber) {
    this.props.dispatch(asyncBookkeepInvoice(invoiceNumber));
  }

  receiveCreateInvoice(invoice) {
    this.props.dispatch(receiveCreateInvoice(invoice));
  }

  receiveCreateOrderInvoice(order) {
    this.props.dispatch(receiveCreateOrderInvoice(order));
  }

  receiveUploadFileAttachment(order) {
    this.props.dispatch(receiveUpdateOrder(order));
  }

  filterOrders(filter) {
    const { dispatch } = this.props;

    this.setState({ filter: filter });

    // TODO: Do not invalidate data each time,
    // scope it under each filter to avoid unnecessary requests.
    dispatch(invalidateOrderData());

    dispatch(fetchOrderDataIfNeeded(filter, this.state.pagination.page));
  }

  handlePageChange(page) {
    const { filter, pagination } = this.state;
    this.setState({
      pagination: {
        ...this.state.pagination,
        page: page
      }
    }, () => {
      this.props.dispatch(
        fetchOrderDataIfNeeded(filter, this.state.pagination.page)
      )
    })
  };

  render() {
    const t = i18n.getFixedT();
    const { toggleOrderFormOpen, orders, filter, pagination } = this.state;
    const { isFetching, lastUpdated } = this.props;
    const btnSelectedClass = classNames({'btn-selected': filter === 'invoicenotcreated'});

    return (
      <Grid container justify="center">
        <Grid item xs={11}>
          <Button
            style={{
              color: '#FFFFFF',
              fontSize: 14,
              background: '#66bb6a',
              marginBottom: 5,
              float: 'left',
            }}
            onClick={this.toggleOrderFormOpen}
          >
            {toggleOrderFormOpen ? t('cancel') : t('createOrder')}
          </Button>
          <Pagination
            style={{marginTop: '2px'}}
            variant="outlined"
            shape="rounded"
            onChange={(_, page) => this.handlePageChange(page)}
            page={pagination.page}
            count={pagination.totalPages}
          />
        </Grid>
        <Grid item xs={11}>
          { toggleOrderFormOpen &&
            <OrderForm
              onSubmit={this.createOrder}
              receiveUploadFileAttachment={this.receiveUploadFileAttachment}
            />
          }
        </Grid>
        <Grid item xs={11}>
          <ButtonGroup style={{float: 'left'}} variant="text" aria-label="text primary button group">
            <Button
              className={classNames({'btn-selected': filter === 'invoicenotcreated'})}
              onClick={() => this.filterOrders('invoicenotcreated')}
            >
              {t('notBilled')}
            </Button>
            <Button
              className={classNames({'btn-selected': filter === 'invoicecreated'})}
              onClick={() => this.filterOrders('invoicecreated')}
            >
              {t('billed')}
            </Button>
            <Button
              className={classNames({'btn-selected': filter === ''})}
              onClick={() => this.filterOrders('')}
            >
              {t('all')}
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={11}>
          <OrderList
            updateOrder={this.updateOrder}
            cancelOrder={this.cancelOrder}
            emailOrder={this.emailOrder}
            emailInvoice={this.emailInvoice}
            bookkeepInvoice={this.bookkeepInvoice}
            receiveCreateInvoice={this.receiveCreateInvoice}
            receiveCreateOrderInvoice={this.receiveCreateOrderInvoice}
            receiveUploadFileAttachment={this.receiveUploadFileAttachment}
            data={orders}
            actionsEnabled={filter === 'invoicenotcreated'}
            isFetching={isFetching}
          />
        </Grid>
        <Grid item xs={11} style={{margin: '10px 0 10px 0'}}>
          <Pagination
            variant="outlined"
            shape="rounded"
            onChange={(_, page) => this.handlePageChange(page)}
            page={pagination.page}
            count={pagination.totalPages}
          />
        </Grid>
      </Grid>
    )
  }
}

Orders.propTypes = {
  data: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  const { orderData } = state;
  const { lastUpdated, didInvalidate } = orderData;
  const data = orderData.data || [];
  const meta = orderData.meta || {};
  const isFetching = orderData.isFetching === undefined ? true : orderData.isFetching;

  return {
    isFetching,
    lastUpdated,
    data,
    meta,
    didInvalidate,
  }
}

export default connect(mapStateToProps)(Orders)
