import {
  REQUEST_REPORT_DATA,
  RECEIVE_REPORT_DATA,
  INVALIDATE_REPORT_DATA,
} from './report-action-types';

import { fetchReportData } from '../services/reports';

function receiveReportData(reportData) {
  return {
    type: RECEIVE_REPORT_DATA,
    reportData: reportData,
    receivedAt: Date.now(),
  }
}

function requestReportData() {
  return {
    type: REQUEST_REPORT_DATA
  }
}

export function invalidateReportData() {
  return {
    type: INVALIDATE_REPORT_DATA
  }
}

function asyncFetchReportData(accountGroups, cumulative, financialYear) {
  return function (dispatch, getState) {
    dispatch(requestReportData());

    return fetchReportData(accountGroups, cumulative, financialYear)
      .then(response => response.json())
      .then(json => dispatch(receiveReportData(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function shouldfetchReportData(state) {
  const reportData = state.reportData;
  if (!reportData) {
    return true;
  }

  if (reportData.isFetching) {
    return false;
  }

  return reportData.didInvalidate;
}

export function fetchReportDataIfNeeded(accountGroups, cumulative, financialYear) {
  return (dispatch, getState) => {
    if (shouldfetchReportData(getState())) {
      return dispatch(asyncFetchReportData(accountGroups, cumulative, financialYear));
    }
  }
}

