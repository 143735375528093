import {
  REQUEST_TRANSACTION_DATA,
  RECEIVE_TRANSACTION_DATA,
  INVALIDATE_TRANSACTION_DATA,
} from './transaction-action-types';

import {
  fetchTransactionData,
} from '../services/transactions';

function receiveTransactionData(transactionData) {
  return {
    type: RECEIVE_TRANSACTION_DATA,
    transactionData: transactionData.data,
    meta: transactionData.meta,
    receivedAt: Date.now(),
  }
}

function requestTransactionData() {
  return {
    type: REQUEST_TRANSACTION_DATA
  }
}

export function invalidateTransactionData() {
  return {
    type: INVALIDATE_TRANSACTION_DATA
  }
}

export function asyncFetchTransactionData(page, financialYear) {
  return function (dispatch, getState) {
    dispatch(requestTransactionData());

    return fetchTransactionData(page, financialYear)
      .then(response => response.json())
      .then(json => dispatch(receiveTransactionData(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function shouldfetchTransactionData(state, page) {
  const transactionData = state.transactionData;
  if (!transactionData) {
    return true;
  }

  if (transactionData.isFetching) {
    return false;
  }

  if ((transactionData.meta || {}).current_page != page) {
    return true;
  }

  return transactionData.didInvalidate;
}

export function fetchTransactionDataIfNeeded(page, financialYear) {
  return (dispatch, getState) => {
    if (shouldfetchTransactionData(getState())) {
      return dispatch(asyncFetchTransactionData(page, financialYear));
    }
  }
}
