export const houseWorkTypes = {
  RUT: [
    { label: 'Tillsyn av bostad', value: 'HOMEMAINTENANCE' },
    { label: 'Möblering', value: 'FURNISHING' },
    { label: 'Transporttjänster', value: 'TRANSPORTATIONSERVICES' },
    { label: 'Tvätt och vård av kläder', value: 'WASHINGANDCAREOFCLOTHING' },
    { label: 'Reparation av vitvaror', value: 'MAJORAPPLIANCEREPAIR' },
    { label: 'Flyttjänster', value: 'MOVINGSERVICES' },
    { label: 'IT-tjänster', value: 'ITSERVICES' },
    { label: 'Städning', value: 'CLEANING' },
    { label: 'Kläd och textilvård', value: 'TEXTILECLOTHING' },
    { label: 'Snöskottning', value: 'SNOWPLOWING' },
    { label: 'Trädgårdsarbete', value: 'GARDENING' },
    { label: 'Barnpassning', value: 'BABYSITTING' },
    { label: 'Personlig omsorg', value: 'OTHERCARE' }
  ],
  ROT: [
    { label: 'Bygg', value: 'CONSTRUCTION' },
    { label: 'El', value: 'ELECTRICITY' },
    { label: 'Glas/plåtarbete', value: 'GLASSMETALWORK' },
    { label: 'Mark/dräneringsarbete', value: 'GROUNDDRAINAGEWORK' },
    { label: 'Murning', value: 'MASONRY' },
    { label: 'Målning/tapetsering', value: 'PAINTINGWALLPAPERING' },
    { label: 'VVS', value: 'HVAC' }
  ],
  GREEN: [
    { label: 'Solceller', value: 'SOLARCELLS' },
    { label: 'Lagring egenproducerad el', value: 'STORAGESELFPRODUCEDELECTRICITY' },
    { label: 'Laddningspunkt elfordon', value: 'CHARGINGSTATIONELECTRICVEHICLE' }
  ]
}
