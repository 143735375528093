export const initialState = {
  dashboard: {
    isFetching: undefined,
    didInvalidate: true,
    data: {},
  },
  report: {
    isFetching: undefined,
    didInvalidate: true,
    data: {},
  },
  invoice: {
    isFetching: undefined,
    didInvalidate: true,
    data: [],
  },
  supplierInvoice: {
    isFetching: undefined,
    didInvalidate: true,
    data: [],
  },
  customer: {
    isFetching: undefined,
    didInvalidate: true,
    data: [],
  },
  article: {
    isFetching: undefined,
    didInvalidate: true,
    data: [],
  },
  order: {
    isFetching: undefined,
    didInvalidate: true,
    data: [],
  },
  transaction: {
    isFetching: undefined,
    didInvalidate: true,
    data: [],
  },
  taxReduction: {
    isFetching: undefined,
    didInvalidate: true,
    data: {},
  },
  document: {
    isFetching: undefined,
    didInvalidate: true,
    data: [],
  },
  resultReport: {
    isFetching: undefined,
    didInvalidate: true,
    data: [],
  }
}
