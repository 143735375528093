import React from 'react';
import {
  Grid,
  Paper
} from '@material-ui/core';
import { Link } from "react-router-dom";
import { getProfile } from '../services/employees';
import auth from '../authentication/authentication';

import './documents.scss';

export default function Documents(props) {
  // TODO: Här presenterar vi "box-ikoner", typ skatteverket, kivra, rapporter, sen får varje ikon bli egen komponent.
  // hämtas från company.document_categories??

  const handleOpenBankWebsite = () => {
    getProfile(auth.getEmployeeId())
      .then(response => response.json())
      .then(employee => {
        switch (employee.company_bank) {
          case 'seb':
            window.open('https://id.seb.se/ccs/mbid')
            break;
          case 'swedbank':
            window.open('https://online.swedbank.se/app/ib/logga-in')
            break;
          case 'nordea':
            window.open('https://corporate.nordea.se/login/')
            break;
          case 'handelsbanken':
            window.open('https://secure.handelsbanken.se/logon/se/corp/sv/')
            break;
          case 'lansforsakringar':
            window.open('https://secure246.lansforsakringar.se/nim/')
            break;
        }
      });
  }

  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Link to="/documents/owner" style={{textDecoration: 'none'}}>
              <div className="document-category-container">
                <Paper
                  elevation={2}
                  style={{
                    backgroundColor: '#FFFFFF',
                    backgroundImage: 'url(/owner.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center top',
                    backgroundSize: '80%',
                    color: '#111111',
                    cursor: 'pointer',
                  }}
                >
                  <div style={{marginTop: '58%'}}>
                    <p style={{fontWeight: 'bold', fontSize: 20}}>Ägare</p>
                  </div>
                </Paper>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Link to="/documents/reports" style={{textDecoration: 'none'}}>
              <div className="document-category-container">
                <Paper
                  elevation={2}
                  style={{
                    backgroundColor: '#FFFFFF',
                    backgroundImage: 'url(/reports.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center top',
                    backgroundSize: '84%',
                    color: '#111111',
                    cursor: 'pointer',
                  }}
                >
                  <div style={{marginTop: '58%'}}>
                    <p style={{fontWeight: 'bold', fontSize: 20}}>Rapporter</p>
                  </div>
                </Paper>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Link to="/documents/contracts" style={{textDecoration: 'none'}}>
              <div className="document-category-container">
                <Paper
                  elevation={2}
                  style={{
                    backgroundColor: '#FFFFFF',
                    backgroundImage: 'url(/contract.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center 20%',
                    backgroundSize: '90%',
                    color: '#111111',
                    cursor: 'pointer',
                  }}
                >
                  <div style={{marginTop: '58%'}}>
                    <p style={{fontWeight: 'bold', fontSize: 20}}>Avtal</p>
                  </div>
                </Paper>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Link to="/documents/skatteverket" style={{textDecoration: 'none'}}>
              <div className="document-category-container">
                <Paper
                  elevation={2}
                  style={{
                    background: 'url(/skatteverket.png)',
                    color: '#FFFFFF',
                    cursor: 'pointer',
                  }}
                >
                </Paper>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Link to="/documents/bolagsverket" style={{textDecoration: 'none'}}>
              <div className="document-category-container">
                <Paper
                  elevation={2}
                  style={{
                    backgroundColor: '#FFFFFF',
                    backgroundImage: 'url(/bolagsverket.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center 60%',
                    backgroundSize: '95%',
                    color: '#FFFFFF',
                    cursor: 'pointer',
                  }}
                >
                </Paper>
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <div className="document-category-container">
              <Paper
                elevation={2}
                style={{
                  backgroundColor: '#60cd18',
                  backgroundImage: 'url(/seb.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: '95%',
                  color: '#FFFFFF',
                  cursor: 'pointer',
                }}
                onClick={() => handleOpenBankWebsite() }
              >
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
