import request from './request';

export function fetchArticleData() {
  return request('articles', {
    method: 'GET'
  });
}

export function fetchArticle(articleNumber) {
  return request(`articles/${articleNumber}`, {
    method: 'GET'
  });
}

export function createArticle(body) {
  return request('articles', {
    method: 'POST',
    body: body
  });
}

export function updateArticle(body, articleNumber) {
  return request(`articles/${articleNumber}`, {
    method: 'PUT',
    body: body
  });
}

export function deleteArticle(articleNumber) {
  return request(`articles/${articleNumber}`, {
    method: 'DELETE'
  });
}
