import React from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import {
  asyncCreateCustomer,
  asyncUpdateCustomer,
  asyncDeleteCustomer
   } from '../actions/customers';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import FormInput from '../common/form-input';
import i18n from '../i18n';
import { ACTIONS } from '../constants/actions';
import settings from '../settings/settings';

import './customer-form.scss';

const sharedInputs = [
  'name',
  'email',
  'address',
  'zipCode',
  'city',
  'phone',
];

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);

    const isCompany = settings.getInvoiceCustomerType() === 'company';

    this.state = {
      address: '',
      corporateIdentityNumber: '',
      email: '',
      errors: [],
      name: '',
      phone: '',
      zipCode: '',
      city: '',
      editCustomerModalOpen: false,
      isCompany: isCompany,
    }

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const {
      action,
      selectedCustomer
    } = this.props;

    if (action === ACTIONS.UPDATE && selectedCustomer) {
      this.setState({
        editCustomerModalOpen: true,
      }, () => this.setSelectedCustomer(selectedCustomer));
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedCustomer } = this.props;
    if (selectedCustomer && selectedCustomer !== prevProps.selectedCustomer) {
      this.setSelectedCustomer(selectedCustomer);
    }
  }

  customerForm() {
    const t = i18n.getFixedT();
    const {
      errors,
      customerNumber,
      selectedCustomer,
      editCustomerModalOpen,
      isCompany,
     } = this.state;

    const { closeCustomerModal } = this.props;

    const { action } = this.props;

    const submitButtonText = !customerNumber ? 'createCustomer' : 'updateCustomer';

    const onCloseModal = () => {
      this.setState({editCustomerModalOpen: false});
      closeCustomerModal();
    }

    const onDeleteCustomer = () => {
      this.props.dispatch(asyncDeleteCustomer(customerNumber));
      closeCustomerModal();
    }

    const onToggleCustomerType = (isCompany) => {
      this.setState({ isCompany: isCompany });

      const customerType = isCompany ? 'company' : 'private';
      settings.setInvoiceCustomerType(customerType);
    }

    return (
      <form
        className={ClassNames("customer-form", {
          "custom-modal": customerNumber
        })}
        onSubmit={(event) => this.handleSubmit(event)}
      >
        {editCustomerModalOpen &&
          <div className="modal-header">
            <span className="modal-title">{t('updateCustomer')}</span>
            <Icon
              onClick={() => onCloseModal()}
              style={{
                margin: '10px 0 5px 4px',
                float: 'right',
                cursor: 'pointer'
              }}
            >
              clear
            </Icon>
          </div>
        }
        <RadioGroup
          row aria-label="position"
          name="position"
          defaultValue="top"
          style={{marginTop: 10}}
        >
          <FormControlLabel
            onChange={() => onToggleCustomerType(true)}
            checked={isCompany}
            control={<Radio color="primary" />}
            label={t('company')}
            labelPlacement="start"
          />
          <FormControlLabel
            onChange={() => onToggleCustomerType(false)}
            checked={!isCompany}
            control={<Radio color="primary" />}
            label={t('individual')}
            labelPlacement="start"
          />
        </RadioGroup>

        <Grid item xs={12}>
          <Grid key={'corporateIdentityNumber'} item xs={12}>
            <FormInput
              fullWidth={true}
              label={isCompany ? t('corporateIdentityNumber') : t('socialSecurityNumber')}
              variant="outlined"
              onChange={(event) => this.handleInputChange('corporateIdentityNumber', event.target.value)}
              sm={12}
              md={12}
              lg={12}
              value={this.state.corporateIdentityNumber}
            />
          </Grid>
          { sharedInputs.map((field) => (
            <Grid key={field} item xs={12}>
              <FormInput
                fullWidth={true}
                label={t(field)}
                variant="outlined"
                onChange={(event) => this.handleInputChange(field, event.target.value)}
                sm={12}
                md={12}
                lg={12}
                value={this.state[field]}
                error={errors.includes(field)}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container justify="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              type="submit"
              style={{
                color: '#FFFFFF',
                fontSize: 12,
                background: '#66bb6a',
                margin: '10px 0 5px 4px',
                float: 'left',
              }}
            >
              {t(submitButtonText)}
            </Button>

            { customerNumber &&
              <Button
                onClick={() => onDeleteCustomer()}
                style={{
                  color: '#FFFFFF',
                  fontSize: 12,
                  background: '#e53935',
                  margin: '10px 0 5px 4px',
                  float: 'right',
                }}
              >
                {t('deleteCustomer')}
              </Button>
            }
          </Grid>
        </Grid>
      </form>
    )
  }

  setSelectedCustomer(selectedCustomer) {
    this.setState({
      address: selectedCustomer.address_one,
      corporateIdentityNumber: selectedCustomer.corporate_identity_number,
      email: selectedCustomer.email,
      name: selectedCustomer.name,
      phone: selectedCustomer.phone,
      zipCode: selectedCustomer.zip_code,
      city: selectedCustomer.city,
      customerNumber: selectedCustomer.fortnox_customer_number,
      isCompany: selectedCustomer.type == 'COMPANY'
    });
  }

  handleInputChange(field, value) {
    this.setState({ [field]: value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { action } = this.props;

    const state = Object.entries(this.state);
    delete state.errors;

    const errors = [];

    if (!this.state.name) {
      errors.push('name');
    }

    if (errors.length > 0) {
      this.setState({ errors: errors });
      return;
    }

    const {
      address,
      corporateIdentityNumber,
      email,
      name,
      phone,
      zipCode,
      city,
      customerNumber,
      isCompany,
    } = this.state;

    const body = {
      address: address,
      email: email,
      name: name,
      phone: phone,
      zip_code: zipCode,
      city: city,
      company: isCompany,
    };

    body.corporate_identity_number = corporateIdentityNumber;

    if (action === ACTIONS.CREATE) {
      this.props.dispatch(asyncCreateCustomer(body));
    }

    if (action === ACTIONS.UPDATE) {
      this.props.dispatch(asyncUpdateCustomer(body, customerNumber));
      this.setState({ editCustomerModalOpen: false });
      this.props.closeCustomerModal();
    }
  }

  render() {
    const {
      customerNumber,
      editCustomerModalOpen
    } = this.state;

    if (customerNumber) {
      return (
        <Modal
          open={editCustomerModalOpen}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {this.customerForm()}
        </Modal>
      )
    }

    return this.customerForm();
  }
}

CustomerForm.propTypes = {
  selectedCustomer: PropTypes.object,
  action: PropTypes.string,
  dispatch: PropTypes.func.isRequired
}

export default connect(null)(CustomerForm);
