import {
  REQUEST_SUPPLIER_INVOICE_DATA,
  RECEIVE_SUPPLIER_INVOICE_DATA,
  INVALIDATE_SUPPLIER_INVOICE_DATA,
} from './supplier-invoice-action-types';

import {
  fetchSupplierInvoiceData,
} from '../services/supplier-invoices';

function receiveSupplierInvoiceData(supplierInvoiceData) {
  return {
    type: RECEIVE_SUPPLIER_INVOICE_DATA,
    supplierInvoiceData: supplierInvoiceData.data,
    meta: supplierInvoiceData.meta,
    receivedAt: Date.now(),
  }
}

function requestSupplierInvoiceData() {
  return {
    type: REQUEST_SUPPLIER_INVOICE_DATA
  }
}

export function invalidateSupplierInvoiceData() {
  return {
    type: INVALIDATE_SUPPLIER_INVOICE_DATA
  }
}

export function asyncFetchSupplierInvoiceData(page) {
  return function (dispatch, getState) {
    dispatch(requestSupplierInvoiceData());

    return fetchSupplierInvoiceData(page)
      .then(response => response.json())
      .then(json => dispatch(receiveSupplierInvoiceData(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

function shouldfetchSupplierInvoiceData(state, page) {
  const supplierInvoiceData = state.supplierInvoiceData;
  if (!supplierInvoiceData) {
    return true;
  }

  if (supplierInvoiceData.isFetching) {
    return false;
  }

  if ((supplierInvoiceData.meta || {}).current_page != page) {
    return true;
  }

  return supplierInvoiceData.didInvalidate;
}

export function fetchSupplierInvoiceDataIfNeeded(page) {
  return (dispatch, getState) => {
    if (shouldfetchSupplierInvoiceData(getState(), page)) {
      return dispatch(asyncFetchSupplierInvoiceData(page));
    }
  }
}
