import request from './request';
import { buildUrl } from '../utils/link-builder-utils';

export function fetchTransactionData(page, financialYear) {
  const url = buildUrl('transactions', {
    page: page,
    financial_year: financialYear
  });

  return request(url, {
    method: 'GET'
  });
}
