export function calculateNetPrice(invoiceRows) {
  return invoiceRows.reduce((total, row) => {
    if (row.deliveredQuantity && row.price) {
      const quantity = parseFloat(row.deliveredQuantity);
      const price = parseFloat(row.price);
      const discount = parseFloat(row.discount || '0');
      const amount = quantity * price;

      return discount ? total + amount - amount * discount / 100 : total + amount;
    } else {
      return total;
    }
  }, 0);
}

export function calculateTotalPrice(invoiceRows) {
  return invoiceRows.reduce((total, row) => {
    if (row.deliveredQuantity && row.price) {
      const quantity = parseFloat(row.deliveredQuantity);
      const price = parseFloat(row.price);
      const discount = parseFloat(row.discount || '0');
      const vat = row.vat || 25;
      const amount = quantity * price;

      if (discount) {
        return total + (amount - amount * discount / 100) * (vat / 100 + 1);
      }

      return total + amount * (vat / 100 + 1);
    } else {
      return total;
    }
  }, 0);
}

export function calculateROT(invoiceRows) {
  const totalAmount = calculateTotalPrice(invoiceRows);

  return Math.floor(totalAmount * 0.3);
}

export function calculateRUT(invoiceRows) {
  const netAmount = calculateNetPrice(invoiceRows);

  return Math.floor(netAmount * 0.5);
}

export function calculateGREEN(invoiceRows) {
  // TODO: Implement
  // Grönt avdrag Knepigt 15% Solceller 50 % solcellsbatterier o Laddboxar
}
