export const REQUEST_INVOICE_DATA = 'REQUEST_INVOICE_DATA';
export const RECEIVE_INVOICE_DATA = 'RECEIVE_INVOICE_DATA';
export const INVALIDATE_INVOICE_DATA = 'INVALIDATE_INVOICE_DATA';
export const RECEIVE_CREATE_INVOICE = 'RECEIVE_CREATE_INVOICE';
export const RECEIVE_UPDATE_INVOICE = 'RECEIVE_UPDATE_INVOICE';
export const RECEIVE_CREATE_INVOICE_PAYMENT = 'RECEIVE_CREATE_INVOICE_PAYMENT';
export const BOOKKEEP_INVOICE = 'BOOKKEEP_INVOICE';
export const EMAIL_INVOICE = 'EMAIL_INVOICE';
export const RECEIVE_BOOKKEEP_INVOICE = 'RECEIVE_BOOKKEEP_INVOICE';

