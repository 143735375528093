import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import {
  Grid,
  Paper
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import i18n from '../i18n';
import Button from '@material-ui/core/Button';
import FileUploader from '../sie/file-uploader';
import Dropzone from 'react-dropzone'
import {
  uploadExcel,
  uploadSkv,
  fetchSieUrl
} from '../services/sie';
import auth from '../authentication/authentication';


class Sie extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      uploading: false,
      buttonBg: 'rgb(102, 187, 106)',
    }

    this.handleUploadDocument = this.handleUploadDocument.bind(this);
    this.handleUploadDraggedFiles = this.handleUploadDraggedFiles.bind(this);
  }

  handleUploadDraggedFiles(acceptedFiles, rejectedFiles) {
    this.setState({buttonBg: '#66BB6A'})
    let docs = []
    acceptedFiles.forEach((file) => {
      docs.push({file: file})
    })

    if (rejectedFiles.length > 0) {
      console.log(rejectedFiles[0])
      alert(`Otillåtet filformat fil: ${rejectedFiles[0].file.name}, accepterade filformat är .xlsx och .skv`)
    }

    if (docs.length > 0) {
      this.handleUploadDocument(docs, 'Test company')
    }
  }

  handleUploadDocument(documents, companyName) {
    this.setState({
      uploading: true
    });

    const formData = new FormData();

    const doc = documents[0]
    const extension = doc.file.name.split('.').pop()
    formData.append('files[]', doc.file);
    formData.append('company_name', companyName)

    if (extension === 'xlsx') {
      uploadExcel(formData)
      .then((response) => response.json())
      .then((result) => {
        if (result.status && result.status !== 200) {
          alert("Något fick fel, vänligen försök igen eller kontakta support@ekonomimolnet.se");
        } else {
          this.setState({uploading: false})
          result.s3_file_paths.map((path) => window.open(path))
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert("Något fick fel, vänligen försök igen eller kontakta support@ekonomimolnet.se");
      })
      .finally(() => this.setState({ uploading: false }));
    } else if (extension === 'skv'){
      uploadSkv(formData)
        .then((response) => response.json())
        .then((result) => {
          if (result.status && result.status !== 200) {
            alert("Något fick fel, vänligen försök igen eller kontakta support@ekonomimolnet.se");
          } else {
            this.setState({uploading: false})
            result.s3_file_paths.map((path) => window.open(path))
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          alert("Något fick fel, vänligen försök igen eller kontakta support@ekonomimolnet.se");
        })
        .finally(() => this.setState({ uploading: false }));
    }
  }

  render() {
    const t = i18n.getFixedT();
    const { uploading, buttonBg } = this.state;

    return (
      <Grid container justify="center" style={{marginTop: '50px'}}>
        <Grid item xs={8}>
          <Grid container justify="flex-start">
            <Grid item xs={12} sm={12}>
              <Dropzone
                accept={{'.skv': [], '.xlsx': []}}
                onDrop={(acceptedFiles, rejectedFiles) => this.handleUploadDraggedFiles(acceptedFiles, rejectedFiles)}
                onDragEnter={() => this.setState({buttonBg: '#79E580'})}
                onDragLeave={() => this.setState({buttonBg: '#66BB6A'})}
              >
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps()} >
                      <input {...getInputProps()} />

                      <Button
                        style={{
                          color: '#FFFFFF',
                          fontSize: 16,
                          fontWeight: 'bold',
                          background: buttonBg,
                          marginBottom: '10px',
                          width: '400px',
                          height: '300px',
                        }}
                      >
                        Välj fil eller släpp den här
                      </Button>
                    </div>
                  </section>
                )}
              </Dropzone>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Sie
