import React from 'react';
import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import auth from '../authentication/authentication';

export default function SessionExpired(props) {
  return (
    <Modal
      open={true}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="custom-modal">
        <div className="modal-header">
          <span className="modal-title">Sessionen har gått ut</span>
          <Icon
            onClick={props.onClose}
            style={{
              margin: '10px 0 5px 4px',
              float: 'right',
              cursor: 'pointer'
            }}
          >
            clear
          </Icon>
        </div>
        <div className="modal-body">
          <p>Din session har gått och du behöver därför logga in på nytt.</p>

          <Button
            style={{
              background: '#66bb6a',
              color: '#ffffff',
              width: '100%',
              height: '50px',
              textAlign: 'center'
            }}
            variant="outlined"
            onClick={props.onClose}
          >
            Logga in
          </Button>
        </div>
      </div>
    </Modal>
  )
}
