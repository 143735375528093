export const selectVatStyles = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
      marginLeft: 4,
      marginTop: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      marginTop: 0,
      marginLeft: 4,
      border: error ? '2px solid red' : '',
    })
  }
}

export const selectArticleStyles = {
  menu: (provided, state) => ({
    ...provided,
    minWidth: 400,
    width: '100%',
    cursor: 'pointer',
    zIndex: 999,
    marginLeft: 0,
    marginTop: 3,
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    cursor: 'pointer',
    minWidth: 400,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    cursor: 'pointer',
    marginTop: 0,
    marginLeft: 0,
  }),
}

export const selectCustomerStyles = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
      marginLeft: 0,
      marginTop: 44,
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      marginTop: 2,
      border: error ? '2px solid red' : '',
      float: 'left',
      width: '85%'
    })
  }
}

export const selectUnitStyles = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
      marginLeft: 4,
      marginTop: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      marginTop: 0,
      marginLeft: 4,
      border: error ? '2px solid red' : '',
    })
  }
}

export const selectDiscountStyles = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
      marginLeft: 4,
      marginTop: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      marginTop: 0,
      marginLeft: 4,
      border: error ? '2px solid red' : '',
    })
  }
}

export const selectTermsOfPaymentsStyles = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
      marginLeft: 0,
      marginTop: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      marginTop: 3,
      marginLeft: 0,
      border: error ? '2px solid red' : '',
    })
  }
}
