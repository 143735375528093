import request from './request';

export function updateActiveCompany(employeeId, activeCompanyId) {
  return request(`employees/${employeeId}`, {
    method: 'PUT',
    body: {
      active_company_id: activeCompanyId
    }
  });
}

export function getCompanies(employeeId) {
  return request(`employees/${employeeId}/companies`, {
    method: 'GET'
  });
}

export function getProfile(employeeId) {
  return request(`employees/${employeeId}`, {
    method: 'GET'
  });
}

export function changePassword(employeeId, password) {
  return request(`employees/${employeeId}/change_password`, {
    method: 'PUT',
    body: {
      password: password
    }
  });
}
