import {
  RECEIVE_TAX_REDUCTION_DATA,
} from './tax-reduction-action-types';
import {
  createTaxReduction,
  updateTaxReduction,
  getTaxReductionByInvoice,
} from '../services/tax-reductions';

function receiveTaxReduction(taxReductionData) {
  return {
    type: RECEIVE_TAX_REDUCTION_DATA,
    taxReductionData: taxReductionData,
    receivedAt: Date.now(),
  }
}

export function asyncCreateTaxReduction(body) {
  return function (dispatch, getState) {
    createTaxReduction(body)
      .then(response => response.json())
      .then(json => dispatch(receiveTaxReduction(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

export function asyncUpdateTaxReduction(id, body) {
  return function (dispatch, getState) {
    updateTaxReduction(id, body)
      .then(response => response.json())
      .then(json => dispatch(receiveTaxReduction(json)))
      // .catch(error => console.log("error: ", error)))
  }
}

export function asyncGetTaxReductionByInvoice(invoiceId) {
  return function (dispatch, getState) {
    getTaxReductionByInvoice(invoiceId)
      .then(response => response.json())
      .then(json => dispatch(receiveTaxReduction(json)))
      // .catch(error => console.log("error: ", error)))
  }
}
